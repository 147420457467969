import React, { useEffect, useRef, useState } from "react";

import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Grid,
  Divider,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";

import { connect } from "react-redux";

import { GetOpsScreenPingPostDetail, GetOpsScreenPingPostDetail_LDPIngestQueryGroup_GetOpsScreenPingPostDetail } from "../../common/models/types/GetOpsScreenPingPostDetail";
import { GET_OPS_SCREEN_PING_POST_DETAILS } from "../../common/models/opsScreen";
import { useLazyQuery } from "@apollo/react-hooks";
import { queryLoad } from "../../components";
import Editor from "@monaco-editor/react";
import { FillSpinner as Loader } from "react-spinners-kit";
import { GetLeadDetail, GetLeadDetail_LDPIngestQueryGroup } from "../../common/models/types/GetLeadDetail";
import { GET_LEAD_DETAIL } from "../../common/models/leadDataLookup";
import { RootState } from "../../state";
import { opsScreenSearchStateProps } from "../../state/opsScreenReducer";

interface OpsScreenPostDetailParamProps {
  ContractId: any;
  LeadDataId: any;
  VerticalId: any;
  SubVerticalId: any;
  IsPing: Number;
  fetchEdw?: boolean;
}

interface OpsScreenDetailModalProps {
  params: OpsScreenPostDetailParamProps;
  close: Function;
  dispatch?: Function;
  opsScreenSearch: opsScreenSearchStateProps;
}

const OpsScreenDetailModal = ({
  params,
  close,
  dispatch,
  opsScreenSearch
}: OpsScreenDetailModalProps) => {
  const classes = useStyles();

  const [getOpsScreenPostDetail, { data, error, loading, refetch }] = useLazyQuery<GetOpsScreenPingPostDetail>(GET_OPS_SCREEN_PING_POST_DETAILS, {
    fetchPolicy: "no-cache"
  });

  const [opsScreenPostDetails, setOpsScreenPostDetails] = useState<GetOpsScreenPingPostDetail_LDPIngestQueryGroup_GetOpsScreenPingPostDetail | null>(null)

  useEffect(() => {
    if (!data) {
      getOpsScreenPostDetail({
        variables: {
          ...params
        }
      })
    } else {
      if (!loading)
        setOpsScreenPostDetails(data?.LDPIngestQueryGroup?.GetOpsScreenPingPostDetail?.pop() ?? null);
    }
  }, [data]);


  const [getLeadDetails, { data: leadDetailData }] = useLazyQuery<GetLeadDetail>(GET_LEAD_DETAIL, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    const { LeadDataId, VerticalId, SubVerticalId } = params;
    getLeadDetails({
      variables: {
        LeadDataId,
        VerticalId,
        SubVerticalId,
        FetchEDW: !!params.fetchEdw, // force
      }
    });
  }, []);

  const [leadDetails, setLeadDetails] = useState<any>(null);
  useEffect(() => {
    if (leadDetailData){
      const leadDetail = JSON.parse(leadDetailData?.LDPIngestQueryGroup?.GetLeadDetail ?? "").pop();

      if(leadDetail?.Payload){
        try {
          const payload = JSON.parse(leadDetail.Payload || leadDetail.payload);
          for(const payloadKey in payload){
            leadDetail[payloadKey] = payload[payloadKey];
          }

          delete(leadDetail.Payload);
          delete(leadDetail.payload);

        } catch {
          leadDetail.Payload = `Malformed JSON:\n\n${leadDetail.Payload}`;
        }
      }

      setLeadDetails(leadDetail);
    }
    
    //setLeadDetails({"name":"John", "age":30, "car":null});
  }, [leadDetailData]);


  const [theme, setTheme] = useState("vs-dark");
  const [language, setLanguage] = useState<string>("json");
  const [requestPayload, setRequestPayload] = useState<String>("");
  const [responsePayload, setResponsePayload] = useState<String>("");

  const editorRef = useRef(null);
  const handleEditorDidMount = (editor: any) => {
    editorRef.current = editor;
  }

  const leadDetailRef = useRef(null);
  useEffect(() => {

    console.log(leadDetailRef);
  }, [leadDetailRef]);

  const displayLeadValue = (title: string, value: any) => {
    if (typeof value === 'boolean') {
      if (title == "Accident") {
        return value.toString();
      }
      return (value ? "Yes" : "No")
  }
    return value;
  }

  const displayPayload = (jsonValue: string) => {
    try {
      const payload = JSON.parse(jsonValue);
      return <>
        {Object.keys(payload).map(k => (
          <div>
            <strong>{k}</strong>:
            <div style={{ paddingLeft: "10px" }}>{payload[k]}</div>
          </div>
        ))}
      </>
    } catch {
      return <>
        <p>{jsonValue}</p>
      </>
    }
  }

  return (queryLoad([!!loading], [error]) || (
    <Paper className={classes.contrainer}>
      <Grid className={classes.mainGrid} container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>

            <Grid ref={leadDetailRef} item xs={9}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    Error Message:
                  </Grid>
                  <Grid item xs={10}>
                    <div style={{ width: '100%', wordWrap: 'break-word' }}>
                      {opsScreenPostDetails?.ErrorMessage?.replaceAll('","', '", "').replaceAll('":"', '": "')}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    Lead Detail ID:
                  </Grid>
                  <Grid item xs={10}>
                    {opsScreenPostDetails?.LeadDetailId}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    Request URL:
                  </Grid>
                  <Grid item xs={10}>
                    {opsScreenPostDetails?.RequestUrl}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    Request Payload:
                  </Grid>
                  <Grid item xs={10}>
                    <Editor
                      height="25vh" // By default, it fully fits with its parent
                      theme={theme}
                      language={language}
                      loading={<Loader />}
                      key="request-payload"
                      value={opsScreenPostDetails?.RequestPayload ?? ""}
                      defaultValue={opsScreenPostDetails?.RequestPayload ?? ""}
                      onChange={(value: any) => setRequestPayload(value)}
                      onMount={handleEditorDidMount}
                      options={{ lineNumbers: "on", "wordWrap": "on", }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    Response Payload:
                  </Grid>
                  <Grid item xs={10}>
                    <Editor
                      height="25vh" // By default, it fully fits with its parent
                      theme={theme}
                      language={language}
                      loading={<Loader />}
                      key="response-payload"
                      value={opsScreenPostDetails?.ResponsePayload ?? ""}
                      defaultValue={opsScreenPostDetails?.ResponsePayload ?? ""}
                      onChange={(value: any) => setResponsePayload(value)}
                      onMount={handleEditorDidMount}
                      options={{ lineNumbers: "on", "wordWrap": "on", }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              Lead Details
              <div style={{ height: `${leadDetailRef?.current?.innerHeight || '552'}px`, overflow: "hidden scroll" }}>
                {leadDetails && Object.keys(leadDetails).map((key) => (
                  <div key={key} style={{ width: `calc(100% - 40px)` ?? "330px", wordWrap: "break-word", borderBottom: "#cccccc 1px dashed", paddingBottom: "10px", marginBottom: "10px" }}>
                    <strong>{key}</strong>:
                    <div style={{ paddingLeft: "10px" }}>
                      {key.toLowerCase() === "payload" ? displayPayload(leadDetails[key]) : displayLeadValue(key, leadDetails[key])}
                    </div>
                  </div>
                ))}
              </div>
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>&nbsp;</Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            type="button"
            size="large"
            fullWidth
            onClick={() => close()}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Paper>
  ));
};

export default connect(
  (state: RootState) => ({
    opsScreenSearch: state.opsScreenSearch,
  }),
  null
)(OpsScreenDetailModal);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
