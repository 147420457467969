import { combineReducers } from 'redux';
import buyerSection from './buyerSectionReducer';
import contractSection from './contractSectionReducer';
import contractRequestSection from './contractRequestReducer';
import buyerGroupSection from './buyerGroupSectionReducer';
import reportsForm from './reportsFormReducer';
import tabsSection from './tabsReducer';
import formsSection from './formReducer';
import ldpUiTableStates from './ldpUiTableStates';
import opsScreenSearch from './opsScreenReducer';
import salesScreenSearch from './salesScreenReducer';
import reportsSection from './reportsReducer';
import entityDataCache from './dataCacheReducer';
import loggedInUser from './loggedInUserReducer';
import leadSearchSection from './leadSearchSectionReducer';

export const rootReducer = combineReducers({
  buyerSection,
  contractSection,
  contractRequestSection,
  buyerGroupSection,
  tabsSection,
  formsSection,
  reportsForm,
  ldpUiTableStates,
  opsScreenSearch,
  salesScreenSearch,
  reportsSection,
  entityDataCache,
  loggedInUser,
  leadSearchSection
});

export type RootState = ReturnType<typeof rootReducer>