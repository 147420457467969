import gql from "graphql-tag";

export const GET_CONTRACT_REQUEST_HEADER = gql`
    query GetContractRequestHeader($where: String){
        LDPConfigQueryGroup {
            ContractRequestHeader(limit:9999, where: $where) {
                ContractId
                ContractRequestHeaderId
                CreatedDate
                IsActive
                IsPing
                Key
                ModifiedDate
                UserId
                Value
            }
        }
    }
`;  

export const SAVE_CONTRACT_REQUEST_HEADER = gql`
    mutation SaveContractRequestHeader($contractRequestHeader: [ContractRequestHeaderInputType]!){
        LDPConfigMutationGroup {
            SaveContractRequestHeader(ContractRequestHeaderInput : $contractRequestHeader) {
                ContractRequestHeaderId
                ContractId
                CreatedDate
                IsActive
                IsPing
                Key
                ModifiedDate
                UserId
                Value
            }
        }
    }
`;

export const DELETE_CONTRACT_REQUEST_HEADER = gql`
    mutation DeleteContractRequestHeader($contractRequestHeaderId: ID!){
        LDPConfigMutationGroup {
            DeleteContractRequestHeader(ContractRequestHeaderId : $contractRequestHeaderId)
        }
    }
`;