import { createStyles, makeStyles, Theme, Tooltip, withStyles } from "@material-ui/core";

export const EllipsisTooltip2 = (width: any) => withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: width,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const EllipsisTooltip = EllipsisTooltip2(300);

export const useEllipsisStyles = makeStyles((theme: Theme) =>
createStyles({    
    root: {
      flexGrow: 1,
    },
    ellipsisColumn: {
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",      
      textOverflow: "ellipsis",      
      [theme.breakpoints.down(960)]: {
        maxWidth: "100%",
      },
    },
    ellipsisColumnContractList: {
      maxWidth: "140px",
    },
    ellipsisColumnReportList: {
      maxWidth: "100px",
    },
    ellipsisColumnReportRunLiveList: {
      maxWidth: "200px",
    },
    ellipsisColumnFilterValue: {
      maxWidth: "200px",
    },
  })
);