import gql from "graphql-tag";

export const GET_SUBVERTICAL_RULE = gql`
    query GetSubVerticalRule{
        LDPConfigQueryGroup {
            GetSubVerticalRule{
                UserId
                SubVerticalName
                VerticalName
                MaxSalesPerLead
                IsActive
                CreatedDate
                ModifiedDate
                SubVerticalRuleId
                UndersoldThreshold
                CatchAllThreshold
            }
        }
    }
`;

export const SAVE_UNDERSOLD_SETTING = gql`
  mutation MergeSubVerticalRule($mergeSubVerticalRuleParams: MergeSubVerticalRuleInputType!) {
    LDPConfigMutationGroup {
      MergeSubVerticalRule(MergeSubVerticalRuleParams: $mergeSubVerticalRuleParams)
    }
  }
`;
