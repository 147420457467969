import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Link,
} from "@material-ui/core";
import TimelineIcon from '@material-ui/icons/Timeline';

import { LDPUIDataTable, queryLoad, StatusIcon, useModal, PrettyPayload } from "../../components";
import { useLazyQuery } from "@apollo/react-hooks";
import { GetOpsScreenDispositions, GetOpsScreenDispositionsVariables } from "../../common/models/types/GetOpsScreenDispositions";
import { GET_OPS_SCREEN_DETAILS, GET_OPS_SCREEN_DISPOSITIONS } from "../../common/models/opsScreen";
import { GetOpsScreenDetails, GetOpsScreenDetails_LDPIngestQueryGroup_GetOpsScreenDetails } from "../../common/models/types/GetOpsScreenDetails";

import SlideshowIcon from '@material-ui/icons/Slideshow';
import { ApolloError } from "apollo-client";
import { LdpJourneyDetails } from "./ldpJourneyDetails";
1
import LaunchIcon from "@material-ui/icons/Launch";
import ListIcon from '@material-ui/icons/List';
import SettingsIcon from '@material-ui/icons/Settings';
import OpsScreenDetailModal from "./opsScreenDetailModal";
import { dateToPreferredTimezone, getTimezoneSettingLongDesc } from "../../common/utils/date";
import LeadViewModal from "../leads/leadViewModal";
import { findMuiRowValue } from "../../common/utils/findMuiRowValue";

interface OpsScreenDispositionProps {
  params: GetOpsScreenDispositionsVariables;
  fetchEDW?: boolean;
  showDefault?: boolean;
}

export const OpsScreenDisposition = ({
  params,
  fetchEDW,
  showDefault
}: OpsScreenDispositionProps) => {
  const [getOpsScreenDetails, { data: opsScreenDetailData, loading: opsScreenDetailLoading, error: opsScreenDetailError }] = useLazyQuery<GetOpsScreenDetails>(GET_OPS_SCREEN_DETAILS, {
    fetchPolicy: "cache-and-network"
  });
  const [getOpsScreenDispositions, { data, loading, error }] = useLazyQuery<GetOpsScreenDispositions>(GET_OPS_SCREEN_DISPOSITIONS, { fetchPolicy: "cache-and-network" });

  /* useEffect(() => {
    console.log("!data && !showDefault", !data, !showDefault);
    if (!data && !showDefault) {
      
    }
  }, [data]); */

  const [responseDespositionId, setResponseDispositionId] = useState<number | null>(null);
  const [responseDespositionName, setResponseDispositionName] = useState<string | null>(null);

  useEffect(() => {
    getOpsScreenDispositions({
      variables: { ...params, fetchEdw: fetchEDW, timezone: getTimezoneSettingLongDesc() }
    });


  }, []);

  useEffect(() => {
    if (data && showDefault) {
      setResponseDispositionId(0);
      setResponseDispositionName("Default");
    }
  }, [data, error]);

  useEffect(() => {
    if (data && responseDespositionId && responseDespositionId !== 0) {
      setResponseDispositionName(data.LDPIngestQueryGroup?.GetOpsScreenDisposition?.find(rd => rd?.ResponseDispositionId === responseDespositionId)?.ResponseDispositionName || null);
    }
  }, [data, responseDespositionId]);

  useEffect(() => {
    if (responseDespositionId !== null) {
      const { startDate, endDate, accountManagerName, buyerName, verticalId, subVerticalId, isPost, isSuccess } = params;
      const reportType = `${isPost ? "Posts" : "Pings"}${isSuccess ? (showDefault ? "DefaultAccepted" : "Accepted") : (showDefault ? "DefaultRejected" : "Rejected")}`;
      getOpsScreenDetails({
        variables: {
          startDate,
          endDate,
          accountManagerName,
          buyerName,
          verticalId,
          subVerticalId,
          reportType: !!fetchEDW ? reportType.toLowerCase() : reportType,
          dispositionId: responseDespositionId,
          fetchEDW: !!fetchEDW,
          timezone: getTimezoneSettingLongDesc()
        }
      });
    }
  }, [responseDespositionId]);


  const classes = useStyles();
  const columns = [
    {
      name: "ResponseDispositionId",
      label: " ",
      options: {
        viewColumns: true,
        filter: true,
        customBodyRender: (value: any, tableMeta: any) => (
          <Tooltip placement="top" title={
            <React.Fragment>
              <Typography>Display Leads</Typography>
            </React.Fragment>
          }>
            <IconButton onClick={() => setResponseDispositionId(value)}>
              <SlideshowIcon />
            </IconButton>
          </Tooltip>
        ),
        setCellProps: () => ({ style: { minWidth: "20px", maxWidth: "20px" } })
      },
    },
    {
      name: "ResponseDispositionName",
      label: "Response Disposition Name",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "Volume",
      label: "Volume",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "IsSuccess",
      label: "Is Success",
      options: {
        viewColumns: true,
        filter: true,
        customBodyRender: (value: any) => (value === 1 ? "Yes" : "No"),
      },
    },
  ];

  const options: any = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Paper className={classes.container}>
      {!showDefault ? (
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.tableWrapper}>
            {queryLoad([!!loading], [error]) || (
              <LDPUIDataTable
                ldpTableId="ops-screen-disposition"
                restoreFilters={true}
                title={<Typography variant='h6'>&nbsp;</Typography>}
                data={data?.LDPIngestQueryGroup?.GetOpsScreenDisposition?.filter(rd => rd?.ResponseDispositionId != 0) || []}
                columns={columns}
                options={options} />
            )}
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={1}>&nbsp;</Grid>
      {(responseDespositionId !== null ? (<Grid container spacing={1}>
        <Grid item xs={12} className={classes.tableWrapper}>
          <OpsScreenDetailList
            data={(opsScreenDetailData?.LDPIngestQueryGroup?.GetOpsScreenDetails ?? []).filter((d) => d?.ResponseDispositionName == responseDespositionName)}
            loading={opsScreenDetailLoading}
            error={opsScreenDetailError}
            verticalId={params.verticalId}
            subVerticalId={params.subVerticalId}
            isPing={params.isPost !== true ? 1 : 0}
            fetchEDW={!!fetchEDW}
          /></Grid>
      </Grid>) : "")}
    </Paper>
  );
};

interface OpsScreenDetailListProps {
  data: GetOpsScreenDetails_LDPIngestQueryGroup_GetOpsScreenDetails;
  loading: Boolean;
  error: ApolloError | undefined;
  verticalId: string;
  subVerticalId: string;
  isPing: number
  fetchEDW?: boolean;
}

const OpsScreenDetailList = ({ data, loading, error, verticalId, subVerticalId, isPing, fetchEDW }: OpsScreenDetailListProps) => {
  const { Modal, openModal, closeModal } = useModal({}, 0.8);
  const columns = [
    {
      name: "ContractId",
      label: " ",
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const [ContractId, LeadDataId, ContractName] = findMuiRowValue(columns, ['ContractId', 'LeadDataId', 'ContractName'], tableMeta.rowData);
          return (
            <React.Fragment>
              <IconButton
                onClick={() => {
                  openModal({
                    title: `Lead Data ID #${LeadDataId} - ${ContractName}`,
                    icon: <SettingsIcon />,
                    iconColor: "green",
                    content: (<OpsScreenDetailModal params={{
                      ContractId,
                      LeadDataId,
                      VerticalId: verticalId,
                      SubVerticalId: subVerticalId,
                      IsPing: isPing,
                      fetchEdw: !!fetchEDW,
                    }} close={closeModal} />)
                  });
                }
                }
              >
                <LaunchIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  openModal({
                    content: <LdpJourneyDetails LeadDataId={LeadDataId} VerticalId={verticalId} SubVerticalId={subVerticalId} FetchEDW={!!fetchEDW} />,
                    title: `LDP Journey Lead Data Id #${LeadDataId} on Vertical ${verticalId}, Sub-Vertical ${subVerticalId}`
                  });
                }}>
                <TimelineIcon />
              </IconButton>
            </React.Fragment>
          );
        }
      }
    },
    {
      name: "ContractId",
      label: "",
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: "LeadDataId",
      label: "LeadDataId",
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => (
          <Link href="#" onClick={() => {
            openModal({
              title: "Lead Data ID #" + value,
              icon: <ListIcon />,
              iconColor: "rose",
              content: (
                <LeadViewModal
                  LeadDataId={value}
                  VerticalId={verticalId}
                  SubVerticalId={subVerticalId}
                  close={closeModal}
                  FetchEDW={fetchEDW}
                />
              ),
            });
          }}><Typography align={"left"} variant={"body2"}> <strong>{`${value}`}</strong></Typography></Link>
        ),
      }
    },
    {
      name: "ContractName",
      label: "Contract Name",
      options: {
        viewColumns: true,
        filter: true,
        customBodyRender: (value: any, tableMeta: any) => (
          <Link href={`/contracts/${tableMeta.rowData[0]}`} target="_blank">
            <Typography align={"left"} variant={"body2"}> <strong>{value}</strong></Typography>
          </Link>)
      },
    },
    {
      name: "PriceOffered",
      label: "Price Offered",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "ReceiveDate",
      label: "Receive Date",
      options: {
        viewColumns: true,
        filter: true,
        customBodyRender: (value: any) => (dateToPreferredTimezone(value))
      },
    },
    {
      name: "ResponseDispositionName",
      label: "Response Disposition Name",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "ResponseStatusCode",
      label: "Response Status Code",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    {
      name: "ClientTimeMilliSeconds",
      label: "Client Time Milliseconds",
      options: {
        viewColumns: true,
        filter: true,
      },
    },
    /* {
      name: "SignupId",
      label: "Signup ID",
      options: {
        "display": false,
      }
    },
    {
      name: "UUID",
      label: "UUID",
      options: {
        "display": false,
      }
    }, */
  ];

  const options: any = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (queryLoad([!!loading], [error]) || (<>
    <LDPUIDataTable
      ldpTableId="ops-screen-detail"
      restoreFilters={true}
      title={<Typography variant='h6'>OPS Screen Detail Leads</Typography>}
      data={data || []}
      columns={columns}
      options={options} />
    <Modal />
  </>)
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
      boxShadow: "none"
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    tableWrapper: {
      margin: "4px",
      overflowY: "auto",
    },
    CodeWrapper: {
      maxHeight: "120px",
      width: "240px",
      overflowY: "auto",
      overflowX: "hidden",
      padding: "5px",
      fontFamily: "monospace",
      boxShadow: "none",
      fontSize: "0.87rem",
    },
    jsonPrettyContainer: {
      position: "relative",
      height: "150px",
      "&:hover": {
        cursor: 'pointer',
        "& $jsonPrettyOverlay": {
          display: "block",
        }
      },
    },
    jsonPrettyOverlay: {
      position: "absolute",
      display: "none",
      backgroundColor: "rgb(0 0 0 / 60%)",
      width: "100%",
      height: "100%",
      zIndex: 2,
    },
    jsonPrettyBtn: {
      margin: "50px auto",
      display: "block",
    }
  })
);