import React, { useEffect, useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { ContentContainer, Layout, PrivateRoute } from "../components";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { RootState } from "../state";
import { TabProps, TabsContainer } from "../components/tabs";
import { updateTabContainers } from "../state/tabsReducer";


import UndersoldList from "../views/general/undersoldDatatables";
import SubVerticalGlobalDefaults from "../views/general/subVerticalGlobalDefaults";

interface Props {
  dispatch: Function;
  generalSettingsTab: any;
  settings?: string;
}

const TAB_CONTAINER = "general-settings-tabs";

const GeneralSettings = ({ generalSettingsTab, dispatch, settings }: Props & RouteComponentProps) => {
  
  const [tabFocus, setTabFocus] = useState<number>(0);
  const [tabUndersold, setTabUndersold] = useState<TabProps[]>([]);

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    if (props.permalink) navigate(props.permalink);
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );
  };

  useEffect(() => {
    if (! generalSettingsTab.tabContainers[TAB_CONTAINER]) {
      let initialTabContainerFocus = 0;
      let initialTabs: TabProps[] = [
        {
          title: "Undersold Settings",
          tabId: "undersold-list",
          content: <UndersoldList />,
          permalink: "/general-settings/undersold/"
        },
        {
          title: "Sub-Vertical Global Defaults",
          tabId: "subvert-global-defs-list",
          content: <SubVerticalGlobalDefaults />,
          permalink: "/general-settings/svglobaldefs/"
        },
      ];            
      
      if(settings){
        initialTabContainerFocus = initialTabs.findIndex(tab => tab.permalink === `/general-settings/${settings}/`);
        initialTabContainerFocus = initialTabContainerFocus < 0 ? 0 : initialTabContainerFocus;
      }

      const initialLeadsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch(updateTabContainers(initialLeadsTabContainer));
    } else {
      if (generalSettingsTab.tabContainers[TAB_CONTAINER]) {
        setTabUndersold(generalSettingsTab.tabContainers[TAB_CONTAINER].tabs);
        setTabFocus(generalSettingsTab.tabContainers[TAB_CONTAINER].tabFocus);

        if(settings){
          let tabFocus = generalSettingsTab.tabContainers[TAB_CONTAINER].tabs.findIndex((tab:TabProps) => tab.permalink === `/general-settings/${settings}/`);
          tabFocus = tabFocus < 0 ? 0 : tabFocus;
          
          if(generalSettingsTab.tabContainers[TAB_CONTAINER].tabFocus !== tabFocus){
            dispatch(updateTabContainers({
              [TAB_CONTAINER]: {
                tabFocus: tabFocus,
                tabs: [ ...generalSettingsTab.tabContainers[TAB_CONTAINER].tabs ],
              }
            }));
          }
        }
      }
    }
  }, [generalSettingsTab]);

  return (
    <ContentContainer>
      <TabsContainer
        tabs={tabUndersold}
        onCloseTab={setTabUndersold}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />
    </ContentContainer>
  );
};

const GeneralSettingsWrapper = connect((state: RootState) => ({
  generalSettingsTab: state.tabsSection,
}), null)(GeneralSettings);

export default (a: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute default component={GeneralSettingsWrapper} path="/general-settings/" pagetitle="General Settings"/>
        <PrivateRoute default component={GeneralSettingsWrapper} path="/general-settings/:settings/" pagetitle="General Settings"/>
      </Router>
    </Layout>
  );
};