import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { queryLoad } from "../../components";
import { TransformMapperEditor } from "../../components/transformMapperEditor";

import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TransformCodeInputType } from "../../types/graphql-global-types";
import { GetVertical, GetVertical_LDPConfigQueryGroup_Vertical } from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { GetSubVertical, GetSubVertical_LDPConfigQueryGroup_SubVertical } from "../../common/models/types/GetSubVertical";
import { GET_BUYERS } from "../../common/models/buyers";
import { GetBuyers, GetBuyers_LDPConfigQueryGroup_Buyer } from "../../common/models/types/GetBuyers";
import { GET_LEAD_DATA_LOOKUP_CONFIG, GET_LEAD_DATA_LOOKUP } from "../../common/models/config";
import { LeadDataLookupConfig } from "../../common/models/types/LeadDataLookupConfig";
import { GetLeadDataLookup } from "../../common/models/types/GetLeadDataLookup";
import { SAVE_TRANSFORM_CODES } from "../../common/models/transformCode";

import { GetTableDefinition, GetTableDefinition_LDPIngestQueryGroup_TableDefinition } from "../../common/models/types/GetTableDefinition";
import { GET_TABLE_DEFINITION } from "../../common/models/tableDefinition";

interface MappingModalProps {
  data: any | null;
  action: string;
  close: Function;
  refetch: Function;
  dispatch: Function;
}

interface SelectOption { id: string | number, label: string, parent?: string | number }
interface MappingDialogProps {
  open: boolean;
  prevValue: any;
  newValue: any;
}

const globalVertical = {
  id: 0,
  label: "Global"
};

const globalSubVertical = {
  id: 0,
  label: "Global"
};

const WrappedMappingModal = ({
  action,
  data,
  close,
  refetch,
  dispatch
}: MappingModalProps) => {
  const classes = useStyles();

  const isEdit = data?.BuyerId ? true : false;
  
  const [getVerticals, { data: verticalData, loading: verticalLoading }] = useLazyQuery<GetVertical>(GET_VERTICAL);
  const [verticalOptions, setVerticalOptions] = useState<SelectOption[]>([]);
  const [vertical, setVertical] = useState<SelectOption>(globalVertical);

  const [getSubverticals, { data: subVerticalData, loading: subVerticalLoading }] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);
  const [subVerticalOptions, setSubVerticalOptions] = useState<SelectOption[]>([]);
  const [subVertical, setSubVertical] = useState<SelectOption>(globalSubVertical);

  const [getBuyers, { data: buyersData, loading: buyersLoading, error: buyersError }] = useLazyQuery<GetBuyers>(GET_BUYERS);
  const [buyerOptions, setBuyerOptions] = useState<SelectOption[]>();
  const [buyer, setBuyer] = useState<SelectOption>();

  const [fieldNameOptions, setFieldNameOptions] = useState<SelectOption[]>([]);
  const [transformCodeName, setTransformCodeName] = useState("");
  const [targetFieldName, setTargetFieldName] = useState("");
  
  const [fieldName, setFieldName] = useState<SelectOption>();
  const [loading, setLoading] = useState<boolean>((action == "create" ? false : true));
  const [firstLoad, setFirstLoad] = useState(false);

  const [getLeadDataLookupConfig, { data: leadLookupConfigData }] = useLazyQuery<LeadDataLookupConfig>(GET_LEAD_DATA_LOOKUP_CONFIG);
  const [getLeadDataLookup, { data: leadLookupData, loading: leadLookupLoading, error: leadLookupError }] = useLazyQuery<GetLeadDataLookup>(GET_LEAD_DATA_LOOKUP);

  const [getTableDefinition, { data: tableDefinitionData }] = useLazyQuery<GetTableDefinition>(GET_TABLE_DEFINITION);

  const [mapper, setMapper] = useState<any>({});

  const defaultFieldDialogValues:MappingDialogProps = {
    open: false,
    prevValue: null,
    newValue: null
  };

  const [changeToBoolean, setChangeToBoolean] = useState<MappingDialogProps>(defaultFieldDialogValues);

  useEffect(() => {
    getSubverticals();
  }, []);

  useEffect(() => {
    if(!subVerticalData) return;

    //console.log("loaded sub-verticals");
    getVerticals();
  }, [subVerticalData]);

  useEffect(() => {
    if(!verticalData) return;

    //console.log("loaded verticals");

    const options: SelectOption[] = [globalVertical];

    verticalData?.LDPConfigQueryGroup?.Vertical?.forEach((item) => {
      if(item){
        options.push({
          id: item.VerticalId,
          label: item.VerticalName || '',
        })
      }
    });

    setVerticalOptions(options);

    if(!vertical && options.length){
      setVertical(options[0])
    }

    getBuyers();
  }, [verticalData]);

  useEffect(() => {
    if(!buyersData) return;

    // console.log("loaded buyers", JSON.stringify(buyersData));

    const options: SelectOption[] = [];

    buyersData?.LDPConfigQueryGroup?.Buyer?.forEach((item) => {
      if(item){
        options.push({
          id: item?.BuyerId,
        label: item?.BuyerName || '',
        })
      }
    });
    setBuyerOptions(options);
  }, [buyersData]);

  useEffect(() => {
    if(!tableDefinitionData) return;

    //console.log("loaded table definitions");
    const options: SelectOption[] = [];

    tableDefinitionData?.LDPIngestQueryGroup?.TableDefinition?.forEach((item) => {
      if(item){
        options.push({
          id: item?.DataType || '',
        label: item?.ColumnName || '',
        })
      }
    });

    setFieldNameOptions(options);

    if (data && data.FieldName) {
      //console.log("fieldname", data.FieldName);
      
      const selected = options.find((it) => it?.label === data?.FieldName);
      //console.log("selected", selected);

      setFieldName(selected);
      setLoading(false)
    }
  }, [tableDefinitionData]);

  useEffect(() => {
    if (!firstLoad) {
      if (verticalOptions) {
        if (data && data.VerticalName) {
          setVertical(verticalOptions?.find((it: any) => it?.label === data?.VerticalName) || globalVertical);
        }
      }
      if (subVerticalOptions) {
        if (data && data.SubVerticalName) {
          setSubVertical(subVerticalOptions?.find((it: any) => it?.label === data?.SubVerticalName) || globalSubVertical);
        }
      }
    } 

    if (buyerOptions) {
      if (data && data.BuyerId) {
        setBuyer(buyerOptions?.find((it: any) => it?.id === data?.BuyerId));
      }
    }

  }, [buyerOptions, verticalOptions, subVerticalOptions]);

  // render sub-vertical options based on vertical selected value
  useEffect(() => {
    /* console.log("vertical changed", vertical); */
    if (vertical) {
      const options: SelectOption[] = [];

      if(vertical.label === "Global"){
        options.push(globalSubVertical)

        setSubVertical(options[0]);
        
      } else {
        /* console.log("vertical set", vertical);
        console.log("vertical set, loading sub-vertical options from",  subVerticalData); */

        subVerticalData?.LDPConfigQueryGroup?.SubVertical?.forEach((item) => {
          //console.log("sub-vertical", item, item?.VerticalId == vertical.id);

          if(item && item.VerticalId == vertical.id){
            options.push({
              id: item?.SubVerticalId,
              label: item?.SubVerticalName || '',
              parent: item?.VerticalId,
            })
          }
        });

        if(!subVertical || (subVertical && !options.find((opt) => opt.label === subVertical.label))){
          /* console.log("sub-vertical defaulted to ", options[0]); */
          setSubVertical(options[0]);
        }
      }

      setSubVerticalOptions(options);
    }
  }, [vertical]);

  useEffect(() => {
    if ((vertical && vertical.id === 0) || (subVertical && vertical)) {

      getTableDefinition({
        variables: {
          schema: "lead",
          name: vertical.label + (vertical.label === "Global" ? "Global" : subVertical?.label)
        }
      });
    }
  }, [subVertical]);

  useEffect(() => {
    if (fieldName && subVertical && vertical) {
      getLeadDataLookup({
        variables: {
          'columnName': `${fieldName.label}`,
          'verticalId': vertical.id,
          'subVerticalId': subVertical.id
        }
      })

      if (!firstLoad) {
        setFirstLoad(true)
      }
    }
  }, [fieldName]);

  useEffect(() => {
    if (data) {

      if (data.Map) {
        try {
          if (typeof JSON.parse(data.Map) === "object")
            setMapper(JSON.parse(data.Map));
        } catch (e) {
          console.log(e); // error in the above string (in this case, yes)!
        }
      }

      if (data.TransformCodeName) {
        setTransformCodeName(data.TransformCodeName.replace(/ /g, '').trim())
      }

      if (data.TargetFieldName) {
        setTargetFieldName(data.TargetFieldName.replace(/ /g, '').trim())
      }
    }
  }, [data]);

  useEffect(() => {
    if(verticalOptions && verticalOptions.length){
      setVertical(verticalOptions.find((vo) => vo.label === data?.VerticalName) || verticalOptions[0])
    }
  }, [data, verticalOptions]);

  useEffect(() => {
    if(subVerticalOptions && subVerticalOptions.length){
      setSubVertical(subVerticalOptions.find((svo) => svo.label === data?.SubVerticalName) || subVerticalOptions[0])
    }
  }, [data, subVerticalOptions]);


  const [saveTransformCode] = useMutation(SAVE_TRANSFORM_CODES);
  const { register, handleSubmit, control, getValues, setValue, watch, errors } = useForm<TransformCodeInputType>();

  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  
  const onSubmit = (TransformCodeInput: TransformCodeInputType) => {

    TransformCodeInput.TransformCodeId = parseInt(TransformCodeInput?.TransformCodeId);
    TransformCodeInput.TransformCodeId = isNaN(TransformCodeInput.TransformCodeId) ? 0 : TransformCodeInput.TransformCodeId;

    TransformCodeInput.BuyerId = buyerOptions?.find(
      (buyer) =>
        buyer?.label === TransformCodeInput.BuyerId
    )?.id;

    TransformCodeInput.TargetFieldName = targetFieldName;
    
    let stringMap = JSON.stringify(mapper, null, 2);
    if (Object.keys(mapper).length === 0) {
      return false;
    } else {
      if(mapper["*"]){
        const wildcard = mapper["*"];
        delete(mapper["*"]);
        stringMap = JSON.stringify(mapper, null, 2);
        stringMap = stringMap.replace("}", `${Object.keys(mapper).length > 0 ? ', ' : ''} "*": "${wildcard}" }`);
      }
    }

    TransformCodeInput.Map = stringMap; //JSON.stringify(mapper);
    delete (TransformCodeInput as any).mapper;

    TransformCodeInput.VerticalName = vertical?.label;

    if(TransformCodeInput.VerticalName === "Global"){
      TransformCodeInput.SubVerticalName = "";
    }
    
    // return console.log(TransformCodeInput);
    setDisableButtons(true);

    //console.log("saving");

    saveTransformCode({
      variables: {
        transformCodeInput: TransformCodeInput
      }
    }).then((response: any) => {
      //console.log("response", response);
      setDisableButtons(false);

      //console.log("close", close);
      close();

      //console.log("refetch", refetch);
      refetch();

      

      toast.success(`Transform Code ${(TransformCodeInput?.TransformCodeId > 0 ? "updated" : "created")} successfully.`);
    }).catch(({ errors }) => {
      setDisableButtons(false);
      if (errors)
        toast.error("Unexpected error!");
    });
  };

  const TransformCodeConstraints = {
    BuyerId: {
      required: {
        value: true,
        message: "Buyer is required."
      }
    },
    TransformCodeName: {
      required: {
        value: true,
        message: "Transform Code Name is required."
      }
    },
    VerticalName: {
      required: {
        value: true,
        message: "Vertical Name is required."
      }
    },
    SubVerticalName: {
      required: {
        value: true,
        message: "Sub Vertical Name is required."
      }
    },
    FieldName: {
      required: {
        value: true,
        message: "Field Name is required."
      }
    },
  };

  const handleCloseDialog = () => {
    setChangeToBoolean(defaultFieldDialogValues);
  };

  const handleChangeFieldName = () => {
    setFieldName(changeToBoolean.newValue);
    setMapper({});
    setChangeToBoolean(defaultFieldDialogValues);
  };

  return (
    <Paper className={classes.contrainer}>
      {queryLoad([!!buyersLoading, !!subVerticalLoading, !!verticalLoading, !!loading], [buyersError]) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={6}>
              <TextField
                hidden
                inputRef={register}
                name="TransformCodeId"
                defaultValue={data?.TransformCodeId ?? 0}
              />
              <TextField
                hidden
                inputRef={register}
                name="InputFieldType"
                defaultValue={data?.InputFieldType ?? "string"}
              />
              <TextField
                hidden
                inputRef={register}
                name="OutputFieldType"
                defaultValue={data?.OutputFieldType ?? "string"}
              />
              <TextField
                hidden
                inputRef={register}
                name="IsActive"
                defaultValue={data?.IsActive ?? true}
              />
              <TextField
                hidden
                inputRef={register}
                name="UserId"
                defaultValue={data?.UserId ?? "Test User"}
              />
              {buyerOptions && ((isEdit && buyer) || !isEdit) &&
                <Autocomplete
                  id="buyer-id-input"
                  options={buyerOptions}
                  value={buyer}
                  getOptionLabel={option => option.label}
                  onChange={(event: any, newValue: any) => {
                    setBuyer(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputRef={register(TransformCodeConstraints.BuyerId)}
                      error={errors.BuyerId ? true : false}
                      helperText={errors.BuyerId && errors.BuyerId?.message}
                      name="BuyerId"
                      label="Buyer Name"
                      variant="outlined"
                    />
                  )}
                />
              }
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register(TransformCodeConstraints.TransformCodeName)}
                error={errors.TransformCodeName && true}
                helperText={errors.TransformCodeName && errors.TransformCodeName?.message}
                name="TransformCodeName"
                label="Transform Code Name"
                variant="outlined"
                onChange={(event: any) => {
                  setTransformCodeName(event.target.value.replace(/ /g, '').trim());
                }}
                value={transformCodeName ?? ""}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="vertical-id-input"
                options={verticalOptions}
                value={vertical || verticalOptions[0]}
                getOptionLabel={option => option.label}
                onChange={(event: any, newValue: any) => {
                  setVertical({...newValue});
                  setFieldName(undefined);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputRef={register(TransformCodeConstraints.VerticalName)}
                    error={errors.VerticalName && true}
                    helperText={errors.VerticalName && errors.VerticalName?.message}
                    name="VerticalName"
                    label="Vertical Name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="subvertical-id-input"
                options={subVerticalOptions}
                value={subVertical || subVerticalOptions[0]}
                getOptionLabel={option => option.label}
                onChange={(event: any, newValue: any) => {
                  setSubVertical(newValue);
                }}
                disabled={!vertical || subVertical?.label === "Global"}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputRef={register(TransformCodeConstraints.SubVerticalName)}
                    error={errors.SubVerticalName && true}
                    helperText={errors.SubVerticalName && errors.SubVerticalName?.message}
                    name="SubVerticalName"
                    label={(vertical && vertical.label === "Global") ? "Global" : "SubVertical Name"}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="fieldname-id-input"
                options={fieldNameOptions}
                value={fieldName}
                getOptionLabel={option => option.label}
                disabled={!vertical || (vertical && vertical.label !== "Global" && !subVertical)}
                onChange={(event: any, newValue: any) => {
                  if(newValue?.id == 'bit' && fieldName?.id !== 'bit' ){
                    setChangeToBoolean({
                      open: true,
                      prevValue: fieldName,
                      newValue: newValue,
                    });
                  } else {
                    setFieldName(newValue);
                  }
                  // setValue("InputFieldType", newValue.id);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputRef={register(TransformCodeConstraints.FieldName)}
                    error={errors.FieldName && true}
                    helperText={errors.FieldName && errors.FieldName?.message}
                    name="FieldName"
                    label="Field Name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // inputRef={register(TransformCodeConstraints.TargetFieldName)}
                error={errors.TargetFieldName && true}
                helperText={errors.TargetFieldName && errors.TargetFieldName?.message}
                name="TargetFieldName"
                label="Target Field Name"
                variant="outlined"
                value={targetFieldName}
                onChange={(event: any) => {
                  setTargetFieldName(event.target.value.replace(/ /g, '').trim());
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {queryLoad([!!leadLookupLoading], [leadLookupError]) || (
                leadLookupData?.LDPConfigQueryGroup?.GetLeadDataLookup && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography component="h5" variant="h5"> Map Builder </Typography>
                        <Divider className={classes.divider} />
                      </Grid>
                    </Grid>
                    <Grid className={classes.mapContrainer} container justify="center" spacing={2}>
                      <TransformMapperEditor
                        onChange={(dataMap) => {
                          setMapper(dataMap)
                        }}
                        fieldNameDataType={`${fieldName?.id}`}
                        options={leadLookupData?.LDPConfigQueryGroup?.GetLeadDataLookup}
                        keyValuePairs={mapper || {}} />
                    </Grid>
                  </>
                )
              )}   
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableButtons}
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
            </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableButtons}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
            </Button>
            </Grid>
          </Grid>
        </form>
      )
      }
      <Dialog
        open={changeToBoolean.open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm field change</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are changing Field type [{changeToBoolean?.prevValue?.id || ''}] to boolean. This will reset previous map. 
            Continue?`
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangeFieldName} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Paper >
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mapContrainer: {
      overflowY: "auto",
      overflowX: "hidden",
      maxHeight: "280px"
    },
    mainGrid: {
      padding: "20px",
    },
    textCenter: {
      textAlign: "center"
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    divider: {
      margin: "10px 0",
    },
  })
);

export const MappingModal = connect()(WrappedMappingModal);