import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  Divider,
  Tooltip,
  TextField,
  Button,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";
import { UPDATE_MODIFY_PRICE_BY_SIGNUPID } from "../../common/models/modifyPrice";
import SaveIcon from "@material-ui/icons/Save";
import { toast } from "react-toastify";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";

interface ModifyPriceModalProps {
  data: any;
  signupId: number;
  close: Function;
  refetch: Function;
}

const formError = {
  BuyerName: {
    required: {
      value: true,
      message: "Buyer Name is required.",
    },
  },
  Amount: {
    //required: {
    //  value: true,
    //  message: "New Price is required.",
    //},
    // min: {
    //   value: 0,
    //   message: "New Price can't be less than 0",
    // },
    max: {
      value: 100,
      message: "New Price can't be greater than 100",
    },    
    pattern: {
      value: /^(0|[1-9]\d*)(\.\d+)?$/,
      message: "Please input numeric numbers only.",
    },
  },
};

const ModifyPriceModal = ({
  data,
  signupId,
  close,
  refetch,
}: ModifyPriceModalProps) => {
  const classes = useStyles();
  const { register, handleSubmit, watch, errors } = useForm();
  const [saveBuyer, { data: saveBuyerData, error: saveBuyerError, loading: saveBuyerLoading }] = useMutation(UPDATE_MODIFY_PRICE_BY_SIGNUPID);
  const googleUserMeta = GoogleUserMeta();

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  // useEffect(() => {
  //   console.log("errors", errors);
  // }, [errors]);
  
  function handleDecimalsOnValue(value: any) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.toString().match(regex)[0];
}

  const onSubmit = (data: any) => {
    console.log(data);
    data.ModifyPrice.map((price: any) => {
      const amount = parseInt(price.Amount);      
      if (amount || amount >= 0) {        
        saveBuyer({
          variables: {
            modifyPrice: {
              BuyerContractId: parseInt(price.BuyerContractId),
              NewPrice: handleDecimalsOnValue(price.Amount),
              SignupId: signupId,
              UserId: googleUserMeta?.email,
            },
          },
        }).then(() => {
          refetch();
        });
      }      
    });
    toast.success("Prices updated successfully.");
    close();
  };

  return (
    <Paper className={classes.contrainer}>
      {data && (
        <Grid className={classes.mainGrid} container>
          <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            {/* <Grid className={classes.mainGrid} container spacing={2}> */}
            {data.map((price: any, index: number) => (
              <Grid className={classes.innerGrid} container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    required
                    inputRef={register}
                    label="Buyer Contract Id"
                    name={`ModifyPrice.[${index}].BuyerContractId`}
                    defaultValue={price?.BuyerContractId ?? 0}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    inputRef={register(formError.BuyerName)}
                    name={`ModifyPrice.[${index}].BuyerName`}
                    label="Buyer Name"
                    defaultValue={price?.BuyerName ?? ""}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField                                        
                    label="Current Price"
                    defaultValue={price?.Amount ?? ""}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    inputRef={register(formError.Amount)}
                    error={
                      errors?.ModifyPrice && errors?.ModifyPrice[index] && true
                    }
                    helperText={
                      errors?.ModifyPrice &&
                      errors?.ModifyPrice[index] &&
                      errors?.ModifyPrice[index].Amount.message
                    }
                    name={`ModifyPrice.[${index}].Amount`}
                    label="New price"
                    variant="outlined"
                    focused 
                    autoFocus
                  />
                </Grid>
              </Grid>
            ))}

            <Grid className={classes.innerGrid} container spacing={2}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  type="button"
                  size="large"
                  fullWidth
                  onClick={() => close()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  fullWidth
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </Paper>
  );
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
      overflowY: "auto",
      overflowX: "hidden",
      height: "auto",
    },
    mainGrid: {
      padding: "20px",
    },
    innerGrid: {
      padding: "5px 10px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    divider: {
      margin: "10px 0",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
      margin: "0px auto",
    },
    linkTypo: {
      display: "inline",
      overflow: "hidden",
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  })
);

export default ModifyPriceModal;
