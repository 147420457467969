import { useMutation } from "@apollo/react-hooks";
import {
  Box,
  Button,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  SAVE_BULK_CONTRACT_CAPACITY,
  SAVE_CONTRACT_CAPACITY,
} from "../../common/models/contractCapacity";
import { GetContractCapacity_LDPConfigQueryGroup_ContractCapacity as ContractCapacity } from "../../common/models/types/GetContractCapacity";
import {
  compareTimes,
  convert12HourFormatToMilitaryTime,
  convertMititaryTimeTo12HourFormat,
  getCurrentTime24HourFormat,
  isValidTimeFormat,
  toggleAMPM,
} from "../../common/utils/time";
import TimeInput from "../../components/timeInput";
import {
  ContractCapacityBulkInputType,
  ContractCapacityInputType,
} from "../../types/graphql-global-types";

interface ContractCapacityFormModalProps {
  data: ContractCapacity | any;
  action: string;
  close: Function;
  refetch: Function;
}

interface ContractCapacityInput extends ContractCapacityInputType {
  StartTimeAMPM?: string | null;
  EndTimeAMPM?: string | null;
}

export const ContractCapacityFormModal = ({
  action,
  data,
  close,
  refetch,
}: ContractCapacityFormModalProps) => {
  // what kind of validation was this? if you throw a time in here it walys ends up invalid
  // const isValidDate = (dateObject: string) => new Date(dateObject).toString() !== 'Invalid Date';

  const defaultStartTime = convertMititaryTimeTo12HourFormat(
    data?.StartTime ?? '00:00:00'
  );
  const defaultEndTime = convertMititaryTimeTo12HourFormat(
    data?.EndTime ?? '23:59:59'
  );

  const defaultValues = {
    StartTime: defaultStartTime,
    EndTime: defaultEndTime,
    StartTimeAMPM: defaultStartTime.split(" ")[1],
    EndTimeAMPM: defaultEndTime.split(" ")[1],
  };

  /** use for displaying dates on components that doesnt support moment objects */
  // const [startDateNative, setStartDateNative] = useState<Date>(
  //   defaultValues.StartTime
  // );
  // const [endDateNative, setEndDateNative] = useState<Date>(
  //   defaultValues.EndTime
  // );

  const [saveContractCapacity] = useMutation(SAVE_CONTRACT_CAPACITY);
  const [saveBulkContractCapacity] = useMutation(SAVE_BULK_CONTRACT_CAPACITY);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    errors,
    setValue,
  } = useForm<ContractCapacityInput>({ defaultValues });
  const classes = useStyles();
  const pageTitle =
    (action == "create" ? "Add New" : "Edit") + " Contract Capacity";

  const today = new Date();
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [scheduleDay, setScheduleDay] = useState<Array<string> | undefined>(
    data?.ScheduleDay ? [data?.ScheduleDay] : [dayNames[today.getDay()]]
  );

  const [startTime, setStartTime] = useState(
    convertMititaryTimeTo12HourFormat(data?.StartTime)
  );
  const [endTime, setEndTime] = useState(
    convertMititaryTimeTo12HourFormat(data?.EndTime)
  );

  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState("");
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState("");

  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const onSubmit = async (ContractCapacityInput: ContractCapacityInput) => {
    const vars: ContractCapacityBulkInputType & ContractCapacityInput = {
      ...ContractCapacityInput,
      StartTime: convert12HourFormatToMilitaryTime(startTime),
      EndTime: convert12HourFormatToMilitaryTime(endTime),
    };

    if (action === "create") {
      const data = {
        ...vars,
        ScheduleDay: scheduleDay?.join(","),
      };
      delete data?.ContractCapacityId;
      delete data?.StartTimeAMPM;
      delete data?.EndTimeAMPM;

      try {
        const response = await saveBulkContractCapacity({
          variables: {
            contractCapacityInput: data,
          },
        });
        const responseMessage =
          response.data?.LDPConfigMutationGroup?.SaveBulkContractCapacity[0];
        if (responseMessage === "Success") {
          toast.success(`Contract Capacity created successfully.`);
          refetch();
          close();
          return true;
        } else {
          toast.error(responseMessage, {
            autoClose: false, // Disable auto close
          });
          return false;
        }
      } catch (error) {
        // Handle unexpected errors
        // toast.error("Unexpected error!");
        return false;
      }
    } else {
      const savingCapacityPromises = scheduleDay?.map(async (value, index) => {
        const data = {
          ...vars,
          ContractCapacityId:
            index > 0 ? "-1" : ContractCapacityInput.ContractCapacityId,
          ScheduleDay: value?.toString(),
        };
        delete data?.IsHourly;
        delete data?.StartTimeAMPM;
        delete data?.EndTimeAMPM;

        try {
          const response = await saveContractCapacity({
            variables: {
              contractCapacityInput: [data],
            },
          });

          const responseMessages =
            response.data?.LDPConfigMutationGroup?.SaveContractCapacity ?? [];
          const allSuccess = responseMessages.every(
            (msg: string) => msg === "Success"
          );

          if (allSuccess) {
            toast.success(`Contract Capacity updated successfully.`);
            return true;
          } else {
            const errorMsg = responseMessages.join(", ");
            toast.error(errorMsg);
            return false;
          }
        } catch (error) {
          // Handle unexpected errors
          // toast.error("Unexpected error!");
          return false;
        }
      });

      const savingCapacityResults = await Promise.all(
        savingCapacityPromises || []
      );
      const result = savingCapacityResults.every((res) => res);

      if (result) {
        refetch();
        close();
      }
    }

    setDisableButtons(false);
  };

  const ContractCapacityConstraints = {
    ScheduleDay: {
      validate: (value: any) =>
        Array.isArray(scheduleDay) && scheduleDay.length > 0,
      message: "Schedule Day is required.",
    },
    StartTime: {
      required: {
        value: true,
        message: "Start Time is required.",
      },
    },
    /* StartTimeSecondsPastMidnightUtc: {
      required: {
        value: true,
        message: "Start Time Seconds Past Midnight in UTC is required.",
      },      
    },*/
    EndTime: {
      required: {
        value: true,
        message: "End Time is required.",
      },
    },
    /* EndTimeSecondsPastMidnightUtc: {
      required: {
        value: true,
        message: "End Time Seconds Past Midnight in UTC is required.",
      },
    },  */
    MaxCapacity: {
      required: {
        value: true,
        message: "Max Capacity is required.",
      },
      min: {
        value: 0,
        message: "Minimum value is 0",
      },
    },
    Price: {
      required: {
        value: true,
        message: "Price Floor is required.",
      },
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Price Floor value.",
      },
      min: {
        value: 0,
        message: "Minimum value is 0",
      },
      max: {
        value: 100,
        message: "Maximum value is 100",
      },
    },
  };

  const watchStartTime = watch("StartTime");
  const watchStartTimeAMPM = watch("StartTimeAMPM");
  const watchEndTime = watch("EndTime");
  const watchEndTimeAMPM = watch("EndTimeAMPM");

  useEffect(() => {
    if (watchStartTime && watchStartTimeAMPM) {
      const newTime = toggleAMPM(watchStartTime, watchStartTimeAMPM);
      setStartTime(newTime);
    }
  }, [watchStartTime, watchStartTimeAMPM]);

  useEffect(() => {
    if (watchEndTime && watchEndTimeAMPM) {
      setEndTime(toggleAMPM(watchEndTime, watchEndTimeAMPM));
    }
  }, [watchEndTime, watchEndTimeAMPM]);

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs>
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractCapacityId"
              defaultValue={data?.ContractCapacityId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractId"
              defaultValue={data?.ContractId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="schedule-day-input"
              options={dayNames}
              value={scheduleDay}
              onChange={(event: any, newValue: Array<string> | undefined) => {
                setScheduleDay(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Schedule Day"
                  variant="outlined"
                  inputRef={register(ContractCapacityConstraints.ScheduleDay)}
                  error={errors.ScheduleDay && true}
                  helperText={
                    errors.ScheduleDay &&
                    ContractCapacityConstraints.ScheduleDay.message
                  }
                  name="ScheduleDay"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <Controller
                render={({ ref, ...rest }) => {
                  return (
                    <TextField
                      {...rest}
                      error={errors.StartTime && true}
                      helperText={errors.StartTime && startTimeErrorMessage}
                      label="Start Time (PST)"
                      variant="outlined"
                      InputProps={{
                        inputComponent: TimeInput, // Use the TimeInput component as the custom input component
                      }}
                    />
                  )
                }}
                name="StartTime"
                control={control}
                rules={{
                  validate: () => {
                    if (!isValidTimeFormat(startTime)) {
                      setStartTimeErrorMessage(
                        "Please input a valid time with format HH:MM:SS AM/PM"
                      );
                      return false;
                    }
                    if (!compareTimes(startTime, endTime)) {
                      setStartTimeErrorMessage(
                        "Start Time should be lesser than End Time."
                      );
                      return false;
                    }
                  },
                }}
              />
              <FormControl style={{ width: "100px" }}>
                <Controller
                  render={({ ref, ...rest }) => (
                    <Select
                      {...rest}
                      variant="outlined"
                      error={errors.StartTime && true}
                    >
                      <MenuItem value="AM"> AM </MenuItem>
                      <MenuItem value="PM"> PM </MenuItem>
                    </Select>
                  )}
                  name="StartTimeAMPM"
                  control={control}
                />
                <FormHelperText hidden={!errors.StartTime}>
                  &nbsp;
                </FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <Controller
                render={({ ref, ...rest }) => (
                  <TextField
                    {...rest}
                    error={errors.EndTime && true}
                    helperText={errors.EndTime && endTimeErrorMessage}
                    label="End Time (PST)"
                    variant="outlined"
                    InputProps={{
                      inputComponent: TimeInput, // Use the TimeInput component as the custom input component
                    }}
                  />
                )}
                name="EndTime"
                control={control}
                rules={{
                  validate: () => {
                    if (!isValidTimeFormat(endTime)) {
                      setEndTimeErrorMessage(
                        "Please input a valid time with format HH:MM:SS AM/PM"
                      );
                      return false;
                    }
                    if (!compareTimes(startTime, endTime)) {
                      setEndTimeErrorMessage(
                        "End Time should be greater than Start Time."
                      );
                      return false;
                    }
                  },
                }}
              />

              <FormControl style={{ width: "100px" }}>
                <Controller
                  render={({ ref, ...rest }) => (
                    <Select
                      {...rest}
                      variant="outlined"
                      error={errors.EndTime && true}
                    >
                      <MenuItem value="AM"> AM </MenuItem>
                      <MenuItem value="PM"> PM </MenuItem>
                    </Select>
                  )}
                  name="EndTimeAMPM"
                  control={control}
                />
                <FormHelperText hidden={!errors.EndTime}>&nbsp;</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractCapacityConstraints.MaxCapacity)}
              error={errors.MaxCapacity && true}
              helperText={errors.MaxCapacity && errors.MaxCapacity?.message}
              name="MaxCapacity"
              label="Max Capacity"
              defaultValue={data?.MaxCapacity ?? ""}
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Notes"
              label="Notes"
              defaultValue={data?.Notes ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractCapacityConstraints.Price)}
              error={errors.Price && true}
              helperText={errors.Price && errors.Price?.message}
              name="Price"
              label="Price Floor"
              defaultValue={data?.Price ?? ""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={action === "create" ? 4 : 6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsPing ?? true}
                  name="IsPing"
                  color="primary"
                />
              }
              label="Is Ping"
            />
          </Grid>
          <Grid item xs={12} sm={4} hidden={action !== "create"}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsHourly ?? false}
                  name="IsHourly"
                  color="primary"
                />
              }
              label="Is Hourly"
            />
          </Grid>
          <Grid item xs={12} sm={action === "create" ? 4 : 6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? false}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
