import { ReactNode } from "react";

export interface TabProps {
  title: string | ReactNode;
  tabId: string;
  content: ReactNode;
  closeTab?: Function | boolean; //override default close tab callback
  permalink?: string;
}

export interface tabContainerStateProps {
  [key: string]: {
    tabs:TabProps[];
    tabFocus: number;
  };
}
export interface tabsSectionStateProps {
  tabContainers: tabContainerStateProps;
}

const initialTabContainersState: tabsSectionStateProps = {
  tabContainers: {},
};


const TAB_CONTAINERS_UPDATE = "TAB_CONTAINERS_UPDATE";
const TAB_CONTAINERS_DELETE = "TAB_CONTAINERS_DELETE";

interface tabAction {
  type: string;
  payload?: any;
}

export const updateTabContainers = (payload: tabContainerStateProps): tabAction => {  
  return {
    type: TAB_CONTAINERS_UPDATE,
    payload,
  }  
};

export const deleteTabContainers = (payload: string): tabAction => ({
  type: TAB_CONTAINERS_DELETE,
  payload,
});

export default (state = initialTabContainersState, action:tabAction):tabsSectionStateProps => {
  switch (action.type) {
    case TAB_CONTAINERS_UPDATE:      
      return { ...state, tabContainers: { ...state.tabContainers, ...action.payload} };

    case TAB_CONTAINERS_DELETE:
      if(typeof(state.tabContainers[action.payload]) !== "undefined") delete state.tabContainers[action.payload];
      return state;
    
    default:
      return state;
  }
};