import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
} from "@material-ui/core";

import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SAVE_BUYER_GROUP } from "../../common/models/buyergroups";
import { MergeBuyerGroupInputType as BuyerGroupInputType } from "../../types/graphql-global-types";
import { GetVertical, GetVertical_LDPConfigQueryGroup_Vertical } from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { GetSubVertical, GetSubVertical_LDPConfigQueryGroup_SubVertical } from "../../common/models/types/GetSubVertical";

import { signalBuyerGroupSaved } from "../../state/buyerGroupSectionReducer";

interface BuyerGroupFormModalProps {
  data: any | null;
  action: string;
  close: Function;
  refetch: Function;
  dispatch: Function;
}

const WrappedBuyerGroupFormModal = ({
  action,
  data,
  close,
  refetch,
  dispatch
}: BuyerGroupFormModalProps) => {
  const classes = useStyles();
  const pageTitle = action == "create" ? "Add New Buyer Group" : "Edit Buyer Group";

  const [getVerticals, { data: verticalData }] = useLazyQuery<GetVertical>(GET_VERTICAL);
  const [verticalOptions, setVerticalOptions] = useState([]);
  const [vertical, setVertical] = useState<any>(null);

  const [getSubverticals, { data: subVerticalData }] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);
  const [subVerticalOptions, setSubVerticalOptions] = useState([]);
  const [subVertical, setSubVertical] = useState<any>(null);

  useEffect(() => {
    if (verticalData) 
    {
      setVerticalOptions(verticalData?.LDPConfigQueryGroup?.Vertical?.map((item: GetVertical_LDPConfigQueryGroup_Vertical) => (
        {
          id: item?.VerticalId,
          label: item?.VerticalName
        }
      )));
    }
    else getVerticals();
    
    if (! subVerticalData) 
      getSubverticals();

  }, [verticalData, subVerticalData]);

  // render vertical options
  useEffect(() => {
    setVertical(verticalOptions?.find((it: any) => it?.id === data?.VerticalId) || null);
  }, [verticalOptions]);

  // render sub-vertical options based on vertical selected value
  useEffect(() => {
    if (vertical) {
      const subVerticalList = subVerticalData?.LDPConfigQueryGroup?.SubVertical?.map((item: GetSubVertical_LDPConfigQueryGroup_SubVertical) => (
        {
          id: item?.SubVerticalId,
          label: item?.SubVerticalName,
          parent: item?.VerticalId
        }
      )) ?? [];

      const options = subVerticalList.filter((it: any) => it?.parent === vertical.id) ?? [];
      setSubVerticalOptions(options);
      setSubVertical(options?.find((it: any) => it?.id === data?.SubVerticalId) || null);
    }
  }, [vertical]);

  const [saveBuyerGroup] = useMutation(SAVE_BUYER_GROUP);
  const { register, handleSubmit, control, getValues, watch, errors } = useForm<BuyerGroupInputType>();

  const [disableButtons, setDisableButtons] = useState<Boolean>(false);
  const onSubmit = (BuyerGroupInput: BuyerGroupInputType) => {
    setDisableButtons(true);
    saveBuyerGroup({
      variables: {
        buyerGroupInput: BuyerGroupInput
      }
    }).then((response: any) => {
      toast.success(`Buyer Group ${(BuyerGroupInput?.BuyerGroupId > 0 ? "updated" : "created")} successfully.`);
      setDisableButtons(false);
      refetch();
      dispatch(signalBuyerGroupSaved());
      close();
    }).catch(({errors})=>{      
      setDisableButtons(false);
      if(errors)
        toast.error("Unexpected error!");
    });
  };

  const BuyerGroupConstraints = {
    BuyerGroupName: {
      required: {
        value: true,
        message: "Buyer Group Name is required."
      }
    }
  };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              hidden
              inputRef={register}
              name="BuyerGroupId"
              defaultValue={data?.BuyerGroupId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
            <TextField
              inputRef={register(BuyerGroupConstraints.BuyerGroupName)}
              error={errors.BuyerGroupName && true}
              helperText={errors.BuyerGroupName && errors.BuyerGroupName?.message}
              name="BuyerGroupName"
              label="Buyer Group Name"
              defaultValue={data?.BuyerGroupName ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Position"
              label="Position"
              defaultValue={data?.Position ?? 1}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
                id="vertical-id-input"
                options={verticalOptions}
                value={vertical}
                getOptionLabel={option => option.label}
                onChange={(event: any, newValue: any) => {
                  setVertical(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputRef={register}
                    name="VerticalName"
                    label="Vertical Name"
                    variant="outlined"
                  />
                )}
              />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
                id="subvertical-id-input"
                options={subVerticalOptions}
                value={subVertical}
                getOptionLabel={option => option.label}
                onChange={(event: any, newValue: any) => {
                  setSubVertical(newValue);
                }}
                disabled={! vertical}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputRef={register}
                    name="SubVerticalName"
                    label="Subvertical Name"
                    variant="outlined"
                  />
                )}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="OverrideExclusiveSale"
              label="Override Exclusive Sale"
              defaultValue={data?.OverrideExclusiveSale ?? 0}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

export const BuyerGroupFormModal = connect()(WrappedBuyerGroupFormModal);