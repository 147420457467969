import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button, createStyles, Divider, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, Paper, Select, TextField, Theme
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { queryLoad } from "../../components";

import { GetSubVerticalRule, GetSubVerticalRule_LDPConfigQueryGroup_GetSubVerticalRule } from "../../common/models/types/GetSubVerticalRule";
import { signalBuyerSaved } from "../../state/buyerSectionReducer";

import { GET_SUBVERTICAL_RULE, SAVE_UNDERSOLD_SETTING } from "../../common/models/subverticalRule";
import { GetSubVertical } from "../../common/models/types/GetSubVertical";
import { GetVertical } from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { withinPast } from "../../common/utils/date";
import { GoogleUserMetaProp } from "../../common/utils/googleUserMeta";
import BigDamnSwitch from "../../components/bigSwitch";
import { eventTracker as tracker } from '../../components/tracker';
import { RootState } from "../../state";
import { DataCacheState, updateSubVerticals, updateVerticals } from "../../state/dataCacheReducer";

interface UndersoldSettingFormProps {
  entryId?: number;
  close: Function;
  refetch: Function;
  dispatch: Function;
  googleUserMeta?: GoogleUserMetaProp;
  entityDataCache?: DataCacheState;
}

interface OptionProps {
  label: string;
  value: string;
  parent?: number;
  id: number;
}

type SubVerticalDataType = Omit<Omit<GetSubVerticalRule_LDPConfigQueryGroup_GetSubVerticalRule, "__typename">, "UserId">;

const formError = {
  VerticalName: {
    required: {
      value: true,
      message: "Vertical is required.",
    },
  },
  SubVerticalName: {
    required: {
      value: true,
      message: "Sub Vertical is required.",
    },
  },
  CatchAllThreshold: {
    required: {
      value: true,
      message: "Catch All value is required.",
    },
    min: 0,
    valueAsNumber: true,
  },
  UndersoldThreshold: {
    required: {
      value: true,
      message: "Undersold value required.",
    },
    min: 0,
    valueAsNumber: true,
  },
  MaxSalesPerLead: {
    required: {
      value: true,
      message: "Max Sales per Lead is required.",
    },
    min: 0,
    valueAsNumber: true,
  },
};

const WrappedSubVerticalConfigFormModal = ({
  entryId,
  close,
  refetch,
  dispatch,
  googleUserMeta,
  entityDataCache
}: UndersoldSettingFormProps) => {
  const [getAllUndersoldSettings, { data, error }] = useLazyQuery<GetSubVerticalRule>(GET_SUBVERTICAL_RULE);
  const [saveUndersoldSetting] = useMutation(SAVE_UNDERSOLD_SETTING);

  const { register, handleSubmit, watch, errors } = useForm<SubVerticalDataType>();
  const classes = useStyles();

  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);
  const [
    getAllSubVertical,
    {
      data: subVerticalData,
      error: subVerticalError,
      loading: subVerticalLoading,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  const [pageLoading, setPageLoading] = useState<boolean>(entryId ? true : false);
  const [ruleData, setRuleData] = useState<SubVerticalDataType>();

  const [VerticalList, setVerticalList] = useState<OptionProps[]>([]);
  const [SubVerticalList, setSubVerticalList] = useState<OptionProps[]>([]);

  const [selectedVertical, setSelectedVertical] = useState<OptionProps>();
  const [selectedSubVertical, setSelectedSubVertical] = useState<OptionProps>();

  useEffect(() => {
    getAllUndersoldSettings();
  }, []);

  useEffect(() => {
    if (data && entryId) {
      setRuleData({ ...data.LDPConfigQueryGroup?.GetSubVerticalRule?.find(r => r?.SubVerticalRuleId == entryId) });
    }

    if (
      !entityDataCache?.ActiveVerticals ||
      (entityDataCache?.ActiveVerticals &&
        !withinPast(entityDataCache?.ActiveVerticals.time, { days: 1 }))
    ) {
      getAllVertical({ variables: { where: "IsActive = true" } });
    }
  }, [data, error]);

  /** auto-select existing entry if combination of vertical and sub-vertical is found */
  useEffect(() => {
    if(selectedVertical && selectedSubVertical){
      if(pageLoading){
        setPageLoading(false);
      }

      if (data) {
        setRuleData(data.LDPConfigQueryGroup?.GetSubVerticalRule?.find((r) =>
          r?.VerticalName == selectedVertical?.value && r?.SubVerticalName === selectedSubVertical?.value));
      }
    }
    
  }, [selectedVertical, selectedSubVertical]);

  /** begin loading selection values */

  /** vertical option list */
  useEffect(() => {
    if (verticalData && !verticalError) {
      dispatch &&
        dispatch(
          updateVerticals(verticalData?.LDPConfigQueryGroup?.Vertical || [])
        );
    }
  }, [verticalData, verticalError]);

  // update vertical options list when data cache is loaded
  useEffect(() => {
    if (!entityDataCache?.ActiveVerticals) return;

    setVerticalList(
      entityDataCache?.ActiveVerticals?.data?.map((it) => ({
        label: `${it?.VerticalName}`,
        value: `${it?.VerticalName}`,
        id: it?.VerticalId,
      })) ?? []
    );

    if (!entityDataCache?.ActiveSubVerticals ||
      (entityDataCache?.ActiveSubVerticals && !withinPast(entityDataCache?.ActiveSubVerticals.time, { days: 1 }))
    ) {
      getAllSubVertical({ variables: { where: "IsActive = true" } });
    }
  }, [entityDataCache?.ActiveVerticals]);

  useEffect(() => {
    //console.log('verticals loaded', pageLoading, VerticalList.length, selectedVertical, ruleData);
    if (VerticalList.length > 0 && pageLoading) {
      if(ruleData){
        setSelectedVertical(VerticalList.find(v => v.value === ruleData?.VerticalName));
        //console.log('set loading vertical by data', ruleData?.VerticalName);
      } else {
        // setSelectedVertical(VerticalList[0]);
        setSelectedVertical(undefined);
        //console.log('set loading vertical by auto', VerticalList[0].value);
      }
      
    }
  }, [VerticalList, ruleData]);

  useEffect(() => {
    if (subVerticalData && !subVerticalError) {
      dispatch &&
        dispatch(
          updateSubVerticals(
            subVerticalData?.LDPConfigQueryGroup?.SubVertical || []
          )
        );
    }
  }, [subVerticalData, subVerticalError]);

  /** sub-vertical option list */
  useEffect(() => {
    // console.log('update subs ', selectedVertical);
    if (selectedVertical && entityDataCache?.ActiveSubVerticals) {
      const filteredSubVerticals =
        entityDataCache?.ActiveSubVerticals?.data?.filter(
          (it) => it?.VerticalId === selectedVertical?.id
        ) ?? [];

      if (filteredSubVerticals?.length > 0) {
        const subs = filteredSubVerticals?.map((it) => ({
          label: `${it?.SubVerticalName}`,
          value: `${it?.SubVerticalName}`,
          parent: it?.VerticalId,
          id: it?.SubVerticalId,
        }));

        setSubVerticalList(subs);
        setSelectedSubVertical(subs.find(sv => sv.value === ruleData?.SubVerticalName) || undefined);
      }
    }
  }, [selectedVertical, entityDataCache?.ActiveSubVerticals]);


  const onSubmit = (subVerticalRuleInput: SubVerticalDataType) => {
    const dataSubmit: SubVerticalDataType = {
      ...subVerticalRuleInput,
      SubVerticalRuleId: ruleData?.SubVerticalRuleId || 0,
      VerticalName: selectedVertical?.value || ruleData?.VerticalName || null,
      SubVerticalName: selectedSubVertical?.value || ruleData?.SubVerticalName || null,
    };

    saveUndersoldSetting({ 
      variables: { 
        mergeSubVerticalRuleParams: dataSubmit
      } 
    }).then((response: any) => {
      eventTracker({ mergeSubVerticalRuleParams: dataSubmit });
      toast.success("Sub-Vertical Rule successfully saved.");

      refetch();
      dispatch(signalBuyerSaved());
      close();
    });
  };

  const eventTracker = ({ submittedData }: any) => {
    const blockers: any = { '@': '[at]', '.': '[dot]' };
    tracker({
      name: "Sub-Vertical Rule",
      caption: "Track Sub-Vertical Rules Changes",
      values: {
        ...submittedData,
        email: googleUserMeta?.email?.replace(/\@|\./g, it => blockers[it]) ?? null
      }
    });
  }

  return (
    <Paper className={classes.contrainer}>
      {queryLoad([!!pageLoading], []) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12} sm={8} key="id-and-vertical">
              <TextField
                hidden
                inputRef={register}
                name="SubVerticalRuleId"
                value={ruleData?.SubVerticalRuleId ?? 0}
                variant="outlined"
              />
              <FormControl variant="outlined" fullWidth error={errors.VerticalName ? true : false}>
                <InputLabel htmlFor="outlined-v-native-simple">Vertical</InputLabel>
                <Select
                  fullWidth
                  native
                  id='outlined-v-native-simple'
                  variant="outlined"
                  value={selectedVertical?.value || undefined}
                  onChange={(evt) => {
                    setSelectedVertical(VerticalList.find(v => v.value === evt.target.value) );
                  }}
                  label="Vertical"
                  inputRef={register(formError.VerticalName)}
                  name="VerticalName"
                  disabled={entryId ? true : false}
                >
                  <option key='vertical-empty' value={undefined}>{''}</option>
                  {VerticalList.map(v => <option key={`verticals-${v.label}`} value={v.value}>{v.label}</option>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} key="is-active">
              <FormControlLabel
                control={<BigDamnSwitch
                  size="medium"
                  checked={!!ruleData?.IsActive}
                  onClick={(evt) => {
                    setRuleData({
                      ...ruleData,
                      IsActive: !ruleData?.IsActive,
                    });
                  }}
                  inputRef={register}
                  name="IsActive" />}
                label="Is Active"
              />
            </Grid>
            <Grid item xs={12} key="sub-vertical">
              <FormControl variant="outlined" fullWidth error={errors.SubVerticalName ? true : false}>
                <InputLabel htmlFor="outlined-sv-native-simple">Sub-Vertical</InputLabel>
                <Select
                  fullWidth
                  native
                  variant="outlined"
                  id='outlined-sv-native-simple'
                  value={selectedSubVertical?.value || undefined}
                  onChange={(evt) => {
                    setSelectedSubVertical(SubVerticalList.find(v => v.value === evt.target.value));
                  }}
                  label="Sub-Vertical"
                  inputRef={register(formError.SubVerticalName)}
                  name="SubVerticalName"
                  disabled={entryId ? true : false}
                >
                  <option key='vertical-empty' value={undefined}>{''}</option>
                  {SubVerticalList.map(v => <option key={`sub-verticals-${v.label}`} value={v.value}>{v.label}</option>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} key="catch-all">
              <TextField
                type="number"
                inputRef={register(formError.CatchAllThreshold)}
                error={errors.CatchAllThreshold && true}
                helperText={errors.CatchAllThreshold && errors.CatchAllThreshold?.message}
                name="CatchAllThreshold"
                label="Catch All Threshold"
                value={ruleData?.CatchAllThreshold ?? 0}
                onChange={(evt) => {
                  let v = evt.target.value;
                  if(v.trim().startsWith('.')){
                    v = `0${v}`;
                  }

                  setRuleData({
                    ...ruleData,
                    CatchAllThreshold: v.replace(/[^\d.]/g, ""),
                  });
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} key="undersold-thresh">
              <TextField
                type="number"
                inputRef={register(formError.UndersoldThreshold)}
                error={errors.UndersoldThreshold && true}
                helperText={errors.UndersoldThreshold && errors.UndersoldThreshold?.message}
                name="UndersoldThreshold"
                label="Undersold Threshold"
                value={ruleData?.UndersoldThreshold ?? 0}
                onChange={(evt) => {
                  let v = evt.target.value;
                  if(v.trim().startsWith('.')){
                    v = `0${v}`;
                  }

                  setRuleData({
                    ...ruleData,
                    UndersoldThreshold: v.replace(/[^\d.]/g, ""),
                  });
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} key="max-per-lead">
              <TextField
                type="number"
                inputRef={register(formError.MaxSalesPerLead)}
                error={errors.MaxSalesPerLead && true}
                helperText={errors.MaxSalesPerLead && errors.MaxSalesPerLead?.message}
                name="MaxSalesPerLead"
                label="Max Sales per Lead"
                value={ruleData?.MaxSalesPerLead ?? 0}
                onChange={(evt) => {
                  setRuleData({
                    ...ruleData,
                    MaxSalesPerLead: evt.target.value.replace(/[^\d]/g, ""),
                  });
                }}
                onBlur={(evt) => {
                  setRuleData({
                    ...ruleData,
                    MaxSalesPerLead: Number(evt.target.value),
                  });
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} key="no-content-divider">
              <Divider />
            </Grid>
            <Grid item xs={6} key="but-1">
              <Button
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => {
                  close();
                  refetch();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} key="but-2">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

const mapStateToProps = (
  state: RootState
) => ({
  entityDataCache: state.entityDataCache,
});

export default connect(mapStateToProps, null)(WrappedSubVerticalConfigFormModal);