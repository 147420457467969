import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { SAVE_CONTRACT_RESPONSE_TOKEN } from "../../common/models/contractResponseToken";
import { ContractResponseTokenInputType } from "../../types/graphql-global-types";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SaveIcon from "@material-ui/icons/Save";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { MenuInputAdornment } from "../../components/menuInputAdornment";

interface ContractResponseTokenFormModalProps {
  data: ContractResponseTokenInputType | null;
  contractId: any;
  action: string;
  close: Function;
  refetch: Function;
}

interface MappedSelectField {
  id: string;
  label: string;
}

const formError = {
  TokenName: {
    required: {
      value: true,
      message: "Token Name is required.",
    },
  },
  TokenPath: {
    required: {
      value: true,
      message: "Token Path is required.",
    },
  },
  TokenType: {
    required: {
      value: true,
      message: "Token Type is required.",
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    formControl: {
      //margin: theme.spacing(1),
      width: "100%",
      margin: "0",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export const ContractResponseTokenModal = ({
  action,
  contractId,
  data,
  close,
  refetch,
}: ContractResponseTokenFormModalProps) => {
  const { register, handleSubmit, errors, setValue } = useForm<ContractResponseTokenInputType>();
  const classes = useStyles();
  const pageTitle = action == "create" ? "Add New Token" : "Edit Token";

  const [saveContractResponseToken] = useMutation(SAVE_CONTRACT_RESPONSE_TOKEN);
  const [loading, setLoading] = useState(true);
  const [mappedTokenType, setMappedTokenType] = useState<MappedSelectField[]>();
  const [selectedTokenType, setTokenType] = useState<MappedSelectField | null>();
  const tokenTypeList = ['Boolean', 'Byte', 'Char', 'Decimal', 'Double', 'Int16', 'Int32', 'Int4', 'String', 'DateTime'];
  // { id: "Boolean", label: "Boolean" },
  // { id: "Byte", label: "Byte" },
  // { id: "Char", label: "Char" },
  // { id: "Decimal", label: "Decimal" },
  // { id: "Double", label: "Double" },
  // { id: "Int16", label: "Int16" },
  // { id: "Int32", label: "Int32" },
  // { id: "Int4", label: "Int4" },
  // { id: "String", label: "String" },
  // { id: "DateTime", label: "DateTime" }
  useEffect(() => {
    setMappedTokenType(
      tokenTypeList.map((x) => {
        return { id: x, label: x };
      })
    );
  }, []);

  useEffect(() => {
    if (mappedTokenType) {
      setTokenType(
        mappedTokenType?.find(
          (responseDisposition: MappedSelectField) => responseDisposition?.id === data?.TokenType
        ) || null
      );
      setLoading(false);
    }
  }, [mappedTokenType]);

  const onSubmit = (contractResponseTokenData: ContractResponseTokenInputType) => {
    saveContractResponseToken({ variables: { data: contractResponseTokenData } }).then(
      () => {
        if (contractResponseTokenData?.ContractResponseTokenId > 0)
          toast.success("Token updated successfully.");
        else toast.success("Token created successfully.");

        refetch();
        close();
      }
    ).catch((error) => {
      console.log('saveContractResponseToken', error);
    });
  };

  const [tokenName, setTokenName] = useState<String>(data?.TokenName ?? "");
  const setTokenTypeValue = (value: string) => {
    let type = "";
    switch(value) {
      case "PingSessionId":
      case "PingUrlRedirect": type = "String";
        break;
      case "Price": type = "Decimal";
        break;
      default: type = "String";
    }
    setValue("TokenType", type);
    setTokenType(mappedTokenType?.find(
      (responseDisposition: MappedSelectField) => responseDisposition?.id === type
    ) || null);
  }

  return (
    <Paper className={classes.contrainer}>
      { !!loading || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            hidden
            inputRef={register}
            name="ContractResponseTokenId"
            defaultValue={data?.ContractResponseTokenId ?? 0}
          />
          <TextField
            hidden
            inputRef={register}
            name="ContractId"
            defaultValue={data?.ContractId ?? contractId}
          />
          <TextField
            hidden
            inputRef={register}
            name="UserId"
            defaultValue={data?.UserId ?? "Test User"}
          />
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register(formError.TokenName)}
                error={errors.TokenName && true}
                helperText={
                  errors.TokenName && errors.TokenName?.message
                }
                name="TokenName"
                label="Token Name"
                defaultValue={data?.TokenName ?? ""}
                value={tokenName}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title={"Name assigned to the token for UI use."}>
                      <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <MenuInputAdornment options={[
                      "Price",
                      "PingSessionId",
                      "PingUrlRedirect",
                    ]} onSelect={(value: any) => {
                        setValue("TokenName", value);
                        setTokenName(value);
                        setTokenTypeValue(value);
                    }} />)
                }}
                onChange={(e)=>{
                  setValue("Key", e.target.value);
                  setTokenName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register(formError.TokenPath)}
                error={errors.TokenPath && true}
                helperText={
                  errors.TokenPath && errors.TokenPath?.message
                }
                name="TokenPath"
                label="Token Path"
                defaultValue={data?.TokenPath ?? ""}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title={"The fully qualified path to the element we desire."}>
                      <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                id="token-type-input"
                options={tokenTypeList || []}
                value={selectedTokenType?.id ?? "String"}
                renderInput={params => {
                  params.InputProps.startAdornment = ( 
                    <InputAdornment position="start">
                      <Tooltip title={"Key into a table of Response Dispositions (see below)."}>
                      <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>);

                  return (
                    <TextField
                      {...params}
                      label="Token Type"
                      variant="outlined"
                      error={errors.TokenType && true}
                      helperText={errors.TokenType && errors.TokenType?.message}
                      name="TokenType"
                      inputRef={register(formError.TokenType)}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={data?.IsActive ?? false}
                    name="IsActive"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={data?.IsPing ?? true}
                    name="IsPing"
                    color="primary"
                  />
                }
                label={<>
                  {"Ping"}
                    <Tooltip title={"Boolean indicating if this is a PING or POST response"}>
                      <HelpOutlineIcon fontSize="small" />
                    </Tooltip>
                  </>}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={data?.IsHtml ?? false}
                    name="IsHtml"
                    color="primary"
                  />
                }
                label="HTML"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={data?.TokenPathIsCaseSensitive ?? false}
                    name="TokenPathIsCaseSensitive"
                    color="primary"
                  />
                }
                label="Token Path Is Case Sensitive"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
            </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
            </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};