import gql from "graphql-tag";

export const GET_BUYER_FILTERS = gql`
  query GetBuyerFilters($buyerId: ID) {
    LDPConfigQueryGroup {
      GetBuyerFilters(BuyerId: $buyerId) {
        BuyerFilterId
        BuyerFilterName
        BuyerId
        CreatedDate
        ExternalProcedureName
        FieldName
        IsActive
        IsDecimal
        IsList
        IsNumeric
        ModifiedDate
        Operation
        Delimiter
        ProcedureParameterName
        ProcedureDescription
        SubVerticalName
        UserId
        Value
        VerticalName
      }
    }
  }
`;

export const SAVE_BUYER_FILTERS = gql`
  mutation SaveBuyerFilter($buyerFilterInput: BuyerFilterInputType!) {
    LDPConfigMutationGroup {
      SaveBuyerFilter(BuyerFilterInput: $buyerFilterInput)
    }
  }
`;
