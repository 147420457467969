import gql from "graphql-tag";

export const GET_BUYER_SUBVERTICAL_CAPACITY = gql`
  query BuyerSubVerticalCapacity(
    $where: String
    $limit: Int
    $orderby: String
  ) {
    LDPConfigQueryGroup {
      BuyerSubVerticalCapacity(
        where: $where
        limit: $limit
        orderby: $orderby
      ) {
        BuyerId
        BuyerSubVerticalCapacityId
        CreatedDate
        DailyMaxCapacity
        IsActive
        ModifiedDate
        MonthlyMaxCapacity
        Notes
        UserId
        WeeklyMaxCapacity
        SubVerticalName
      }
    }
  }
`;

export const SAVE_BUYER_SUBVERTICAL_CAPACITY = gql`
  mutation MergeBuyerSubVerticalContractCapacity(
    $buyerSubVerticalContractCapacity: BuyerSubVerticalCapacityInputType!
  ) {
    LDPConfigMutationGroup {
      MergeBuyerSubVerticalContractCapacity(
        BuyerSubVerticalContractCapacity: $buyerSubVerticalContractCapacity
      )
    }
  }
`;

export const GET_BUYER_CAPACITY = gql`
  query BuyerCapacityQuery($where: String, $limit: Int, $orderby: String) {
    LDPConfigQueryGroup {
      BuyerCapacity(where: $where, limit: $limit, orderby: $orderby) {
        BuyerCapacityId
        BuyerId
        CreatedDate
        DailyMaxCapacity
        IsActive
        ModifiedDate
        MonthlyMaxCapacity
        Notes
        UserId
        WeeklyMaxCapacity
      }
    }
  }
`;

export const SAVE_BUYER_CAPACITY = gql`
  mutation SaveBuyerCapacity($dataInput: BuyerCapacityInputType!) {
    LDPConfigMutationGroup {
      SaveBuyerCapacity(BuyerCapacityDataInput: $dataInput)
    }
  }
`;
export const GET_BUYER_CAPACITY_HISTORY = gql`
  query GetBuyerCapacityHistory($recordId: ID) {
    LDPConfigQueryGroup {
      GetConfigDataChange(TableId: $recordId, TableName: "BuyerCapacity")
    }
  }
`;

export const GET_BUYER_MONETARY_CAPACITY = gql`
  query BuyerMonetaryCapacityConfig(
    $where: String
    $limit: Int
    $orderby: String
  ) {
    LDPConfigQueryGroup {
      BuyerMonetaryCapacityConfig(
        where: $where
        limit: $limit
        orderby: $orderby
      ) {
        BuyerId
        BuyerMonetaryCapacityConfigId
        CreatedDate
        IsActive
        MaxMonthlyBudget
        ModifiedDate
        Notes
        UserId
        MaxDailyBudget
        MaxWeeklyBudget
      }
    }
  }
`;

export const GET_BUYER_SUBVERTICAL_MONETARY_CAPACITY = gql`
  query BuyerSubVerticalMonetaryCapacityConfig(
    $where: String
    $limit: Int
    $orderby: String
  ) {
    LDPConfigQueryGroup {
      BuyerSubVerticalMonetaryCapacityConfig(
        where: $where
        limit: $limit
        orderby: $orderby
      ) {
        BuyerId
        BuyerSubVerticalMonetaryCapacityConfigId
        CreatedDate
        IsActive
        MaxMonthlySubVerticalBudget
        ModifiedDate
        Notes
        SubVerticalName
        UserId
        MaxDailySubVerticalBudget
        MaxWeeklySubVerticalBudget
      }
    }
  }
`;

export const MERGE_BUYER_MONETARY_CAPACITY = gql`
  mutation MergeBuyerMonetaryCapacityConfig(
    $mergeBuyerMonetaryCapacityConfigParams: MergeBuyerMonetaryCapacityConfigInputType!
  ) {
    LDPConfigMutationGroup {
      MergeBuyerMonetaryCapacityConfig(
        MergeBuyerMonetaryCapacityConfigParams: $mergeBuyerMonetaryCapacityConfigParams
      )
    }
  }
`;

export const MERGE_BUYER_SUBVERTICAL_MONETARY_CAPACITY_CONFIG = gql`
  mutation MergeBuyerSubVerticalMonetaryCapacityConfig(
    $mergeBuyerSubVerticalMonetaryCapacityConfigParams: MergeBuyerSubVerticalMonetaryCapacityConfigInputType!
  ) {
    LDPConfigMutationGroup {
      MergeBuyerSubVerticalMonetaryCapacityConfig(MergeBuyerSubVerticalMonetaryCapacityConfigParams: $mergeBuyerSubVerticalMonetaryCapacityConfigParams)
    }
  }
`;