import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import React, { useState } from "react";
import { useEffect } from "react";
import { Box, Divider, Grid, List, ListItem, Button, IconButton } from "@material-ui/core";

import { GET_CONTRACT_COMMENT, SAVE_CONTRACT_COMMENT } from "../../common/models/contractComment";
import { GetContractComment } from "../../common/models/types/GetContractComment";
import { ContractCommentInputType } from "../../types/graphql-global-types";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import { dateToPreferredTimezone } from "../../common/utils/date";
import CloseIcon from '@material-ui/icons/Close';

interface props {
    ContractId: any;
    onClose: Function;
}

export const ContractCommentList = ({ ContractId, onClose }: props) => {
  const googleUserMeta = GoogleUserMeta();
  const [getContractComment, {data, error, loading, refetch}] = useLazyQuery<GetContractComment>(GET_CONTRACT_COMMENT, { fetchPolicy: 'network-only'});

  const [saveContractComment] = useMutation(SAVE_CONTRACT_COMMENT);
  const { register, handleSubmit, control, getValues, watch, errors, setValue, reset } = useForm<ContractCommentInputType>({ 
    defaultValues: { Comment: "" }
  });
  const [disableButtons, setDisableButtons] = useState<Boolean>(false);  

  useEffect(() => {
    getContractComment({
        variables: {
          where: `ContractId = ${ContractId}`
        }
      });
  }, [ContractId]);

//   useEffect(() => {
//     console.log(data);
//   }, [data]);

  const onSubmit = ({ Comment }: ContractCommentInputType) => {         
    if (Comment === "") {
      return toast.error("Please input your comment");
    }

    setDisableButtons(true);

    saveContractComment({
        variables: {          
          contractCommentInput: {                               
            Comment,
            ContractId,                        
          },
        },
      }).then((response: any) => {
        toast.success(
          `Contract Comment added successfully.`
        );
                        
        setDisableButtons(false);
        refetch();        
        reset();
      }).catch(() => {
        setDisableButtons(false);
        toast.error("Unabled to save comment.");
      });
  }  
  
  return (
    <Box width="400px" height="100%" p={0} position="relative">
    <Box p={1} bgcolor="#E0F1EF">
        <div style={{ float: "right"}}>
        <IconButton onClick={() => onClose()}>
            <CloseIcon />                
        </IconButton>
            </div>
        
        <h3 style={{ marginLeft: "8px"}}>Comments</h3>            
    </Box>    
    <Divider />   
    <List style={{maxHeight: "75vh", overflow: "auto"}}>
        {data?.LDPConfigQueryGroup?.ContractComment?.map(
            (comments, index) => (
            <ListItem
                key={index}
                style={{
                borderBottom: '1px solid #ebebeb',
                paddingBottom: '.75rem',
                }}
            >            
                <div style={{ position: "relative", width: "100%"}}>
                    <fieldset style={{ width: "100%", paddingTop: "20px", borderRadius: "4px", backgroundColor: "#E0F1EF"}}>
                    <legend style={{ padding: "0 5px", fontWeight: "bold",  letterSpacing: "1px"}}>{comments?.UserId}</legend>                 
                        <div style={{ float: "right", clear: "right", marginTop: "-24px"}}>
                            {dateToPreferredTimezone(comments?.CreatedDate, "MMM dd yyyy hh:mma (z)")}                            
                        </div>
                        {comments?.Comment}                    
                    </fieldset>                
                </div>
            </ListItem>
            )
        )}
    </List>

    <Box position="absolute" bottom="0" width="100%">        
        <Divider />        
        <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={1}>        
            <Grid container>        
                <Grid item xs={12}>
                    <textarea  
                        name="Comment"
                        ref={register}                          
                        style={{ height: "100px", width: "100%" }} 
                        placeholder="Type your comment here"                               
                        onChange={(e) => setValue('Comment', e.target.value)}
                    ></textarea>
                    </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={disableButtons}
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        fullWidth>
                            Save
                        </Button>
                    </Grid>
            </Grid>        
        </Box>
        </form>
    </Box>
   </Box>);
}
