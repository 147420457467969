import React, { useEffect, useState } from "react";

import {
  makeStyles,
  Theme,
  createStyles,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import TabIcon from '@material-ui/icons/Tab';
import LaunchIcon from '@material-ui/icons/Launch';

import { connect } from "react-redux";
import { RootState } from "../../state";

import { FormStateProps, toggleForm } from "../../state/formReducer";

import { HistoryList } from "../history/HistoryList";
import { navigate } from "gatsby";

import { TabProps, TabsContainer } from "../../components/tabs";
import { updateTabContainers } from "../../state/tabsReducer";
import { isRoleExternalAccountManager } from "../../common/utils/roles";

interface HistoryModalProps {
  ConfigName: string;
  ConfigId: string;
  TabContainerId: string;  // TAB_CONTAINER
  data?: any;
  formId: string;
  formState?: FormStateProps; // contractForm
  close?: Function;
  isDocked?: boolean;
  isDocker?: boolean;
  historyTabs?: any;
  dispatch?: Function;
  loggedInUser?: RootState['loggedInUser'];
}

const HistoryModal = ({
  ConfigName,
  ConfigId,
  TabContainerId,
  data,
  formId,
  formState,
  close,
  isDocked,
  isDocker = true,
  historyTabs,
  dispatch,
  loggedInUser
}: HistoryModalProps) => {
  const isUserExternalAccountManager = loggedInUser && isRoleExternalAccountManager(loggedInUser);  

  const classes = useStyles();

  const [tabFocus, setTabFocus] = useState<number>(0);
  const [tabHistories, setTabHistories] = useState<TabProps[]>([]);

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    if (props.permalink) navigate(props.permalink);
    dispatch && dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );                                                                                                                                                            
  };

  useEffect(() => {
    if (! historyTabs.tabContainers[TabContainerId]) {
      let initialTabContainerFocus = 0;
      let initialTabs: TabProps[] = [
        {
          title: "History",
          tabId: "history-list",
          content: 
          isUserExternalAccountManager ? 
            <h2 style={{color: "red", margin: "5rem 0", textAlign: "center"}}>Sorry you do not have access for this view right now.</h2> :
            <HistoryList ConfigName={ConfigName} ConfigId={ConfigId} TabContainerId={TabContainerId} />,
        },
      ];

      const initialLeadsTabContainer = {
        [TabContainerId]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch && dispatch(updateTabContainers(initialLeadsTabContainer));
    } else {
      if (historyTabs.tabContainers[TabContainerId]) {
        setTabHistories(historyTabs.tabContainers[TabContainerId].tabs);
        setTabFocus(historyTabs.tabContainers[TabContainerId].tabFocus);
      }
    }
  }, [historyTabs]);

  return (
    <div className={classes.container}>
      {!isDocked && 
        <div style={{position: "absolute", top: "0px", right:"15px", marginTop: "-86px"}}>
          {isDocker && (<Tooltip title="Dock to tab">
            <IconButton aria-label="settings"
              onClick={() =>{
                dispatch && dispatch(toggleForm({formId: formId, formData: data}));
                }
              }
            >
              <TabIcon />
            </IconButton>
          </Tooltip>)}
          
          <Tooltip title="Close">
            <IconButton aria-label="settings"
              onClick={() => {
                close && close();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      {isDocked &&
        <div style={{textAlign: "right", marginRight: "10px"}}>
          <Tooltip title="Popup">
            <IconButton aria-label="settings"
              onClick={() => {
                dispatch && dispatch(toggleForm({formId: formId, formData: data}));
                }
              }
            >
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      <div style={{width: "100%", padding: 20}}>
        <TabsContainer
          tabs={tabHistories}
          onCloseTab={setTabHistories}
          tabFocus={tabFocus}
          onChange={handleTabChangeFocus}
          tabsContainerId={TabContainerId}
        />
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  historyTabs: state.tabsSection,
  loggedInUser: state.loggedInUser,
}), null)(HistoryModal);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    menuItemStyle: { maxHeight: "600px", overflow: "hidden auto" },
    container: {
      textAlign: "left",
      position: "relative",
      background: "#fff"
    },
    mainGrid: {
      padding: "20px",
    },
    helpIcon: {
      verticalAlign: "middle",
      cursor: "help"
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
