import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
} from "@material-ui/core";
import { queryLoad } from "../../components";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveIcon from "@material-ui/icons/Save";
import { GoogleUserMetaProp } from "../../common/utils/googleUserMeta";
import { BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity } from "../../common/models/types/BuyerSubVerticalCapacity";
import { GET_BUYER_SUBVERTICAL_CAPACITY, SAVE_BUYER_SUBVERTICAL_CAPACITY } from "../../common/models/buyerCapacity";
import { BuyerSubVerticalCapacity, BuyerSubVerticalCapacityVariables } from "../../common/models/types/BuyerSubVerticalCapacity";
import { GetSubVertical, GetSubVertical_LDPConfigQueryGroup_SubVertical } from "../../common/models/types/GetSubVertical";
import { GetVertical, GetVertical_LDPConfigQueryGroup_Vertical } from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";

interface SubVerticalOption {
  vertical: GetVertical_LDPConfigQueryGroup_Vertical;
  subvertical: GetSubVertical_LDPConfigQueryGroup_SubVertical;
  exists: boolean;
}

interface BuyerSubVerticalCapacityInput {
  BuyerId: any;
  BuyerSubVerticalCapacityId: any;
  DailyMaxCapacity: number | null;
  IsActive: boolean | null;
  MonthlyMaxCapacity: number | null;
  Notes: string | null;
  UserId: string | null;
  WeeklyMaxCapacity: number | null;
  SubVerticalName: string | null;
}

interface BuyerCapacityFormModalProps {
  buyerCapId?: number | null;
  buyerId?: number | string | null;
  action: string;
  close: Function;
  refetch?: () => void;
  dispatch: Function;
  googleUserMeta?: GoogleUserMetaProp;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

const formError = {
  SubVerticalName: {
    required: {
      value: true,
      message: "SubVertical Name is required.",
    },
  },
  DailyMaxCapacity: {
    min: {
      value: 0,
      message: "Minimum value is 0"
    },
    pattern:{
       value: /^(0|[1-9]\d*)(\.\d+)?$/,
       message: "Daily Max Capacity allows positive numbers only.",
    },
  },
  WeeklyMaxCapacity: {
    min: {
      value: 0,
      message: "Minimum value is 0"
    },
    pattern:{
       value: /^(0|[1-9]\d*)(\.\d+)?$/,
       message: "Weekly Max Capacity allows positive numbers only.",
    },
  },
  MonthlyMaxCapacity: {
    min: {
      value: 0,
      message: "Minimum value is 0"
    },
    pattern:{
       value: /^(0|[1-9]\d*)(\.\d+)?$/,
       message: "Monthly Max Capacity allows positive numbers only.",
    },
  },
  Notes: {
    maxLength: {
      value: 256,
      message: "Max characters is 256 only."
    }
  }
};

const WrappedBuyerSubVerticalCapacityFormModal = ({
  action,
  buyerCapId,
  buyerId,
  close,
  refetch,
  dispatch,
}: BuyerCapacityFormModalProps) => {
  const { register, handleSubmit, setValue, watch, errors } = useForm<BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity>();
  const classes = useStyles();
  const [getBuyerSubVerticalCapacities, { data, error, loading, called }] = useLazyQuery<BuyerSubVerticalCapacity, BuyerSubVerticalCapacityVariables>(GET_BUYER_SUBVERTICAL_CAPACITY, {
    fetchPolicy: "no-cache" 
  });
  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);

  const [
    getAllSubVertical,
    {
      data: subVerticalData,
      error: subVerticalError,
      loading: subVerticalLoading,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);
  const [ subVerticalInput, setSubVerticalInput ] = useState<SubVerticalOption>();
  const [ subVerticalOptions, setSubVerticalOptions ] = useState<SubVerticalOption[]>([]);
  const [ saveBuyerSubVerticalCapacity ] = useMutation(SAVE_BUYER_SUBVERTICAL_CAPACITY);

  const [ buyerCapDetails, setBuyerCapDetails ] = useState<BuyerSubVerticalCapacityInput>({
    BuyerId: buyerId,
    BuyerSubVerticalCapacityId: 0,
    DailyMaxCapacity: null,
    IsActive: true,
    MonthlyMaxCapacity: null,
    Notes: "",
    UserId: "",
    WeeklyMaxCapacity: null,
    SubVerticalName: "",
  });
  const [buyerCapLoading, setBuyerCapLoading ] = useState(true);
  const [touched, setTouched] = useState(false);
  const [notes, setNotes] = useState<string | null | undefined>("");

  useEffect(() => {
    if(action != "edit"){
      setBuyerCapLoading(false);
    }
  }, [action]);

  useEffect(() => {
    getBuyerSubVerticalCapacities({
      variables: {
        where: `BuyerId = ${buyerId}`
      }
    });
  }, []);

  useEffect(() => {
    getAllVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
  }, [data, error]);

  useEffect(() => {
    getAllSubVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
  }, [verticalData, verticalError]);

  useEffect(() => {
    if(data && verticalData && subVerticalData){
      const options: SubVerticalOption[] = [];
      for(const sv of (subVerticalData.LDPConfigQueryGroup?.SubVertical || [])){
        const v = (verticalData?.LDPConfigQueryGroup?.Vertical || []).find((vert) => vert?.VerticalId === sv?.VerticalId);
        if(v && sv){
          options.push({
            vertical: v,
            subvertical: sv,
            exists: !!data?.LDPConfigQueryGroup?.BuyerSubVerticalCapacity?.find((cap) => cap?.SubVerticalName === sv.SubVerticalName)
          })
        }
      }

      options.sort((sv1, sv2) => `${sv1.vertical.VerticalName} - ${sv1.subvertical.SubVerticalName}` > `${sv2.vertical.VerticalName} - ${sv2.subvertical.SubVerticalName}` ? 1 : -1);
      setSubVerticalOptions(options);

      const buyerCapSVCDataDetails = data?.LDPConfigQueryGroup?.BuyerSubVerticalCapacity?.find((details) => details?.BuyerSubVerticalCapacityId === buyerCapId);

      if(buyerCapSVCDataDetails){
        setBuyerCapDetails(buyerCapSVCDataDetails);
        setSubVerticalInput(options.find((sv) => sv.subvertical.SubVerticalName === buyerCapSVCDataDetails?.SubVerticalName));
        setNotes(buyerCapSVCDataDetails?.Notes);
        // setFormValues(buyerCapSVCDataDetails);
      }
      setBuyerCapLoading(false);
    }
  }, [data, verticalData, subVerticalData]);


  const setFormValues = (data: BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity) => {
    const arrayedDataValues = Object.values(data);
    Object.keys(data).map(function(key, index) {
      setValue(key, arrayedDataValues[index]);
    });
  }

  const handleTouch = () => {
    setTouched(!touched);
  };
  
  const onSubmit = (data: BuyerSubVerticalCapacity_LDPConfigQueryGroup_BuyerSubVerticalCapacity) => {
    let buyerSubVerticalContractCapacity = {
      ...data,
      SubVerticalName: subVerticalInput?.subvertical.SubVerticalName,
      MonthlyMaxCapacity: parseInt(data?.MonthlyMaxCapacity || ""),
      WeeklyMaxCapacity: parseInt(data?.WeeklyMaxCapacity || ""),
      DailyMaxCapacity: parseInt(data?.DailyMaxCapacity || ""),
    };

    saveBuyerSubVerticalCapacity({ variables: { buyerSubVerticalContractCapacity } }).then((response: any) => {
      if (buyerSubVerticalContractCapacity?.BuyerSubVerticalCapacityId > 0) toast.success("Buyer Sub Vertical Capacity updated successfully.");
      else {
        toast.success("Buyer Sub Vertical Capacity created successfully.");
      }
      refetch && refetch();
      close();
    });
  };


  return (
    <Paper>
      {queryLoad([buyerCapLoading], [error]) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                hidden
                inputRef={register}
                name="BuyerSubVerticalCapacityId"
                defaultValue={buyerCapDetails?.BuyerSubVerticalCapacityId ?? 0}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="BuyerId"
                defaultValue={Number(buyerCapDetails?.BuyerId) ?? buyerId}
              />
              {/* <TextField
                required
                hidden
                inputRef={register}
                name="UserId"
                defaultValue={buyerCapDetails?.UserId ?? "Test User"}
              /> */}
              <Autocomplete
                id="sub-vertical-input"
                options={subVerticalOptions || []}
                getOptionLabel={option => `${option.vertical?.VerticalName} - ${option.subvertical?.SubVerticalName}${option.exists ? " (Used)" : ""}`}
                getOptionDisabled={(option) => option.exists === true }
                defaultValue={subVerticalInput}
                onChange={(event: any, newValue: any) => {
                  setSubVerticalInput(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="SubVertical Name"
                    variant="outlined"
                    error={errors.SubVerticalName ? true : false}
                    helperText={errors.SubVerticalName && errors.SubVerticalName?.message}
                    inputRef={register(formError.SubVerticalName)}
                    name="SubVerticalName"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.DailyMaxCapacity)}
                error={errors.DailyMaxCapacity && true}
                helperText={errors.DailyMaxCapacity && errors.DailyMaxCapacity?.message}
                name="DailyMaxCapacity"
                label="Daily Max Lead Capacity"
                type="number"
                defaultValue={buyerCapDetails?.DailyMaxCapacity}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.WeeklyMaxCapacity)}
                error={errors.WeeklyMaxCapacity && true}
                helperText={errors.WeeklyMaxCapacity && errors.WeeklyMaxCapacity?.message}
                name="WeeklyMaxCapacity"
                label="Weekly Max Lead Capacity"
                type="number"
                defaultValue={buyerCapDetails?.WeeklyMaxCapacity}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.MonthlyMaxCapacity)}
                error={errors.MonthlyMaxCapacity && true}
                helperText={errors.MonthlyMaxCapacity && errors.MonthlyMaxCapacity?.message}
                name="MonthlyMaxCapacity"
                label="Monthly Max Lead Capacity"
                type="number"
                defaultValue={buyerCapDetails?.MonthlyMaxCapacity}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>              
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={buyerCapDetails?.IsActive ?? true}
                    name="IsActive"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.Notes)}
                error={errors.Notes && true}
                helperText={(touched || errors.Notes) && (<>
                <span style={{float:"left"}}>
                  {(errors.Notes && errors.Notes?.message)}
                </span>
                <span style={{float:"right"}}>
                  {`${notes?.length} / 256`}
                </span>
                </>)}
                name="Notes"
                label="Notes"
                defaultValue={buyerCapDetails?.Notes ?? ""}
                inputProps={{ maxLength: 256 }}
                onFocus={handleTouch}
                onBlur={handleTouch}
                onChange={(e) => setNotes(e.target.value)}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};

export const BuyerSubVerticalCapacityFormModal = connect()(WrappedBuyerSubVerticalCapacityFormModal);