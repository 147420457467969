import gql from "graphql-tag";

export const GET_CONTRACT_FILTERS = gql`
    query GetContractFilters($where: String){
        LDPConfigQueryGroup {
            ContractFilter(limit:9999, where: $where) {
                ContractFilterId
                ContractFilterName
                ExternalProcedureName
                ProcedureParameterName
                ProcedureDescription
                ContractId
                CreatedDate
                FieldName
                IsActive
                IsDecimal
                IsList
                IsNumeric
                IsPing
                ModifiedDate
                Operation
                UserId
                Value
                Delimiter
            }
        }
    }
`;  

export const SAVE_CONTRACT_FILTER = gql`
    mutation SaveContractFilter($contractFilterInput: ContractFilterInputType!){
        LDPConfigMutationGroup {
            SaveContractFilter(ContractFilterInput : $contractFilterInput)
        }
    }
`;

export const GET_CONTRACT_FILTER_OPERATION = gql`
    query GetContractFilterOperation($where: String) {
        LDPConfigQueryGroup {
            FilterOperation(limit:9999, where: $where) {
                FilterOperationId
                Operation
                UserId
                IsActive
                IsDecimalCapable
                IsListCapable
                IsNumericCapable
                IsTextCapable
                ModifiedDate
                CreatedDate
            }
        }
    }
`;  

export const GET_EXTERNAL_PROCEDURE = gql`
    query GetExternalProcedures($FieldName: String!, $IsContractFilter: Int) {
        LDPConfigQueryGroup {
            GetExternalProcedures(FieldName: $FieldName, IsContractFilter: $IsContractFilter) {
                FilterProcDispositionId
                ResponseDispositionName
                ProcedureName
                ProcedureParameterName
                FieldName
                Description
                IsContractFilter
                CreatedDate
                ModifiedDate
            }
        }
    }
`; 