import gql from "graphql-tag";

export const GET_CONTRACT_REQUEST_SCHEMA = gql`
    query GetContractRequestSchema($where: String){
        LDPConfigQueryGroup {
            ContractRequestSchema(limit:9999, where: $where) {
                AuthHeaderSchema
                AuthHeaderValue
                ContractId
                ContractRequestSchemaId
                ContractRequestSchemaName
                CreatedDate
                Encoding
                HttpVerb
                IsActive
                IsPing
                IsUrlEncodedRequest
                ModifiedDate
                PostUrl
                RequestSchema
                TimeoutSeconds
                UserId
            }
        }
    }
`;

export const SAVE_CONTRACT_REQUEST_SCHEMA = gql`
    mutation SaveContractRequestSchema($contractRequestSchema: ContractRequestSchemaInputType!){
        LDPConfigMutationGroup {
            SaveContractRequestSchema(ContractRequestSchemaData : $contractRequestSchema)
        }
    }
`;