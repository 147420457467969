import { useLazyQuery } from "@apollo/react-hooks";
import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Add, AddCircle, Edit as EditIcon, History as HistoryIcon } from '@material-ui/icons';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { GET_BUYER_CAPACITY } from "../../common/models/buyerCapacity";
import { BuyerCapacityQuery, BuyerCapacityQueryVariables } from "../../common/models/types/BuyerCapacityQuery";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { ContentLoading, LDPUIDataTable, StatusIcon, useModal } from "../../components";
import { activeFilter, createDateFilter, numberColumnFilterNullTo1000K, numberColumnFilterNullTo100K } from "../../components/customMuiDatatableFilter";
import { RootState } from "../../state";
import { dockForm, FormStateProps, openForm, resetForm } from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { BuyerCapacityFormModal } from "./buyerCapacityModal";
import { updateBuyerDetailForms } from "../../state/buyerSectionReducer";

interface BuyerCapacityProps {
  buyerId: string;
  dispatch?: Function;
  formsSection?: FormStateProps;
}

const BuyerCapacityTotalCountList = ({
  buyerId,
  dispatch,
  formsSection
}: BuyerCapacityProps) => {
  const [getBuyerCapacities, { data, error, loading, called, refetch }] = useLazyQuery<BuyerCapacityQuery, BuyerCapacityQueryVariables>(GET_BUYER_CAPACITY, { fetchPolicy: 'network-only' });

  const { Modal, closeModal, openModal, setContent } = useModal();

  useEffect(() => {
    getBuyerCapacities({
      variables: {
        where: `BuyerId = ${buyerId}`
      }
    });
  }, []);

  const mapRowDataToColumns = (muiColumns: any, rowData: any) => {
    if (rowData) {
      let newJson: any = {};
      muiColumns.map((column: any, key: number) => {
        newJson[column.name] = rowData[key];
      });
      return newJson;
    } else return null;
  };

  const columnsMUI = [
    {
      name: "BuyerCapacityId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip placement="top" title={
                <React.Fragment>
                  <Typography color="textPrimary">Edit Buyer Capacity</Typography>
                </React.Fragment>
              }>
                <IconButton onClick={() => {
                  openModal({
                    title: "Edit Buyer Capacity",
                    icon: <EditIcon />,
                    iconColor: "violet",
                    content: (
                      <BuyerCapacityFormModal
                        action="edit"
                        buyerId={buyerId}
                        data={mapRowDataToColumns(columnsMUI, tableMeta.rowData)}
                        close={closeModal}
                        refetch={() => {
                          getBuyerCapacities({
                            variables: {
                              where: `BuyerId = ${buyerId}`
                            }
                          });
                        }}
                      />
                    ),
                  });
                }}>
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip placement="top" title={
                <React.Fragment>
                  <Typography color="textPrimary">History</Typography>
                </React.Fragment>
              }>
                <IconButton onClick={() => {
                  const HISTORY_TAB_ID = `buyer-capacity-${value}-history`;

                  const formProps = {
                    formId: HISTORY_TAB_ID,
                    formTitle: `Buyer Capacity ID#${value} History`,
                    formIcon: <HistoryIcon />,
                    formComponent: <HistoryModal ConfigName="BuyerCapacity" ConfigId={value} TabContainerId={`history-buyer-capacity-${value}-list-tabs`} />,
                      formData: { ConfigName: "BuyerCapacity", ConfigId: value, ...tableMeta.tableData[tableMeta.rowIndex] },
                    formProps: {
                      closeTab: () => dispatch && dispatch(resetForm({ formId: HISTORY_TAB_ID })),
                    }
                  };

                  dispatch && dispatch(updateBuyerDetailForms({ buyerId: `buyer-${buyerId}`, formIds: [HISTORY_TAB_ID] }));

                  dispatch && dispatch(
                    formsSection?.formContainers[HISTORY_TAB_ID]
                      && formsSection?.formContainers[HISTORY_TAB_ID].isLastOpenedDocked ?
                      dockForm(formProps) : openForm(formProps)
                  );
                }}>
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>

          );
        },
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        filter: false,
        //...createDateFilter()
      }
    },
    {
      name: "MonthlyMaxCapacity",
      label: "Monthly Cap",
      options: {
        viewColumns: false,
        filter: true,
        ...numberColumnFilterNullTo1000K,
      },
    },
    {
      name: "WeeklyMaxCapacity",
      label: "Weekly Cap",
      options: {
        viewColumns: false,
        filter: true,
        ...numberColumnFilterNullTo100K,
      },
    },
    {
      name: "DailyMaxCapacity",
      label: "Daily Cap",
      options: {
        viewColumns: false,
        filter: true,
        ...numberColumnFilterNullTo100K,
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        viewColumns: true,
        ...activeFilter,
      },
    },
    {
      name: "Notes",
      label: "Notes",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "UserId",
      label: "User ID",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>&nbsp;</Grid>
      <Grid item xs={12}>
        <Box p={3}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: "Add Buyer Lead Capacity",
                    icon: <Add />,
                    iconColor: "green",
                    content: (
                      <BuyerCapacityFormModal
                        action="edit"
                        buyerId={buyerId}
                        data={mapRowDataToColumns(columnsMUI, {})}
                        close={closeModal}
                        refetch={() => {
                          getBuyerCapacities({
                            variables: {
                              where: `BuyerId = ${buyerId}`
                            }
                          });
                        }}
                      />
                    ),
                  });
                }}
              >
                Add Buyer Lead Capacity
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {!data && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId={`buyer-${buyerId}-caps-list`}
          restoreFilters={true}
          title={"Buyer Lead Capacity"}
          data={data?.LDPConfigQueryGroup?.BuyerCapacity ?? []}
          columns={columnsMUI}
          options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  formsSection: state.formsSection,
}), null)(BuyerCapacityTotalCountList);
