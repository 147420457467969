import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CopyIcon from "@material-ui/icons/FileCopyRounded";

import { CLONE_CONTRACT } from "../../common/models/contracts";
import { CloneContractInputType } from "../../types/graphql-global-types";
import { GET_CONTRACTS } from "../../common/models/contracts";
import { GetContracts } from "../../common/models/types/GetContracts";
import { queryLoad } from "../../components";


interface ContractFilterCloneModalProps {
  contractId: any;
  close: Function;
  refetch: Function;
}

const formError = {
  ContractId: {
    required: {
      value: true,
      message: "Destination Contract is required.",
    },
  }
};

export const ContractFilterCloneModal = ({
  contractId,
  close,
  refetch,
}: ContractFilterCloneModalProps) => {
  const [getAllContracts, { data, called, error, loading: loadingContracts, refetch: refetchContracts }] = useLazyQuery<GetContracts>(GET_CONTRACTS);

  const { register, handleSubmit, watch, setValue, errors, control } = useForm<CloneContractInputType>();
  const classes = useStyles();
  const pageTitle = "Clone Contract Filter";
  const [cloneContract] = useMutation(CLONE_CONTRACT);
  const [loading, setLoading] = useState(false);
  const onSubmit = (dataInput: CloneContractInputType) => {
    
    /* console.log("SUBMIT", { 
      ...dataInput,  
      NewContractName: "Contract Filter Clone Op - " + Date.now(),
      CloneContractCapacity: false,
      CloneContractFilter: true,
      CloneContractRequestHeader: false,
      CloneContractRequestSchema: false,
      CloneContractResponseParsing: false,
      CloneContractResponseToken: false,
      ContractIdToCopyTo: contractId
    }); */
    setLoading(true);
    cloneContract({ variables: { contractParams: { 
      ...dataInput,  
      NewContractName: "Contract Filter Clone Op - " + Date.now(),
      CloneContractCapacity: false,
      CloneContractFilter: true,
      CloneContractRequestHeader: false,
      CloneContractRequestSchema: false,
      CloneContractResponseParsing: false,
      CloneContractResponseToken: false,
      ContractIdToCopyTo: contractId
    } } }).then((response: any) => {
      toast.success("Contract filter cloned successfully.");
      refetch();
      close();
    }).catch(error => {
      toast.error(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllContracts();
  }, []);

  return (
    <Paper className={classes.container}>
      {queryLoad([!!loading], [error]) || (
          <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="ContractId"
                as={
                  <Select className={classes.formControl}
                    required
                    defaultValue=""
                    error={errors.ContractId && true}
                    variant="outlined">
                    <MenuItem value="">Clone Filters From Contract</MenuItem>
                    {data?.LDPConfigQueryGroup?.Contract?.map(contract => <MenuItem key={contract.ContractId} value={contract.ContractId}>{contract.ContractId} - {contract.ContractName}</MenuItem>)}
                  </Select>
                }>
              </Controller>
              <TextField
                required
                hidden
                inputRef={register}
                name="CloneContractCapacity"
                defaultValue={false}
                value={false}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="CloneContractFilter"
                defaultValue={true}
                value={true}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="CloneContractRequestHeader"
                defaultValue={false}
                value={false}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="CloneContractRequestSchema"
                defaultValue={false}
                value={false}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="CloneContractResponseParsing"
                defaultValue={false}
                value={false}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="CloneContractResponseToken"
                defaultValue={false}
                value={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={loading}
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<CopyIcon />}
              >
                Clone
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: "left",
      position: "relative",
      minHeight: "150px",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    formControl: {
      width: "100%",
      marginBottom: "20px"
    },
  })
);
