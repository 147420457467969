import gql from "graphql-tag";

export const GET_CONTRACT_MONETARY_CAPACITY = gql`
    query GetContractMonetaryCapacityConfig($where: String){
        LDPConfigQueryGroup {
            GetContractMonetaryCapacityConfig(limit:9999, where: $where) {
                ContractId
                ContractMonetaryCapacityConfigId
                CreatedDate
                IsActive
                MaxDailyBudget
                MaxMonthlyBudget
                MaxWeeklyBudget
                ModifiedDate
                Notes
                UserId
            }
        }
    }
`;  

export const SAVE_CONTRACT_MONETARY_CAPACITY = gql`
    mutation SaveContractMonetaryCapacity($mergeContractMonetaryCapacityConfigParams: MergeContractMonetaryCapacityConfigInputType!){
        LDPConfigMutationGroup {
            MergeContractMonetaryCapacityConfig(MergeContractMonetaryCapacityConfigParams : $mergeContractMonetaryCapacityConfigParams)
        }
    }
`; 