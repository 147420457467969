import React, { useEffect, useState, BaseSyntheticEvent } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  StatusIcon,
  useConfirmation,
  useModal,
} from "../../components";
import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SyncIcon from "@material-ui/icons/Sync";
import { Check, Clear } from "@material-ui/icons";
import JSONPretty from "react-json-pretty";
import { GET_CONTRACTS, GET_INCOMPLETE_CONTRACT } from "../../common/models/contracts";
import { PING_TEST_CONTRACT } from "../../common/models/ping";
import {
  GetContracts,
  GetContracts_LDPConfigQueryGroup_Contract,
} from "../../common/models/types/GetContracts";
import {
  GetIncompleteContract,
  GetIncompleteContract_LDPConfigQueryGroup_GetIncompleteContract,
} from "../../common/models/types/GetIncompleteContract";
import {
  PingTest,
  PingTestVariables,
} from "../../common/models/types/PingTest";
import ContractPingTestFormModal from "./contractPingTestModal";
import styled from "styled-components";
import { toast } from "react-toastify";
import MuiAlert from '@material-ui/lab/Alert';
import { SET_CONTRACT_IS_ACTIVE } from "../../common/models/contracts";
import { connect } from "react-redux";
import { signalContractRefreshCarriedOut, updateContractDetailForms, updateContractMainForms } from "../../state/contractSectionReducer";
import { RootState } from "../../state";
import { dockForm, FormStateProps, openForm, resetForm } from "../../state/formReducer";
import { ContractFormModal } from "./contractModal";
import HistoryIcon from '@material-ui/icons/History';
import HistoryModal from "../history/HistoryModal";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { GoogleUserMeta } from "../../common/utils/googleUserMeta";
import { isRoleExternalAccountManager } from "../../common/utils/roles";
import { ContractCommentList } from "./contractComment";
import CommentIcon from '@material-ui/icons/Comment';

interface ContractDetailsProps {
  contractId: string;
  dispatch: Function;
  contractRecordSaved: boolean;
  contractForms: FormStateProps;
  loggedInUser?: RootState['loggedInUser'];
}

interface AlertsProps extends GetIncompleteContract_LDPConfigQueryGroup_GetIncompleteContract {
  alertId: string;
  open: boolean;
}

const ContractDetails = ({ contractId, dispatch, contractRecordSaved, contractForms, loggedInUser }: ContractDetailsProps) => {
  const isUserExternalAccountManager = isRoleExternalAccountManager(loggedInUser);  
  const { Modal, closeModal, openModal, setContent } = useModal();
  const { Modal: Confirmation, closeModal: closeConfirmation, useModal: setConfirmation } = useConfirmation();
  const [getContract, { data, error, loading, called, refetch }] = useLazyQuery<
    GetContracts
  >(GET_CONTRACTS);
  const [getIncompleteContracts, { data: incompleteContractData, error: incompleteContractError, loading: incompleteContractLoading }] = useLazyQuery<
    GetIncompleteContract
  >(GET_INCOMPLETE_CONTRACT);
  const [
    pingTest,
    { data: pingData, error: pingError, loading: pingLoading },
  ] = useLazyQuery<PingTest, PingTestVariables>(PING_TEST_CONTRACT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [setContractIsActive] = useMutation(SET_CONTRACT_IS_ACTIVE);
  const googleUserMeta = GoogleUserMeta();

  const EDIT_FORM_ID = `contract-edit`;
  const PING_TEST_FORM_ID = `contract-${contractId}-ping-test`;

  const [commentDrawer, setCommentDrawer] = useState<boolean>(false);    

  const [
    details,
    setDetails,
  ] = useState<GetContracts_LDPConfigQueryGroup_Contract | null>();

  const [alerts, setAlerts] = useState<AlertsProps[]>();
  const handleAlertsClose = (id: String) => {
    setAlerts(alerts?.map((alert) => {
      if (alert.alertId == id) {
        return { ...alert, open: false }
      } else return alert;
    }))
  };

  useEffect(() => {
    getContract({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, []);

  useEffect(() => {
    if (data || error) {
      getIncompleteContracts({
        variables: { contractId: contractId },
      });
    }
  }, [data]);

  useEffect(() => {
    if (data && contractRecordSaved) {
      toast.info("Refreshing Contract");
      refetch().finally(() => {
        toast.dismiss();
        dispatch(signalContractRefreshCarriedOut());
      });
    }
  }, [data, contractRecordSaved]);

  useEffect(() => {
    if (pingError) {
      toast.error("Ping Test Request Failed.");
    } else {
      if (pingData) {
        if (
          pingData?.LDPConfigQueryGroup?.PingTest?.errorMessage ||
          pingData?.LDPConfigQueryGroup?.PingTest?.pingResponse?.errorMessage
        ) {
          toast.error(
            <JSONPretty
              style={Style.CodeWrapper}
              data={pingData?.LDPConfigQueryGroup?.PingTest}
            ></JSONPretty>,
            {
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
        } else {
          toast.info(
            <JSONPretty
              style={Style.CodeWrapper}
              data={pingData?.LDPConfigQueryGroup?.PingTest?.pingResponse}
            ></JSONPretty>,
            {
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            }
          );
        }
      }
    }
  }, [pingData, pingLoading]);

  useEffect(() => {
    if (data) {
      setDetails(
        data?.LDPConfigQueryGroup?.Contract
          ? data?.LDPConfigQueryGroup?.Contract[0]
          : null
      );
    }

    if (incompleteContractData) {
      if (incompleteContractData.LDPConfigQueryGroup?.GetIncompleteContract) {
        let mapped: any = incompleteContractData.LDPConfigQueryGroup?.GetIncompleteContract.map((alert, index) => {
          return {
            ...alert,
            open: true,
            alertId: index,
          }
        });
        setAlerts(mapped);
      }
    }
  }, [data, incompleteContractData]);

  return (
    <Grid container spacing={3} key="grid-container-0">
      {alerts && alerts.map((alert: AlertsProps) =>
        alert.open && <Grid item xs={12} key="grid-item-0">
          <MuiAlert
            style={{ margin: '20px' }}
            elevation={6}
            variant="filled"
            onClose={() => handleAlertsClose(alert.alertId)}
            severity="warning">
            {alert.IncompleteMsg}
          </MuiAlert >
        </Grid>
      )
      }
      <Grid item xs={12} md={6} key="grid-item-1">
        <Style.Info>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Contract Id: </span>
            <span>{details?.ContractId}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Contract Name: </span>
            <span>{details?.ContractName}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Buyer Group: </span>
            <span>
              {details?.BuyerGroup?.BuyerGroupName}
            </span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>BuyerId: </span>
            <span>{details?.BuyerId}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Buyer Name: </span>
            <span>{details?.Buyer?.BuyerName}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Credit Balance: </span>
            <span>{details?.CreditBalance}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Default Price: </span>
            <span>{details?.DefaultPrice}</span>
          </Style.Item>
          {/* <Style.Item>
            <span style={{ fontWeight: "bold" }}>
              MaxNonExclusiveSaleCount:{" "}
            </span>
            <span>{details?.MaxNonExclusiveSaleCount}</span>
          </Style.Item> */}
          <Style.Item>
            <span style={{ fontWeight: "bold" }} title="Effective Price = (Price Offered * Price Inflator%) / 100 + Price Offered">
              Price Inflator %:{" "}
            </span>
            <span title="Effective Price = (Price Offered * Price Inflator%) / 100 + Price Offered">{details?.PriceAdjustmentPercentage}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Modified Date: </span>
            <span>{details?.CreatedDate && dateToPreferredTimezone(details?.ModifiedDate)}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Modified By: </span>
            <span>{details?.UserId}</span>
          </Style.Item>
        </Style.Info>
      </Grid>
      <Grid item xs={12} md={6} key="grid-item-2">
        <Style.Info>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Is Active: </span>
            <span>
              <StatusIcon value={details?.IsActive} />
            </span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Is Post Only: </span>
            <span>
              <StatusIcon value={details?.IsPostOnly} />
            </span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Exclusive Sale: </span>
            <span>
              <StatusIcon value={details?.ExclusiveSale} />
            </span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Undersold: </span>
            <span>
              <StatusIcon value={details?.CanSellAsUndersold === false ? false : true} />
            </span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Catch-All: </span>
            <span>
              <StatusIcon value={details?.IsCatchall} />
            </span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Position: </span>
            <span>{details?.Position}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Vertical: </span>
            <span>{details?.Vertical?.VerticalName}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Sub Vertical: </span>
            <span>{details?.SubVertical?.SubVerticalName}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>ExternalContractId: </span>
            <span>{details?.ExternalContractId}</span>
          </Style.Item>
          <Style.Item>
            <span style={{ fontWeight: "bold" }}>Created Date: </span>
            <span>{details?.CreatedDate && dateToPreferredTimezone(details?.CreatedDate)}</span>
          </Style.Item>

          <Box p={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={4}>
              <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={<CommentIcon />}
                  onClick={() => setCommentDrawer(true)}
                >
                  Add Comment
                </Button>                
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={<HistoryIcon />}
                  onClick={() => {

                    const HISTORY_TAB_ID = `contract-${contractId}-history`;

                    const formProps = {
                      formId: HISTORY_TAB_ID,
                      formTitle: `Contract ${contractId} History`,
                      formIcon: <HistoryIcon />,
                      formComponent: <HistoryModal ConfigName="Contract" ConfigId={contractId} TabContainerId={HISTORY_TAB_ID} />,
                      formData: {},
                      formProps: {
                        closeTab: () => dispatch(resetForm({ formId: HISTORY_TAB_ID })),
                      }
                    };

                    dispatch(updateContractDetailForms({ contractId: `contract-${contractId}`, formIds: [HISTORY_TAB_ID] }));

                    dispatch(
                      contractForms.formContainers[HISTORY_TAB_ID]
                        && contractForms.formContainers[HISTORY_TAB_ID].isLastOpenedDocked ?
                        dockForm(formProps) : openForm(formProps)
                    );
                  }}
                >
                  Config History
                </Button>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={<EditIcon />}
                  onClick={() => {
                    const formProps =
                    {
                      formId: EDIT_FORM_ID,
                      formTitle: `Edit Contract #${contractId}`,
                      formComponent: <ContractFormModal />,
                      formData: details,
                      formProps: {
                        closeTab: () => dispatch(resetForm({ formId: EDIT_FORM_ID })),
                      }
                    };
                    dispatch(
                      contractForms.formContainers[EDIT_FORM_ID]
                        && contractForms.formContainers[EDIT_FORM_ID].isLastOpenedDocked ?
                        dockForm(formProps) : openForm(formProps)
                    );
                    // dispatch(openForm(formProps)); // this will open form in modals instead of dockForm
                  }}
                >
                  Edit Contract
                </Button>
              </Grid>
              <Grid item xs={12} lg={4}>&nbsp;</Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  variant="contained"                  
                  size="large"
                  fullWidth
                  color={details?.IsActive ? "secondary" : "primary"}
                  startIcon={details?.IsActive ? <Clear /> : <Check />}
                  style={{color: "#fff"}}                  
                  onClick={() => {
                    setConfirmation(
                      () => {
                        setContractIsActive({ variables: { contractId: details?.ContractId, isActive: !details?.IsActive, userId: googleUserMeta?.email } }).then((response: any) => {
                          if (response.data.LDPConfigMutationGroup.SetContractIsActive) {
                            toast.success(`Contract ${details?.IsActive ? "Deactivated" : "Activated"} successfully.`);
                          } else {
                            toast.error(`Contract cannot be activated.`);
                          }
                          refetch();
                        })
                      },
                      {
                        title: `Are you sure you want to ${details?.IsActive ? "de" : ""
                          }activate ${details?.ContractName}?`,
                        description: "",
                      }
                    );
                  }}
                >
                  {details?.IsActive ? "Deactivate" : "Activate"} Contract
                </Button>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={<SyncIcon />}
                  onClick={() => {
                    if(isUserExternalAccountManager) {
                      return toast.error("Sorry you do not have access for this action right now!");
                    }


                    localStorage.setItem('PingTestModalScrollPosition', `0`);
                    const formProps =
                    {
                      formId: PING_TEST_FORM_ID,
                      formTitle: `Ping Test`,
                      formComponent: <ContractPingTestFormModal formId={PING_TEST_FORM_ID} />,
                      formData: {
                        ContractId: details?.ContractId,
                        VerticalName: details?.Vertical?.VerticalName,
                        SubVerticalName: details?.SubVertical?.SubVerticalName,
                        VerticalId: details?.Vertical?.VerticalId,
                        SubVerticalId: details?.SubVertical?.SubVerticalId,
                      },
                      formProps: {
                        closeTab: () => dispatch(resetForm({ formId: PING_TEST_FORM_ID })),
                      }
                    };

                    dispatch(
                      contractForms.formContainers[PING_TEST_FORM_ID]
                        && contractForms.formContainers[PING_TEST_FORM_ID].isLastOpenedDocked ?
                        dockForm(formProps) : openForm(formProps)
                    );
                  }}
                >
                  Ping Test
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Style.Info>
      </Grid>
      <Modal />
      <Confirmation />
      <Drawer
        anchor="right"
        open={commentDrawer}
        onClose={()=>setCommentDrawer(false)}
      >
        <ContractCommentList ContractId={contractId} onClose={()=>setCommentDrawer(false)} />
    </Drawer>
    </Grid>
  );
};

export default connect((state: RootState) => ({
  contractRecordSaved: state.contractSection.contractRecordSaved,
  contractForms: state.formsSection,
  loggedInUser: state.loggedInUser,
}), null)(ContractDetails);

const Style: any = {
  CodeWrapper: {
    height: "90vh",
    width: "285px",
    overflowY: "auto",
    overflowX: "auto",
  },
  Paper: {
    padding: "0px",
    width: "100%",
  },
  Details: styled.span`
    margin-left: 10px;
  `,
  Info: styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 5px 0px;
    overflow: hidden;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 10px;
    span {
      width: 50%;
      text-align: left;
      :first-child {
        text-align: right;
        margin-right: 20px;
      }
    }
  `,
};
