import React, { cloneElement, useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import ContractCapacity from "./contractCapacity";
import ContractFilters from "./contractFilters";
import ContractRequest from "./contractRequest";
import ContractResponse from "./contractResponse";
import ContractDetails from "./contractDetails";

import { connect } from "react-redux";
import { TabProps, tabExist, TabsContainer, TabsContainerProps } from "../../components/tabs";
import { updateTabContainers } from "../../state/tabsReducer";
import { RootState } from "../../state";
import { useModal } from "../../components";
import { AddCircle } from "@material-ui/icons";
import { closedForm, FormDataProps, FormStateProps, resetForm, triggeredForm } from "../../state/formReducer";
import { updateContractDetailForms } from "../../state/contractSectionReducer";
import ContractZipCodeFilter from "./contractZipCodeFilter";
import ContractMonetaryCapacity from "./contractMonetaryCapacity";

interface ContractProps {
  contractId: string;
  contractTabs: any;
  dispatch: Function;
  contractForms: FormStateProps;
  contractDetail: any;
}

const ViewContracts = (props: ContractProps) => {

  const { contractId, contractTabs, dispatch, contractForms, contractDetail } = props;

  const TAB_CONTAINER = `contract-${contractId}`;
  const [tabFocus, setTabFocus] = useState<number>(0);
  const [_contractTabs, setContractTabs] = useState<TabsContainerProps["tabs"]>();
  const [_formIds, setFormIds] = useState<string[]>();
  const [formsInitialized, setFormsInitialized] = useState(false);
  const [tabsInitialized, setTabsInitialized] = useState(false);
  const { Modal, closeModal, openModal } = useModal();

  useEffect(() => {
    if (!contractTabs.tabContainers[TAB_CONTAINER]) {
      const initialContractsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: 0,
          tabs: [
            {
              title: `Details`,
              tabId: `contract-${contractId}-details`,
              content: <ContractDetails contractId={contractId} />,
              permalink: `/contracts/${contractId}`,
            },
            {
              title: `Lead Count Capacity`,
              tabId: `contract-${contractId}-capacity`,
              content: <ContractCapacity contractId={contractId} />,
              permalink: `/contracts/${contractId}`,
            },
            {
              title: `Monetary Capacity`,
              tabId: `contract-${contractId}-monetary-capacity`,
              content: <ContractMonetaryCapacity contractId={contractId} />,
              permalink: `/contracts/${contractId}`,
            },
            {
              title: `Filters`,
              tabId: `contract-${contractId}-filters`,
              content: <ContractFilters contractId={contractId} />,
              permalink: `/contracts/${contractId}`,
            },
            {
              title: `Request Setup`,
              tabId: `contract-${contractId}-contract-request`,
              content: <ContractRequest contractId={contractId} setTabFocus={setTabFocus} />,
              permalink: `/contracts/${contractId}`,
            },
            {
              title: `Response Setup`,
              tabId: `contract-${contractId}-contract-response`,
              content: <ContractResponse contractId={contractId} />,
              permalink: `/contracts/${contractId}`,
            },
            {
              title: `Zip Code Filter`,
              tabId: `contract-${contractId}-zip-filter`,
              content: <ContractZipCodeFilter contractId={Number(contractId)} />,
              permalink: `/contracts/${contractId}`,
            },
          ],
        }
      };
      dispatch(updateTabContainers(initialContractsTabContainer));
    } else {
      setContractTabs(contractTabs.tabContainers[TAB_CONTAINER].tabs);
      setTabFocus(contractTabs.tabContainers[TAB_CONTAINER].tabFocus);
      setTabsInitialized(true);
    }

  }, [contractTabs]);

  useEffect(() => {
    if (!contractDetail[TAB_CONTAINER]) {
      dispatch(
        updateContractDetailForms({
          contractId: `contract-${contractId}`,
          formIds: [
            `contract-${contractId}-request-body-edit`,
            `contract-${contractId}-request-body-add`,
            `contract-${contractId}-ping-test`,
          ]
        })
      );
    } else {
      setFormIds(contractDetail[TAB_CONTAINER].formIds);
      setFormsInitialized(true);
    }
  }, [contractDetail]);

  const handleDocking = (formData: FormDataProps) => {
    if (formData) {
      const _tabExist = tabExist(_contractTabs, formData.formId);
      let _tabs: TabProps[] = (_tabExist > 0) ? _contractTabs?.filter((tab, index) => (index !== _tabExist)) : _contractTabs;

      if (_tabExist > 0 && formData.formId.includes('request-body-history')) {
        setTabFocus(_tabExist);
      } else {
        if (formData.isDocked) {
          closeModal();
          dispatch(updateTabContainers(
            {
              [TAB_CONTAINER]: {
                tabFocus: _tabs?.length,
                tabs: [
                  ..._tabs,
                  {
                    title: formData.formTitle,
                    tabId: formData.formId,
                    content: (typeof formData.formComponent !== 'undefined' &&
                      cloneElement(
                        formData.formComponent,
                        {
                          ...formData,
                          data: formData.formData,
                          close: formData.formProps.closeTab,
                          isDocked: formData.isDocked,
                        }
                      )
                    ),
                    closeTab: (tabs: TabProps[]) => {
                      handleCloseTab(tabs);
                    },
                  },
                ],
              }
            }
          ));
        } else if (formData.isModal) {
          dispatch(updateTabContainers(
            {
              [TAB_CONTAINER]: {
                tabFocus: formData?.formProps?.tabFocus ? formData?.formProps?.tabFocus : (tabFocus < _tabs.length) ? tabFocus : (_tabs.length - 1),
                tabs: _tabs,
              }
            }
          ));
          openModal({
            title: formData.formTitle,
            icon: formData.formIcon ?? <AddCircle />,
            iconColor: "orange",
            content: (typeof formData.formComponent !== 'undefined' &&
              cloneElement(
                formData.formComponent,
                {
                  ...formData,
                  data: formData.formData,
                  close: closeModal,
                  isDocked: formData.isDocked,
                }
              )
            ),
            customHeaderButtons: <div />,
          });
        }
      }
    }

    //defrag the form trigger
    dispatch(triggeredForm({ formId: formData.formId }));
  };

  const handleCloseTab = (tabs: TabProps[]) => {
    dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: (tabs.length - 1),
          tabs: tabs,
        }
      })
    );
  };

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );
  };

  useEffect(() => {
    if (tabsInitialized && formsInitialized && _formIds && contractForms) {
      _formIds.forEach(formId => {
        if (contractForms.formContainers[formId] && contractForms.formContainers[formId].isTriggered) {
          handleDocking(contractForms.formContainers[formId]);
        } else if (contractForms.formContainers[formId] && contractForms.formContainers[formId].isClosed) {
          const _tabExist = tabExist(_contractTabs, formId);
          let _tabs: TabProps[] = (_tabExist > 0) ? _contractTabs?.filter((tab, index) => (index !== _tabExist)) : _contractTabs;

          handleCloseTab(_tabs);
          dispatch(closedForm({ formId: formId }));
        }
      });
    }
  }, [contractForms, tabsInitialized, formsInitialized, _formIds]);

  return (
    <Paper style={Style.Paper}>
      <TabsContainer
        tabs={_contractTabs}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        onCloseTab={handleCloseTab}
        tabsContainerId={TAB_CONTAINER}
      />
      <Modal />
    </Paper>
  );
};

// export default ViewContracts;

export default connect((state: RootState) => ({
  contractTabs: state.tabsSection,
  contractForms: state.formsSection,
  contractDetail: state.contractSection.contractDetail,
}), null)(ViewContracts);

const Style = {
  Paper: {
    padding: "0px",
    width: "100%",
  },
};
