import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { queryLoad, useConfirmation } from "../../components";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Divider,
  TextField,
} from "@material-ui/core";
import {
  GET_USER_ROLES,
  SAVE_USER_CONFIG,
  EDIT_USER_CONFIG,
  ASSIGN_USER_ROLE,
  RM_USER_CONFIG,
} from "../../common/models/users";
import { GetUsers_LDPUserConfigQueryGroup_UserConfig } from "../../common/models/types/GetUsers";
import { GetUserRoles } from "../../common/models/types/GetUserRoles";
import {
  AddUserConfigInput,
} from "../../types/graphql-global-types";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import DeleteIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface UserFormModalProps {
  data: GetUsers_LDPUserConfigQueryGroup_UserConfig | null;
  action: string;
  close: Function;
  refetch: Function;
}

const formError = {
  FirstName: {
    required: {
      value: true,
      message: "First Name is required.",
    },
  },
  LastName: {
    required: {
      value: true,
      message: "Last Name is required.",
    },
  },
  Email: {
    required: {
      value: true,
      message: "Email is required.",
    },
    pattern: {
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "Entered value does not match email format"
    }
  },
  Phone: {
    required: {
      value: true,
      message: "Phone is required.",
    },
    minLength: {
      value: 9,
      message: "Invalid phone number"
    },
    // pattern: {
    //   value: /^\d{10}$/,
    //   message: "Please enter a 10-digit phone number"
    // }    
  },
  Title: {
    required: {
      value: true,
      message: "Title is required.",
    },
  },
  Roles: {
    required: {
      value: true,
      message: "User Roles is required.",
    },
  },
};

export const UserListModal = ({
  action,
  data,
  close,
  refetch,
}: UserFormModalProps) => {
  const { register, handleSubmit, control, errors, getValues } = useForm();
  const classes = useStyles();
  const pageTitle = action == "create" ? "Add New User" : "Edit User";

  const confirm = useConfirmation();

  const [loading, setLoading] = useState(true);
  const [userRoles, setUserRoles] = useState<any>([]);
  const [mappedUserRoles, setMappedUserRoles] = useState<any>([]);
  const [userConfigData, setUserConfigData] = useState<GetUsers_LDPUserConfigQueryGroup_UserConfig>({
    __typename: "UserConfigCustom",
    BuyerToken: "",
    CreatedDate: null,
    Email: "",
    FirstName: "",
    LastName: "",
    ModifiedDate: null,
    Phone: "",
    Title: "",
    UserConfigBuyer: null,
    UserConfigId: null,
    UserConfigRole: null,
    UserId: ""
  });

  const [
    getAllUserRoles,
    { data: userRolesData, error: userRolesError, loading: userRolesLoading },
  ] = useLazyQuery<GetUserRoles>(GET_USER_ROLES);

  useEffect(() => {
    if (data) {
      setUserConfigData({ ...data });
      // console.log(data)
    }
  }, [data]);

  useEffect(() => {
    if (userRolesData) {
      setMappedUserRoles(
        userRolesData?.LDPUserConfigQueryGroup?.Role?.map(role => {
          return { id: role?.RoleId, name: role?.Name }
        })
      );
    } else if (!userRolesData) {
      getAllUserRoles();
    }
  }, [userRolesData]);

  useEffect(() => {
    if (data?.UserConfigRole) {
      setUserRoles(
        data?.UserConfigRole?.map(rolesList => {
          return { id: rolesList?.Role?.RoleId, name: rolesList?.Role?.Name }
        })
      );
    }
    setLoading(false);
  }, [userRolesData]);

  const handleUserRoles = (value) => {
    let selectedRoles = value;
    if (
      selectedRoles.some(role => role.name === 'Administrator') &&
      !selectedRoles.some(role => role.name === 'User')
    ) {
      selectedRoles.push({ "id": 69, "name": "User" });
    }
    setUserRoles(selectedRoles);
  };

  const [delUserConfig] = useMutation(RM_USER_CONFIG);
  const [saveUserConfig] = useMutation(SAVE_USER_CONFIG);
  const [editUserConfig] = useMutation(EDIT_USER_CONFIG);
  const [assignUserRoles] = useMutation(ASSIGN_USER_ROLE);
  const onSubmit = async (userConfigFormData: AddUserConfigInput & { UserConfigId?: string }) => {
    setLoading(true);
    delete userConfigFormData.Roles;
    userConfigFormData.FirstName = userConfigFormData.FirstName?.trim();
    userConfigFormData.LastName = userConfigFormData.LastName?.trim();
    setUserConfigData({ ...userConfigData, ...userConfigFormData });
    let userConfigId = parseInt(userConfigFormData?.UserConfigId + "");
    if (userConfigFormData?.UserConfigId) {
      delete userConfigFormData?.UserConfigId;
      await editUserConfig({
        variables: { userConfigData: userConfigFormData, userConfigId },
      }).then((response: any) => {
        // if (response.data.LDPUserConfigMutationGroup.EditUserConfig) {
        saveUserRole(userConfigId);
        toast.success("User updated successfully.");
        // } else {
        //   toast.error("User didnt save. API returned null");
        // }
      });
    } else {
      await saveUserConfig({ variables: { userConfigData: userConfigFormData } })
        .then((response: any) => {
          userConfigId = response.data.LDPUserConfigMutationGroup.AddUserConfig;
          if (userConfigId) {
            toast.success("User created successfully.");
            saveUserRole(userConfigId);
          } else {
            toast.error(response.data.LDPUserConfigMutationGroup);
          }
        })
        .catch(e => {
          toast.error("Email already exists!");
          setLoading(false);
        });
    }
  };

  const saveUserRole = (userConfigId: number) => {
    let userRoleId: any = [];
    userRolesData?.LDPUserConfigQueryGroup?.Role?.map(roles => {
      if (userRoles.filter((role: any) => role?.name === roles?.Name).length > 0) {
        userRoleId.push(roles?.RoleId);
      }
    });
    const userRoleData = {
      UserConfigId: userConfigId,
      RoleIds: userRoleId,
    };
    // console.log(userRoleData);

    if (userRoleId.length > 0) {
      assignUserRoles({
        variables: { userRoleData },
      })
        .then((response: any) => {
          toast.success("User Roles saved successfully.");
          refetch();
          close();
        })
        .catch(e => {
          toast.error("AssignRoles error!");
        });

      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (errors) {
  //     console.log(getValues())
  //   }
  // }, [errors]);

  // console.log(mappedUserRoles, userRoles)

  return (
    <Paper className={classes.container}>
      {queryLoad([!!userRolesLoading, !!loading], [userRolesError]) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12} sm={6}>
              {action != "create" && (
                <TextField
                  required
                  hidden
                  inputRef={register}
                  name="UserConfigId"
                  defaultValue={userConfigData?.UserConfigId ?? 0}
                />
              )}
              <TextField
                inputRef={register(formError.FirstName)}
                error={errors.FirstName && true}
                helperText={errors.FirstName && errors.FirstName?.message}
                name="FirstName"
                label="First Name"
                defaultValue={userConfigData?.FirstName ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register(formError.LastName)}
                error={errors.LastName && true}
                helperText={errors.LastName && errors.LastName?.message}
                name="LastName"
                label="Last Name"
                defaultValue={userConfigData?.LastName ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                inputRef={register(formError.Email)}
                error={errors.Email && true}
                helperText={errors.Email && errors.Email?.message}
                name="Email"
                label="Email"
                defaultValue={userConfigData?.Email ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register(formError.Title)}
                error={errors.Title && true}
                helperText={errors.Title && errors.Title?.message}
                name="Title"
                label="Title"
                defaultValue={userConfigData?.Title ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register(formError.Phone)}
                error={errors.Phone && true}
                helperText={errors.Phone && errors.Phone?.message}
                name="Phone"
                label="Phone"
                defaultValue={userConfigData?.Phone ?? ""}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                render={({ onChange, ...props }) => (
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    id="user-roles"
                    options={mappedUserRoles || []}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    // value={userRoles}
                    onChange={(e, data) => { handleUserRoles(data); onChange(data) }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="User Roles"
                        error={errors.Roles ? true : false}
                        helperText={errors.Roles && "User Roles is required!"}
                      // inputRef={register(formError.Roles)}
                      />
                    )}
                    {...props}
                  />
                )}
                onChange={([, data]) => data}
                defaultValue={userRoles}
                name="Roles"
                control={control}
                rules={{
                  validate: (value) => {
                    return value.length !== 0;
                  }
                }}
              />

              {/* <FormControl
                className={classes.formControl}
                error={Object.keys(errors).length > 0 && userRoles.length === 0}
              >
                <InputLabel id="user-role-label">User Roles</InputLabel>
                <Select
                  labelId="user-role-label"
                  id="user-role-select"
                  multiple
                  value={userRoles}
                  onChange={handleUserRoles}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {(selected as string[]).map(value => {
                        return (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        );
                      })}
                    </div>
                  )}
                >
                  {userRolesData?.LDPUserConfigQueryGroup?.Role?.map(role => (
                    <MenuItem
                      key={role?.RoleId}
                      value={role?.Name}
                    // style={getStyles(name, personName, theme)}
                    >
                      {role?.Name}
                    </MenuItem>
                  ))}
                </Select>
                {Object.keys(errors).length > 0 && userRoles.length === 0 && (
                  <FormHelperText>User Roles is required!</FormHelperText>
                )}
              </FormControl> */}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={(userConfigData?.UserConfigId ?? 0) > 0 ? 4 : 6}>
              <Button
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={(userConfigData?.UserConfigId ?? 0) > 0 ? 4 : 6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>

            { (userConfigData?.UserConfigId ?? 0) > 0 ? (
              <Grid item xs={4}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    confirm.useModal(() => {
                      setLoading(true);
                      delUserConfig({
                        variables: {
                          userConfigId: userConfigData?.UserConfigId
                        }
                      })
                        .then(() => {
                          toast.success('User Data Successfully Removed.');
                          close();
                        })
                        .catch((err) => {
                          toast.error(`Unable to delete User: ${err}`);
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }, {
                      title: 'Delete Confirmation',
                      description: 'Are you sure you want to remove this user from LDP?',
                      confirm: 'Delete',
                      cancel: 'Cancel'
                    });
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  fullWidth
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </Grid>
            ) : null }
            
          </Grid>
        </form>
      )}

      <confirm.Modal />
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    container: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);
