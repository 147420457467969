import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";

import { Box, Button, ButtonGroup, FormControl, Grid, IconButton, Select } from "@material-ui/core";
import { AddCircle, Edit, Save } from "@material-ui/icons";
import TrashIcon from '@material-ui/icons/Delete';
import LaunchIcon from "@material-ui/icons/Launch";
import { queryLoad, useModal } from "../../components";

import LDPUIDataTable from "../../components/LDPUIDataTable";



import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { GetContractZipCodeFilter } from "../../common/models/types/GetContractZipCodeFilter";
import { MergeZipCodeFilterVariables } from "../../common/models/types/MergeZipCodeFilter";
import { GET_CONTRACT_ZIP_FILTER, SAVE_CONTRACT_ZIP_FILTER } from "../../common/models/zipCodeFilters";
import { RootState } from "../../state";
import { ContractZipCodeFilterModal } from "./contractZipCodeFilterModal";
import { isRoleExternalAccountManager } from "../../common/utils/roles";

interface ContractZipCodeFilterProps {
  contractId: number;
  loggedInUser?: RootState['loggedInUser'];
  /* contractTabs: any;
  dispatch: Function;
  contractForms: FormStateProps; */
}

//Update props types once view requirements are finalized
const ContractZipCodeFilter = ({ contractId, loggedInUser }: ContractZipCodeFilterProps) => {
  const isUserExternalAccountManager = loggedInUser && isRoleExternalAccountManager(loggedInUser);  
  const [
    getContractZipCodeFilter,
    { data: zipFilterData, loading: zipFilterLoading, error: zipFilterError, refetch: refetchZipCodeFilter },
  ] = useLazyQuery<GetContractZipCodeFilter>(GET_CONTRACT_ZIP_FILTER, { fetchPolicy: "cache-and-network" });

  const [ saveZipCodeFilter ] = useMutation<MergeZipCodeFilterVariables>(SAVE_CONTRACT_ZIP_FILTER);

  const LDP_CONTRACT_ZIPCODE_FILTER = `contract-zipcode-filter-list-${contractId}`;
  
  const [ zips, setZips ] = useState<{ ZipCode: string }[]>([]);
  const [ suppressed, setSuppressed ] = useState<boolean>(false);
  const [ changed, setChanged ] = useState<boolean>(false);

  const onZipSave = (action:string, zipValue:string, oldValue?:string) => {
    // new zips
    const inZips = zipValue.trim().replaceAll('|', ',').split(',').map((z) => z.trim()).filter((z) => z !== '');

    // existing zips
    const zipValues = zips.map((zip) => zip.ZipCode);

    if(action === 'edit_list' && oldValue){
      var newZips = inZips.map((inZip) => ({ ZipCode: inZip }) );
      
    } else {
      if(action === 'edit' && oldValue){
        if(zipValues.includes(oldValue)){
          zipValues.splice( zipValues.indexOf(oldValue), 1 );
        }
      }

      inZips.forEach((inZip) => {
        if(!zipValues.includes(inZip)){
          zipValues.push(inZip);
        }
      });

      var newZips = zipValues.map(z => ({ ZipCode: z }));
    }

    setZips( newZips );
    saveContractZipFilter( newZips );

    closeModal();
  }

  const onZipDelete = (zipValue: string) => {
    const newZips = zips.filter( (zip) => {
      return zip.ZipCode !== zipValue;
    });

    setZips( newZips );
    setChanged(true);
  }

  useEffect(() => {
    getContractZipCodeFilter({ variables: { contractId } });
  }, []);

  useEffect(() => {
    if(zipFilterData){
      const zipDelimited = zipFilterData.LDPConfigQueryGroup?.GetZipCodeFilter?.[0];
      const zipValues = (zipDelimited?.ZipCodeList || '').split('|').map((z) => ({ ZipCode: z.trim() }));

      setSuppressed(!!zipDelimited?.IsSuppressed);
      zipDelimited && setZips( zipValues );
      setChanged(false);
    }
  }, [zipFilterData, zipFilterError]);

  const { Modal, closeModal, openModal } = useModal();

  const saveContractZipFilter = ( zipsData?:{ ZipCode: string }[] ) => {
    toast.info('Saving Zip Code Filter');
    saveZipCodeFilter({
      variables: {
        "zipCodeFilter": {
          "ContractId": contractId,
          "ZipCodeFilterId": zipFilterData?.LDPConfigQueryGroup?.GetZipCodeFilter?.[0]?.ZipCodeFilterId,
          "ZipCodeList": (zipsData || zips).map(zc => zc.ZipCode).join('|'),
          "IsSuppressed": suppressed
        }
      }
    }).then(() => {
      refetchZipCodeFilter();
      toast.success('Saving Zip Code Filter Success');
      setChanged(false);
    }).catch(() => {
      //toast.error('Saving Zip Code Filter Failed');
    });
  }

  const ZipCodeFilterCols:MUIDataTableColumn[] = [
    {
      name: "DataEditCol",
      label: "Edit",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  console.log(tableMeta);
                  openModal({
                    title: `Edit Zip Code`,
                    icon: <Edit />,
                    iconColor: "orange",
                    content: (
                      <ContractZipCodeFilterModal
                        action="edit"
                        data={tableMeta.rowData[2]}
                        onSave={onZipSave}
                        close={closeModal}
                      />
                    ),
                  });
                }}
              >
                <LaunchIcon />
              </IconButton>
            </>);
        },
      },
    },
    {
      name: "DataDeleteCol",
      label: "Remove",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  onZipDelete(tableMeta.rowData[2]);
                }}
              >
                <TrashIcon />
              </IconButton>
            </>);
        },
      },
    },
    {
      name: "ZipCode",
      label: "Zip Code",
      options: {
        viewColumns: true,
        filter: true,
        sort: true,
      }
    },
  ];

  const options:MUIDataTableOptions = {
    selectableRows: 'none',
    sortOrder: {
      name: "ZipCode",
      direction: "asc",
    },
    download: !isUserExternalAccountManager,
    print: !isUserExternalAccountManager,
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: `Add Zip Code`,
                    icon: <LaunchIcon />,
                    iconColor: "orange",
                    content: (
                      <ContractZipCodeFilterModal
                        action="create"
                        data={null}
                        onSave={onZipSave}
                        close={closeModal}
                      />
                    ),
                  });
                }}
              >
                Add Zip Code
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<Edit />}
                onClick={() => {
                  openModal({
                    title: `Edit Zip Code List`,
                    icon: <LaunchIcon />,
                    iconColor: "orange",
                    content: (
                      <ContractZipCodeFilterModal
                        action="edit_list"
                        data={zips.map(zc => zc.ZipCode).join(',')}
                        onSave={onZipSave}
                        close={closeModal}
                      />
                    ),
                  });
                }}
              >
                Edit Whole List
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  native
                  value={suppressed ? "suppressed" : "unsuppressed"}
                  onChange={(evt) => {
                    setSuppressed(!suppressed);
                    setChanged((zipFilterData?.LDPConfigQueryGroup?.GetZipCodeFilter?.[0]?.IsSuppressed != !suppressed));
                  }}
                >
                  <option value="supressed">Suppressed</option>
                  <option value="unsuppressed">Not Suppressed</option>
                </Select>
              </FormControl>
              
              {/* <ButtonGroup fullWidth size="large" color="primary" variant="contained">
                <Button disabled={!suppressed} onClick={() => {
                  setSuppressed(!suppressed);
                  setChanged((zipFilterData?.LDPConfigQueryGroup?.GetZipCodeFilter?.[0]?.IsSuppressed != !suppressed));
                }}>Suppressed</Button>
                <Button disabled={suppressed} onClick={() => {
                  setSuppressed(!suppressed);
                  setChanged((zipFilterData?.LDPConfigQueryGroup?.GetZipCodeFilter?.[0]?.IsSuppressed != !suppressed));
                }}>Not Suppressed</Button>
              </ButtonGroup> */}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                disabled={!changed}
                startIcon={<Save />}
                onClick={() => {
                  saveContractZipFilter();
                }}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        
        {queryLoad([!!zipFilterLoading], [zipFilterError]) || (
          <LDPUIDataTable
            ldpTableId={LDP_CONTRACT_ZIPCODE_FILTER}
            restoreFilters={true}
            title={""}
            data={zips}
            columns={ZipCodeFilterCols}
            options={options}
          />
        )}
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  loggedInUser: state.loggedInUser,
}), null)(ContractZipCodeFilter);
