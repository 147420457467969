import gql from "graphql-tag";

export const GET_CONTRACTS_SIMPLE = gql`
  query GetContractsSimple($where: String) {
    LDPConfigQueryGroup {
      Contract(limit:9999, where: $where) {
        ContractId
        ContractName
        CreatedDate
        IsActive
        ModifiedDate
        BuyerId
      }
    }
  }
`;

export const GET_CONTRACTS = gql`
  query GetContracts($where: String) {
    LDPConfigQueryGroup {
      Contract(limit: 9999, where: $where) {
        ContractId
        ContractName
        BuyerGroup {
          BuyerGroupId
          BuyerGroupName
        }
        Buyer {
          BuyerId
          BuyerName
          AccountManagerName
        }
        CreatedDate
        CreditBalance
        DefaultPrice
        ExternalContractId
        IsActive
        IsPostOnly
        ModifiedDate
        Position
        SubVertical {
          SubVerticalName
          SubVerticalId
        }
        UserId
        Vertical {
          VerticalName
          VerticalId
        }
        BuyerId
        ExclusiveSale
        MaxNonExclusiveSaleCount
        PriceAdjustmentPercentage
        CanSellAsUndersold
        IsCatchall
      }
    }
  }
`;

export const SAVE_CONTRACT = gql`
  mutation SaveContract($contractData: ContractInputType!) {
    LDPConfigMutationGroup {
      SaveContract(ContractDataInput: $contractData)
    }
  }
`;

export const GET_INCOMPLETE_CONTRACT = gql`
  query GetIncompleteContract($contractId: ID) {
    LDPConfigQueryGroup {
      GetIncompleteContract(ContractId: $contractId) {
        ContractId
        IncompleteMsg
      }
    }
  }
`;

export const CLONE_CONTRACT = gql`
  mutation CloneContract($contractParams: CloneContractInputType!) {
    LDPConfigMutationGroup {
      CloneContract(CloneContractParams: $contractParams)
    }
  }
`;

export const SET_CONTRACT_IS_ACTIVE = gql`
  mutation SetContractIsActive(
    $contractId: ID!
    $isActive: Boolean!
    $userId: String!
  ) {
    LDPConfigMutationGroup {
      SetContractIsActive(
        ContractId: $contractId
        IsActive: $isActive
        UserId: $userId
      )
    }
  }
`;
