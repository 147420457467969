import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { connect, useStore } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SAVE_CONTRACT } from "../../common/models/contracts";
import { ContractInputType } from "../../types/graphql-global-types";
import { GetContracts_LDPConfigQueryGroup_Contract } from "../../common/models/types/GetContracts";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import SaveIcon from "@material-ui/icons/Save";
import { GET_BUYERS } from "../../common/models/buyers";
import { GetBuyers } from "../../common/models/types/GetBuyers";
import { GET_ALL_BUYERGROUPS } from "../../common/models/buyergroups";
import { GetAllBuyerGroups } from "../../common/models/types/GetAllBuyerGroups";
import { queryLoad, useConfirmation } from "../../components";

import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { GetVertical } from "../../common/models/types/GetVertical";
import { GetSubVertical, GetSubVertical_LDPConfigQueryGroup_SubVertical } from "../../common/models/types/GetSubVertical";

import { signalContractSaved, } from "../../state/contractSectionReducer";
import CloseIcon from '@material-ui/icons/Close';
import TabIcon from '@material-ui/icons/Tab';
import LaunchIcon from '@material-ui/icons/Launch';
import { FormStateProps, toggleForm } from "../../state/formReducer";
import { RootState } from "../../state";

interface ContractFormModalProps {
  data: GetContracts_LDPConfigQueryGroup_Contract | null;
  close: Function;
  dispatch?: Function;
  isDocked?: boolean;
  contractTabs?: any;
  contractForm: FormStateProps;
  formId: string;
}
interface MappedSelectField {
  id: number;
  label: string;
}

const signedFloatPattern = /^\-?[0-9]+(\.[0-9]+)?$/s;
const numericPattern = /^[0-9]+(\.[0-9]+)?$/s;
const intPattern = /^[0-9]+$/s;

const formError = {
  ContractName: {
    required: {
      value: true,
      message: "Contract Name is required.",
    },
  },
  Position: {
    required: {
      value: true,
      message: "Position is required.",
    },
    pattern: {
      value: intPattern,
      message: "Position needs to be integer.",
    },
  },
  CreditBalance: {
    required: {
      value: true,
      message: "Credit Balance is required.",
    },
    pattern: {
      value: numericPattern,
      message: "Credit Balance should not be negative and is numeric only.",
    },
  },
  BuyerId: {
    required: {
      value: true,
      message: "Buyer is required.",
    },
  },
  BuyerGroupId: {
    required: {
      value: true,
      message: "Buyer Group is required.",
    }
  },
  ExternalContractId: {
    required: {
      value: true,
      message: "ExternalContractId is required.",
    },
    pattern: {
      value: intPattern,
      message: "ExternalContractId must be numeric ID",
    },
  },
  VerticalId: {
    required: {
      value: true,
      message: "Vertical is required.",
    },
  },
  SubVerticalId: {
    required: {
      value: true,
      message: "Sub Vertical is required.",
    },
  },
  /* MaxNonExclusiveSaleCount: {
    required: {
      value: true,
      message: "Exclusive Sale Count is required.",
    },
    pattern: {
      value: numericPattern,
      message: "Exclusive Sale Count must be numeric.",
    },
  }, */
  PriceAdjustmentPercentage: {
    required: {
      value: false,
      message: "Price Adjustment Percentage is required.",
    },
    min: {
      value: -9999,
      message: "Price Adjustment Percentage value must be higher -10,000.",
    },
    max: {
      value: 1000000,
      message: "Price Adjustment Percentage value must not exceed 1,000,000.",
    },
    pattern: {
      value: signedFloatPattern,
      message: "Price Adjustment Percentage must be numeric.",
    },
  },
};

const WrappedContractFormModal = ({
  data,
  close,
  dispatch,
  isDocked,
  contractTabs,
  contractForm,
  formId,
}: ContractFormModalProps) => {
  const confirm = useConfirmation();

  const [
    getAllBuyers,
    { data: buyersData, error: buyersError, loading: buyersLoading },
  ] = useLazyQuery<GetBuyers>(GET_BUYERS);

  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);

  const [
    getAllSubVertical,
    {
      data: subVerticalData,
      error: subVerticalError,
      loading: subVerticalLoading,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  const [
    getAllBuyerGroups,
    { data: buyerGroupsData, error: buyerGroupsError, loading: buyerGroupsLoading },
  ] = useLazyQuery<GetAllBuyerGroups>(GET_ALL_BUYERGROUPS);

  const { register, handleSubmit, watch, setValue, setError, errors, control } = useForm<
    ContractInputType
  >();
  const classes = useStyles();
  const [saveContract] = useMutation(SAVE_CONTRACT);
  const [tabs, setTabs] = useState();

  const callSaveContract = (contractData: any) => {
    saveContract({ variables: { contractData } }).then((response: any) => {
      if (contractData?.ContractId > 0)
        toast.success("Contract updated successfully.");
      else toast.success("Contract created successfully.");

      dispatch && dispatch(signalContractSaved());
      close();
    }).catch((error) => {
      console.log('saveContract', error);
    });
  }

  const onSubmit = (dataInput: ContractInputType) => {
    // e.preventDefault();

    if(dataInput.IsPostOnly){
      if( dataInput.DefaultPrice &&  dataInput.DefaultPrice <= 0){
        setError("DefaultPrice", {
          type: "manual",
          message: "Default price must be greater than 0 "
        });
        return;
      }
    }
    let contractData = {
      ...dataInput,
      DefaultPrice: dataInput?.DefaultPrice !== "" ? dataInput?.DefaultPrice : null,
      PriceAdjustmentPercentage: dataInput?.PriceAdjustmentPercentage !== "" ? dataInput?.PriceAdjustmentPercentage : null,
      MaxNonExclusiveSaleCount: 0,
      BuyerId: mappedBuyers?.find(
        (buyer: MappedSelectField) => buyer?.label === dataInput.BuyerId
      )?.id,
      BuyerGroupId: mappedBuyerGroups?.find(
        (buyerGroup: MappedSelectField) => buyerGroup?.label === dataInput.BuyerGroupId
      )?.id,
      VerticalId: mappedVertical?.find(
        (vertical: MappedSelectField) =>
          vertical?.label === dataInput.VerticalId
      )?.id,
      SubVerticalId: mappedSubVertical?.find(
        (subVertical: MappedSelectField) =>
          subVertical?.label === dataInput.SubVerticalId
      )?.id,
    };

    if (contractData?.ContractId > 0) {
      confirm.useModal(() => {
        callSaveContract(contractData);
      }, {
        title: 'Update Confirmation',
        description: 'Updating  will deactivate the contract and requires a test before reactivating. Are you sure you want to update?',
        confirm: 'Update',
        cancel: 'Cancel'
      });
    } else {
      callSaveContract(contractData);
    }
  };

  const [mappedBuyers, setMappedBuyers] = useState<MappedSelectField[]>();
  const [mappedBuyerGroups, setMappedBuyerGroups] = useState<MappedSelectField[]>();
  const [selectedBuyer, setSelectedBuyer] = useState<MappedSelectField | null>(null);
  const [selectedBuyerGroup, setSelectedBuyerGroup] = useState<MappedSelectField | null>(null);
  const [mappedVertical, setMappedVertical] = useState<MappedSelectField[]>();
  const [isPostOnly, setIsPostOnly] = useState<boolean | undefined>(!!data?.IsPostOnly);

  const [
    selectedVertical,
    setSelectedVertical,
  ] = useState<MappedSelectField | null>(null);
  const [mappedSubVertical, setMappedSubVertical] = useState<
    MappedSelectField[]
  >();

  const [
    selectedSubVertical,
    setSelectedSubVertical,
  ] = useState<MappedSelectField | null>(null);

  const [loading, setLoading] = useState(true);
  const [priceAdjustment, setPriceAdjustment] = useState(data?.PriceAdjustmentPercentage ?? 0);

  const onChangePriceAdjustment = (event: BaseSyntheticEvent) => {
    let value = parseInt(event.target.value ?? "");

    if (value < 0) value = 0;
    if (value > 100) return false;

    setValue('PriceAdjustmentPercentage', value);
    setPriceAdjustment(value);
  }

  const [formData, setFormData] = useState<any>(data);

  const formatContractData = () => {
    const dataInput = control.getValues();

    let contractData = {
      ...dataInput,
      MaxNonExclusiveSaleCount: 0,
      BuyerId: mappedBuyers?.find(
        (buyer: MappedSelectField) => buyer?.label === dataInput.BuyerId
      )?.id,
      BuyerGroupId: mappedBuyerGroups?.find(
        (buyerGroup: MappedSelectField) => buyerGroup?.label === dataInput.BuyerGroupId
      )?.id,
      VerticalId: mappedVertical?.find(
        (vertical: MappedSelectField) =>
          vertical?.label === dataInput.VerticalId
      )?.id,
      SubVerticalId: mappedSubVertical?.find(
        (subVertical: MappedSelectField) =>
          subVertical?.label === dataInput.SubVerticalId
      )?.id,
    };

    return contractData;
  };

  useEffect(() => {
    if (contractForm.formContainers[formId]) {
      if (!formData) {
        setFormData(contractForm.formContainers[formId].formData);
      }
    }
  }, [contractForm]);

  useEffect(() => {
    if (contractTabs.tabContainers["contract-list-tabs"]) {
      setTabs(contractTabs.tabContainers["contract-list-tabs"].tabs);
    }
  }, [contractTabs]);

  useEffect(() => {
    if (buyersData) {
      setMappedBuyers(
        buyersData?.LDPConfigQueryGroup?.Buyer?.map(x => {
          return { id: x?.BuyerId, label: `${x?.BuyerName} - ${x?.BuyerId}` };
        })
      );
    } else if (!buyersData) {
      getAllBuyers();
    }

    if (verticalData) {
      setMappedVertical(
        verticalData?.LDPConfigQueryGroup?.Vertical?.map(x => {
          return {
            id: x?.VerticalId,
            label: `${x?.VerticalName} - ${x?.VerticalId}`,
          };
        })
      );
    } else if (!verticalData) {
      getAllVertical();
    }

    if (!subVerticalData) {
      getAllSubVertical();
    }

    if (buyerGroupsData) {
      setMappedBuyerGroups(
        buyerGroupsData?.LDPConfigQueryGroup?.BuyerGroup?.map(x => {
          return { id: x?.BuyerGroupId, label: `${x?.BuyerGroupName} - ${x?.BuyerGroupId}` };
        })
      );
    }
    else if (!buyerGroupsData) {
      getAllBuyerGroups();
    }
  }, [buyersData, verticalData, subVerticalData, buyerGroupsData]);


  useEffect(() => {
    setSelectedBuyer(
      mappedBuyers?.find(
        (buyer: MappedSelectField) => buyer?.id === formData?.BuyerId
      ) || null
    );
    setLoading(false);
  }, [mappedBuyers]);

  useEffect(() => {
    setSelectedBuyerGroup(
      mappedBuyerGroups?.find(
        (buyerGroup: MappedSelectField) => buyerGroup?.id === data?.BuyerGroup?.BuyerGroupId || buyerGroup?.id === formData?.BuyerGroupId
      ) || null
    );
    setLoading(false);
  }, [mappedBuyerGroups]);

  useEffect(() => {
    setSelectedVertical(
      mappedVertical?.find(
        (vertical: MappedSelectField) => vertical?.id === data?.Vertical?.VerticalId || vertical?.id === formData?.VerticalId
      ) || null
    );
    setLoading(false);
  }, [mappedVertical]);

  useEffect(() => {
    if (selectedVertical) {
      const subVerticalList = subVerticalData?.LDPConfigQueryGroup?.SubVertical?.map((item: GetSubVertical_LDPConfigQueryGroup_SubVertical) => (
        {
          id: item?.SubVerticalId,
          label: item?.SubVerticalName,
          parent: item?.VerticalId
        }
      )) ?? [];

      const options = subVerticalList.filter((it: any) => it?.parent === selectedVertical.id) ?? [];
      setMappedSubVertical(options);
      setSelectedSubVertical(options?.find((subVertical: MappedSelectField) => subVertical?.id === data?.SubVertical?.SubVerticalId || subVertical?.id === formData?.SubVerticalId) || null);
    }
  }, [selectedVertical]);

  return (
    <Paper className={classes.container}>
      {!isDocked &&
        <div style={{ position: "absolute", top: "0px", right: "15px", marginTop: "-86px" }}>
          <Tooltip title="Dock to tab">
            <IconButton aria-label="settings"
              onClick={() => {
                dispatch(toggleForm({ formId: formId, formData: formatContractData() }));
              }
              }
            >
              <TabIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton aria-label="settings"
              onClick={() => {
                close();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      {isDocked &&
        <div style={{ textAlign: "right", marginRight: "10px" }}>
          <Tooltip title="Popup">
            <IconButton aria-label="settings"
              onClick={() => {
                dispatch(toggleForm({ formId: formId, formData: formatContractData() }));
              }
              }
            >
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      {queryLoad([!!buyersLoading, !!loading], [buyersError]) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                hidden
                inputRef={register}
                name="ContractId"
                defaultValue={formData?.ContractId ?? 0}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="IsActive"
                defaultValue={!!formData?.IsActive}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="UserId"
                defaultValue={formData?.UserId ?? "Test User"}
              />
              {/* SAA-654 Create New Contracts - Hide Exclusive Sale Count */}
              <TextField
                hidden
                inputRef={register}
                name="MaxNonExclusiveSaleCount"
                defaultValue={0}
                value={0}
              />
              <TextField
                inputRef={register(formError.ContractName)}
                error={errors.ContractName && true}
                helperText={errors.ContractName && errors.ContractName?.message}
                name="ContractName"
                label="Contract Name"
                defaultValue={formData?.ContractName ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.CreditBalance)}
                error={errors.CreditBalance && true}
                helperText={
                  errors.CreditBalance && errors.CreditBalance?.message
                }
                name="CreditBalance"
                label="Credit Balance"
                defaultValue={formData?.CreditBalance ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register(formError.Position)}
                error={errors.Position && true}
                helperText={errors.Position && errors.Position?.message}
                name="Position"
                label="Position"
                defaultValue={formData?.Position ?? 0}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register({
                  pattern: {
                    value: numericPattern,
                    message: "DefaultPrice should be numeric.",
                  },
                  required: {
                    value: isPostOnly,
                    message: "DefaultPrice is required.",
                  },
                  maxLength: {
                    value: 5,
                    message: "DefaultPrice max length is 5.",
                  },
                })}
                error={errors.DefaultPrice && true}
                helperText={errors.DefaultPrice && errors.DefaultPrice?.message}
                name="DefaultPrice"
                label="Default Price"
                defaultValue={formData?.DefaultPrice ?? ""}
                variant="outlined"
                type="float"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register(formError.PriceAdjustmentPercentage)}
                error={errors.PriceAdjustmentPercentage && true}
                helperText={
                  errors.PriceAdjustmentPercentage &&
                  errors.PriceAdjustmentPercentage?.message
                }
                name="PriceAdjustmentPercentage"
                label="Price Inflator %"
                title="Effective Price = (Price Offered * Price Inflator%) / 100 + Price Offered"
                defaultValue={formData?.PriceAdjustmentPercentage ?? ""}
                // value={priceAdjustment ?? ""}
                // InputProps={{ inputProps: { min: 0, max: 100 } }}
                variant="outlined"
                // onChange={onChangePriceAdjustment}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="buyer-id-input"
                options={mappedBuyers || []}
                getOptionLabel={option => option.label}
                value={selectedBuyer}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedBuyer(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Buyer"
                    variant="outlined"
                    error={errors.BuyerId ? true : false}
                    helperText={errors.BuyerId && errors.BuyerId?.message}
                    // defaultValue={data?.BuyerId ?? ""}
                    inputRef={register(formError.BuyerId)}
                    name="BuyerId"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="buyergroup-id-input"
                options={mappedBuyerGroups || []}
                getOptionLabel={option => option.label}
                value={selectedBuyerGroup}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedBuyerGroup(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Buyer Group"
                    variant="outlined"
                    error={errors.BuyerGroupId ? true : false}
                    helperText={errors.BuyerGroupId && errors.BuyerGroupId?.message}
                    inputRef={register(formError.BuyerGroupId)}
                    name="BuyerGroupId"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register(formError.ExternalContractId)}
                error={errors.ExternalContractId && true}
                helperText={
                  errors.ExternalContractId &&
                  errors.ExternalContractId?.message
                }
                name="ExternalContractId"
                label="External Contract Id"
                defaultValue={formData?.ExternalContractId ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="vertical-id-input"
                options={mappedVertical || []}
                getOptionLabel={option => option.label}
                value={selectedVertical}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedVertical(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Vertical"
                    variant="outlined"
                    error={errors.VerticalId ? true : false}
                    helperText={errors.VerticalId && errors.VerticalId?.message}
                    // defaultValue={data?.BuyerId ?? ""}
                    inputRef={register(formError.VerticalId)}
                    name="VerticalId"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="subvertical-id-input"
                options={mappedSubVertical || []}
                getOptionLabel={option => option.label}
                value={selectedSubVertical}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedSubVertical(newValue);
                }}
                disabled={!selectedVertical}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="SubVertical"
                    variant="outlined"
                    error={errors.SubVerticalId ? true : false}
                    helperText={
                      errors.SubVerticalId && errors.SubVerticalId?.message
                    }
                    // defaultValue={data?.BuyerId ?? ""}
                    inputRef={register(formError.SubVerticalId)}
                    name="SubVerticalId"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={isPostOnly === undefined ? !!formData?.IsPostOnly : isPostOnly}
                    name="IsPostOnly"
                    color="primary"
                    onChange={(event: any, value: boolean) => {
                      setIsPostOnly(value);
                    }}
                  />
                }
                label="IsPost"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={formData?.CanSellAsUndersold ?? true}
                    name="CanSellAsUndersold"
                    color="primary"
                  />
                }
                label="Undersold"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={formData?.IsCatchall ?? false}
                    name="IsCatchall"
                    color="primary"
                  />
                }
                label="Catch-All"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={formData?.ExclusiveSale ?? true}
                    name="ExclusiveSale"
                    color="primary"
                  />
                }
                label="Exclusive Sale"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      <confirm.Modal />
    </Paper>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

export const ContractFormModal = connect((state: RootState) => ({
  contractTabs: state.tabsSection,
  contractForm: state.formsSection,
}), null)(WrappedContractFormModal);