import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, AddCircle, Edit as EditIcon, History as HistoryIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { GET_BUYER_SUBVERTICAL_MONETARY_CAPACITY } from "../../common/models/buyerCapacity";
import {
  BuyerSubVerticalMonetaryCapacityConfig,
  BuyerSubVerticalMonetaryCapacityConfigVariables,
} from "../../common/models/types/BuyerSubVerticalMonetaryCapacityConfig";
import { dateToPreferredTimezone } from "../../common/utils/date";
import {
  ContentLoading,
  LDPUIDataTable,
  StatusIcon,
  useModal,
} from "../../components";
import {
  activeFilter,
  numberColumnFilterNullTo1000K,
  stringColumnFilter,
} from "../../components/customMuiDatatableFilter";
import { RootState } from "../../state";
import { FormStateProps } from "../../state/formReducer";
import { GetVertical, GetVertical_LDPConfigQueryGroup_Vertical } from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { GetSubVertical, GetSubVertical_LDPConfigQueryGroup_SubVertical } from "../../common/models/types/GetSubVertical";
import { BuyerSubVerticalMonetaryCapacityFormModal } from "./buyerSubVerticalMonetaryCapacityModal";

interface BuyerSubVerticalMonetaryCapacityListProps {
  buyerId: string;
  dispatch?: Function;
  formsSection?: FormStateProps;
}

interface SubVerticalOption {
  vertical: GetVertical_LDPConfigQueryGroup_Vertical;
  subvertical: GetSubVertical_LDPConfigQueryGroup_SubVertical;
  exists: boolean;
}

const BuyerSubVerticalMonetaryCapacityList = ({
  buyerId,
  dispatch,
  formsSection,
}: BuyerSubVerticalMonetaryCapacityListProps) => {
  const [
    getBuyerSubVertMonetaryCapacities,
    { data, error, loading, called, refetch },
  ] = useLazyQuery<
    BuyerSubVerticalMonetaryCapacityConfig,
    BuyerSubVerticalMonetaryCapacityConfigVariables
  >(GET_BUYER_SUBVERTICAL_MONETARY_CAPACITY, { fetchPolicy: "network-only" });

  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);

  const [
    getAllSubVertical,
    {
      data: subVerticalData,
      error: subVerticalError,
      loading: subVerticalLoading,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  const [subVerticalFilter, setSubVerticalFilters] = useState<string[]>([]);
  const [ subVerticalOptions, setSubVerticalOptions ] = useState<SubVerticalOption[]>([]);
  const [ selectedIndexes, setSelectedIndexes ] = useState<number[]>([]);

  const { Modal, closeModal, openModal, setContent } = useModal();

  useEffect(() => {
    getBuyerSubVertMonetaryCapacities({
      variables: {
        where: `BuyerId = ${buyerId}`,
      },
    });
  }, []);

  useEffect(() => {
    getAllVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
  }, [data, error]);

  useEffect(() => {
    getAllSubVertical({
      variables: {
        where: `IsActive = true`,
      },
    });
  }, [verticalData, verticalError]);

  useEffect(() => {
    if(data && verticalData && subVerticalData){
      const options: SubVerticalOption[] = [];
      for(const sv of (subVerticalData.LDPConfigQueryGroup?.SubVertical || [])){
        const v = (verticalData?.LDPConfigQueryGroup?.Vertical || []).find((vert) => vert?.VerticalId === sv?.VerticalId);
        if(v && sv){
          options.push({
            vertical: v,
            subvertical: sv,
            exists: !!data?.LDPConfigQueryGroup?.BuyerSubVerticalMonetaryCapacityConfig?.find((cap) => cap?.SubVerticalName === sv.SubVerticalName)
          })
        }
      }

      options
        .sort((sv1, sv2) => `${sv1.vertical.VerticalName} - ${sv1.subvertical.SubVerticalName}` > `${sv2.vertical.VerticalName} - ${sv2.subvertical.SubVerticalName}` ? 1 : -1);
      setSubVerticalOptions(options.filter((sv) => sv.exists) );
    }
  }, [data, verticalData, subVerticalData]);

  const handleSubVerticalFilterDeletes = (filterList: string[][], filterPos: number, index: number) => {
    if (filterPos === -1) {
      filterList[index] = [];
    } else {
      filterList[index].splice(filterPos, 1);
    }

    const selected = subVerticalOptions
      .map((svo, i) => filterList[index].includes(`${svo.subvertical.SubVerticalName}`) ? i : -1 )
      .filter(n => n > -1).map(n => n + 1);

    setSelectedIndexes(selected);

    return filterList;
  };

  const mapRowDataToColumns = (muiColumns: any, rowData: any) => {
    if (rowData) {
      let newJson: any = {};
      muiColumns.map((column: any, key: number) => {
        newJson[column.name] = rowData[key];
      });
      return newJson;
    } else return null;
  };

  const columnsMUI = [
    {
      name: "BuyerSubVerticalMonetaryCapacityConfigId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          console.log(tableMeta);
          return (
            <>
              <Tooltip placement="top" title={
                <React.Fragment>
                  <Typography color="textPrimary">Edit</Typography>
                </React.Fragment>
              }>
                <IconButton onClick={() => {
                  openModal({
                    title: `Edit Buyer Subvertical Monetary #${value}  Capacity`,
                    icon: <EditIcon />,
                    iconColor: "violet",
                    content: (
                      <BuyerSubVerticalMonetaryCapacityFormModal
                        action="edit"
                        buyerId={buyerId}
                        inUsedList={tableMeta?.tableData?.map((it: any) => it[1])}
                        data={mapRowDataToColumns(columnsMUI, tableMeta.rowData)}
                        close={closeModal}
                        refetch={() => {
                          getBuyerSubVertMonetaryCapacities({
                            variables: {
                              where: `BuyerId = ${buyerId}`
                            }
                          });
                        }}
                      />
                    ),
                  });
                }}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={(value) => {
                    console.log("History", value);
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },

    {
      name: "SubVerticalName",
      label: "SubVertical",
      options: {
        viewColumns: true,
        filter: true,
        filterList: subVerticalFilter,
        ...stringColumnFilter,
        customFilterListOptions: {
          update: handleSubVerticalFilterDeletes,
        },
      },
    },

    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
      },
    },
    {
      name: "MaxMonthlySubVerticalBudget",
      label: "Monthly Cap($)",
      options: {
        viewColumns: false,
        filter: true,
        ...numberColumnFilterNullTo1000K,
        customBodyRender: (value: any) => value !== null ? `$${value}` : '',
      },
    },
    {
      name: "MaxWeeklySubVerticalBudget",
      label: "Weekly Cap($)",
      options: {
        viewColumns: false,
        filter: true,
        ...numberColumnFilterNullTo1000K,
        customBodyRender: (value: any) => value !== null ? `$${value}` : '',
      },
    },
    {
      name: "MaxDailySubVerticalBudget",
      label: "Daily Cap($)",
      options: {
        viewColumns: false,
        filter: true,
        ...numberColumnFilterNullTo1000K,
        customBodyRender: (value: any) => value !== null ? `$${value}` : '',
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        viewColumns: true,
        ...activeFilter,
      },
    },
    {
      name: "Notes",
      label: "Notes",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "UserId",
      label: "User ID",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <Grid item xs={12}>
        <Box p={3}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  // console.log("Add Buyer Monetary Capacity");
                  openModal({
                    title: "Add Buyer SubVertical Monetary Capacity",
                    icon: <Add />,
                    iconColor: "green",
                    content: (
                      <BuyerSubVerticalMonetaryCapacityFormModal
                        action="edit"
                        buyerId={buyerId}
                        inUsedList={data?.LDPConfigQueryGroup?.BuyerSubVerticalMonetaryCapacityConfig?.map((it)=> it?.SubVerticalName) ?? []}
                        data={mapRowDataToColumns(columnsMUI, {})}
                        close={closeModal}
                        refetch={() => {
                          getBuyerSubVertMonetaryCapacities({
                            variables: {
                              where: `BuyerId = ${buyerId}`
                            }
                          });
                        }}
                      />
                    ),
                  });
                }}
              >
                Add SubVertical Monetary Capacity
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {!data && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId={`buyer-sv-monetary-${buyerId}-caps-list`}
          restoreFilters={true}
          title={"Buyer SubVertical Monetary Capacity"}
          data={data?.LDPConfigQueryGroup?.BuyerSubVerticalMonetaryCapacityConfig ?? []}
          columns={columnsMUI}
          options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    formsSection: state.formsSection,
  }),
  null
)(BuyerSubVerticalMonetaryCapacityList);
