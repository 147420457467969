import React, { useEffect, useState } from "react";
import { useModal } from "../../components";

import { IconButton, Grid, Typography, Link, Box, Button } from "@material-ui/core";
import LeadViewModal from "./leadViewModal";
import ListIcon from '@material-ui/icons/List';
import { Edit as EditIcon } from '@material-ui/icons';

import LDPUIDataTable from "../../components/LDPUIDataTable";
import TimelineIcon from "@material-ui/icons/Timeline";
import { useEllipsisStyles } from "../../components/tooltip";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { TabProps, updateTabContainers } from "../../state/tabsReducer";
import { useLazyQuery } from "@apollo/react-hooks";
import { GetAccountManagers_LDPUserConfigQueryGroup_UserConfig_UserConfigRole } from "../../common/models/types/GetAccountManagers";
import { LoggedInUserProps } from "../../state/loggedInUserReducer";
import { GET_MODIFY_PRICE_BY_SIGNUPID } from "../../common/models/modifyPrice";
import LeadConfigHistoryModal from "./leadConfigHistoryModal";
import HistoryIcon from '@material-ui/icons/History';
import ModifyPriceModal from "./modiftPriceModal";
import { LdpJourneyDetails } from "../opsScreen/ldpJourneyDetails";
import { isRolePriceAdjuster } from "../../common/utils/roles";

interface ModifyPriceResultListProps {
    dispatch: Function;
    tabs: any;
    signupId: number;
    leadJourneyDetails: any;
    fetchEDW?: boolean;
    loggedInUser: LoggedInUserProps;  
}

const TAB_CONTAINER = "leads-list-tabs";

const ModifyPriceResultList = ({
  dispatch,
  tabs,
  signupId,
  leadJourneyDetails,
  loggedInUser,
}: ModifyPriceResultListProps) => {
  const classes = useEllipsisStyles();
  const { Modal, closeModal, openModal } = useModal(undefined, 0.6);

  const [getModifyPrice, { data: getModifyPriceData, refetch }] = useLazyQuery(
    GET_MODIFY_PRICE_BY_SIGNUPID,
    {
      fetchPolicy: "network-only",
    }
  );

  const [tableData, setTableData] = useState([]);
  const [soldAs, setSoldAsType] = useState();
  useEffect(() => {
    if (signupId) {
      getModifyPrice({
        variables: { signupId: signupId },
      });
    }
  }, [signupId]);

  useEffect(() => {
    if (getModifyPriceData?.LDPConfigQueryGroup?.ModifyPriceCurrent) {
      const modifyPriceData = JSON.parse(
        getModifyPriceData.LDPConfigQueryGroup.ModifyPriceCurrent
      );
      setTableData(modifyPriceData?.BuyerUpdateResponse?.PriceUpdateData);
      setSoldAsType(modifyPriceData?.BuyerUpdateResponse?.SoldAsType);
    }    
  }, [getModifyPriceData]);

  const handleCloseTab = (tabs: TabProps[]) => {
    dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: tabs.length - 1,
          tabs: tabs,
        },
      })
    );
  };

    const handleEditorTab = () => {
      const currentTabs = tabs.tabContainers[TAB_CONTAINER].tabs;
      dispatch(
        updateTabContainers({
          [TAB_CONTAINER]: {
            tabFocus: currentTabs.length,
            tabs: [
              ...currentTabs,
              {
                title: `Edit {CHANGE_TITLE}`,
                tabId: `leads-result-${currentTabs.length}`,
                content: <div>Edit here...</div>,
                closeTab: handleCloseTab,
              },
            ],
          },
        })
      );
    };

  const columnsMUI = [
    {
      name: " ",
      label: "",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const {LeadDataId, VerticalId, SubVerticalId, FetchEDW} = leadJourneyDetails;
          return (
            <div style={{ width: "110px" }}>
              <IconButton
                title="Lead Journey"
                onClick={() => {
                  openModal({
                    content: <LdpJourneyDetails LeadDataId={LeadDataId} VerticalId={VerticalId} SubVerticalId={SubVerticalId} FetchEDW={!!FetchEDW} />,
                    title: `LDP Journey Lead Data Id #${LeadDataId} on Vertical ${VerticalId}, Sub-Vertical ${SubVerticalId}`,
                    icon: <TimelineIcon />,
                    iconColor: "rose",
                });
                }}
              >
                <TimelineIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
    {
      name: "BuyerContractId",
      label: "Buyer Contract ID",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "LdpBuyerName",
      label: "Buyer Name",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "Amount",
      label: "Current Price",
      options: {
        filter: false,
        display: true,
      },
    },
  ];

  const options: any = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "ContractName",
      direction: "asc",
    },
  };

  return (
    <Grid container spacing={1}>
      {!tableData || (
        <>
          <Grid item xs={12}>
            <Box p={1}>
              <Grid container spacing={1} justify="flex-end">
                <Grid item sm={6} md={3}>
                  {loggedInUser && isRolePriceAdjuster(loggedInUser) && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={tableData?.length < 1}
                      fullWidth
                      startIcon={<EditIcon />}
                      onClick={() => {
                        if (tableData?.length > 0) {
                          openModal({
                            title: "Edit Prices",
                            icon: <EditIcon />,
                            iconColor: "green",
                            content: (
                              <ModifyPriceModal
                                data={tableData}
                                signupId={signupId}
                                close={closeModal}
                                refetch={refetch}
                              />
                            ),
                          });
                        }                        
                      }}
                    >
                      Edit Price
                    </Button>
                  )}
                  </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<HistoryIcon />}
                    onClick={() => {
                        openModal({
                        title: `Lead Config History #${signupId}`,
                        icon: <HistoryIcon />,
                        iconColor: "violet",
                        content: (
                            <LeadConfigHistoryModal SignupId={signupId} />
                        ),
                        });
                    }}
                    >
                    Config History
                    </Button>
                </Grid>                                  
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <LDPUIDataTable
              ldpTableId={`modify-price-result-list-${signupId}`}
              restoreFilters={false}
              title={<Typography variant="h6"> Search Result </Typography>}
              data={tableData || []}
              columns={columnsMUI}
              options={options}
            />
          </Grid>
          <Modal />
        </>
      )}
    </Grid>
  );
};

export default connect((state: RootState) => ({
  tabs: state.tabsSection,
  loggedInUser: state.loggedInUser,
}))(ModifyPriceResultList);
