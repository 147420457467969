import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GET_BUYER_MONETARY_CAPACITY } from "../../common/models/buyerCapacity";
import { GET_CONTRACT_MONETARY_CAPACITY } from "../../common/models/contractMonetaryCapacity";
import { GET_CONTRACTS } from "../../common/models/contracts";
import {
  BuyerMonetaryCapacityConfig,
  BuyerMonetaryCapacityConfigVariables,
} from "../../common/models/types/BuyerMonetaryCapacityConfig";
import { GetContractMonetaryCapacityConfig } from "../../common/models/types/GetContractMonetaryCapacityConfig";
import { GetContracts } from "../../common/models/types/GetContracts";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { queryLoad, StatusIcon, useModal } from "../../components";
import { ChipList } from "../../components/chipList";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { RootState } from "../../state";
import { updateContractDetailForms } from "../../state/contractSectionReducer";
import {
  dockForm,
  FormStateProps,
  openForm,
  resetForm,
} from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { ContractMonetaryCapacityFormModal } from "./contractMonetaryCapacityModal";

interface ContractMonetaryCapacityProps {
  contractId: String;
  contractTabs: any;
  dispatch: Function;
  contractForms: FormStateProps;
}

//Update props types once view requirements are finalized
const ContractMonetaryCapacity = ({
  contractId,
  contractTabs,
  contractForms,
  dispatch,
}: ContractMonetaryCapacityProps) => {
  const [
    getContractMonetaryCapacity,
    { data, loading, error, refetch },
  ] = useLazyQuery<GetContractMonetaryCapacityConfig>(
    GET_CONTRACT_MONETARY_CAPACITY,
    { fetchPolicy: "cache-and-network" }
  );
  const [disableAdd, setDisableAdd] = useState(false);

  const LDP_TABLE_ID = `contract-monetary-capacity-list-${contractId}`;

  useEffect(() => {
    getContractMonetaryCapacity({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, []);

  const [
    getContract,
    { data: contractData, loading: contractLoading },
  ] = useLazyQuery<GetContracts>(GET_CONTRACTS);

  const [
    getBuyerMonetaryCapacities,
    { data: buyerMonetaryCapacityData, loading: buyerMonetaryCapacityLoading },
  ] = useLazyQuery<
    BuyerMonetaryCapacityConfig,
    BuyerMonetaryCapacityConfigVariables
  >(GET_BUYER_MONETARY_CAPACITY, { fetchPolicy: "network-only" });

  const [chipList, setChipList] = useState<any>([]);

  useEffect(() => {
    getContract({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, []);

  useEffect(() => {
    if (contractData) {
      getBuyerMonetaryCapacities({
        variables: {
          where: `BuyerId = ${contractData.LDPConfigQueryGroup?.Contract[0]?.BuyerId} AND IsActive = true`,
        },
      });
    }
  }, [contractData]);

  const ifNotNull = (value: any) => {
    return value !== null ? value : "";
  };

  useEffect(() => {
    if (buyerMonetaryCapacityData) {
      const buyerMonetaryCapacityDataList =
        buyerMonetaryCapacityData.LDPConfigQueryGroup
          ?.BuyerMonetaryCapacityConfig[0];
      if (buyerMonetaryCapacityDataList) {
        setChipList([
          `Daily Budget Max: ${ifNotNull(
            buyerMonetaryCapacityDataList?.MaxDailyBudget
          )}`,
          `Weekly Budget Max: ${ifNotNull(
            buyerMonetaryCapacityDataList?.MaxWeeklyBudget
          )}`,
          `Monthly Budget Max: ${ifNotNull(
            buyerMonetaryCapacityDataList?.MaxMonthlyBudget
          )}`,
        ]);
      } else {
        setChipList([
          `Daily Budget Max`,
          `Weekly Budget Max`,
          `Monthly Budget Max`,
        ]);
      }
    }
  }, [buyerMonetaryCapacityData]);

  useEffect(() => {
    if (data) {
      if (
        data?.LDPConfigQueryGroup?.GetContractMonetaryCapacityConfig &&
        data?.LDPConfigQueryGroup?.GetContractMonetaryCapacityConfig?.length > 0
      )
        setDisableAdd(true);
      else setDisableAdd(false);
    }
  }, [data]);

  const columnsMUI = [
    {
      name: "ContractMonetaryCapacityConfigId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">Edit</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    if (
                      data &&
                      data?.LDPConfigQueryGroup &&
                      data?.LDPConfigQueryGroup
                        .GetContractMonetaryCapacityConfig
                    ) {
                      openModal({
                        title: "Edit Contract Monetary Capacity",
                        icon: <EditIcon />,
                        iconColor: "orange",
                        content: (
                          <ContractMonetaryCapacityFormModal
                            action="edit"
                            data={data?.LDPConfigQueryGroup.GetContractMonetaryCapacityConfig.filter(
                              (item: any) =>
                                item.ContractMonetaryCapacityConfigId === value
                            ).pop()}
                            close={closeModal}
                            refetch={refetch}
                          />
                        ),
                      });
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    console.log("Open Contract Monetary Capacity History");
                    var HISTORY_FORM_ID = `contract-${contractId}-contract-monetary-capacity-history-${value}`;

                    const formProps = {
                      formId: HISTORY_FORM_ID,
                      formTitle: `History Contract Monetary Capacity #${value}`,
                      formIcon: <HistoryIcon />,
                      formComponent: (
                        <HistoryModal
                          ConfigName="ContractMonetaryCapacityConfig"
                          ConfigId={value}
                          TabContainerId={`history-contract-monetary-capacity-${value}-list-tabs`}
                        />
                      ),
                      formData: {
                        ConfigName: "ContractMonetaryCapacity",
                        ConfigId: value,
                        ...tableMeta.tableData[tableMeta.rowIndex],
                      },
                      formProps: {
                        closeTab: () =>
                          dispatch(resetForm({ formId: HISTORY_FORM_ID })),
                      },
                    };

                    dispatch(
                      updateContractDetailForms({
                        contractId: `contract-${contractId}`,
                        formIds: [HISTORY_FORM_ID],
                      })
                    );
                    dispatch(
                      contractForms.formContainers[HISTORY_FORM_ID] &&
                        contractForms.formContainers[HISTORY_FORM_ID]
                          .isLastOpenedDocked
                        ? dockForm(formProps)
                        : openForm(formProps)
                    );
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "ContractId",
      label: "ContractId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "MaxMonthlyBudget",
      label: "Monthly budget ($) ",
      options: {
        filter: false,
        customBodyRender: (value: any) => (value !== null ? `$${value}` : ""),
      },
    },
    {
      name: "MaxWeeklyBudget",
      label: "Weekly Budget ($)",
      options: {
        filter: false,
        customBodyRender: (value: any) => (value !== null ? `$${value}` : ""),
      },
    },
    {
      name: "MaxDailyBudget",
      label: "Daily Budget ($)",
      options: {
        filter: false,
        customBodyRender: (value: any) => (value !== null ? `$${value}` : ""),
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No"),
        },
        customFilterListOptions: {
          render: (v: any) => `IsActive: ${v ? "Yes" : "No"}`,
        },
      },
    },
    {
      name: "UserId",
      label: "UserId",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "StartTime",
      direction: "desc",
    },
  };
  const { Modal, closeModal, openModal, setContent } = useModal();

  return (
    <React.Fragment>
      {queryLoad([!!loading], [error]) || (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ChipList title={"Monetary Capacity"} list={chipList} />
          </Grid>
          <Grid item xs={6}>
            <Box p={1}>
              <Grid container spacing={1} justify="flex-end">
                <Grid item sm={12} md={6} lg={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<AddCircle />}
                    disabled={disableAdd}
                    onClick={() => {
                      openModal({
                        title: "Add New Contract Monetary Capacity",
                        icon: <AddCircle />,
                        iconColor: "orange",
                        content: (
                          <ContractMonetaryCapacityFormModal
                            action="create"
                            data={{
                              ContractId: contractId,
                              ContractMonetaryCapacityConfigId: "-1",
                            }}
                            close={closeModal}
                            refetch={refetch}
                          />
                        ),
                      });
                    }}
                  >
                    Add Monetary Capacity
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <LDPUIDataTable
              ldpTableId={LDP_TABLE_ID}
              restoreFilters={true}
              title={""}
              data={
                data?.LDPConfigQueryGroup?.GetContractMonetaryCapacityConfig
              }
              columns={columnsMUI}
              options={options}
            />
            <Modal />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default connect(
  (state: RootState) => ({
    contractForms: state.formsSection,
  }),
  null
)(ContractMonetaryCapacity);
