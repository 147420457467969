import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GET_BUYER_CAPACITY } from "../../common/models/buyerCapacity";
import { GET_CONTRACT_LEAD_CAPACITY_CONFIG } from "../../common/models/contractLeadCapacityConfig";
import { GET_CONTRACTS } from "../../common/models/contracts";
import {
  BuyerCapacityQuery,
  BuyerCapacityQueryVariables,
} from "../../common/models/types/BuyerCapacityQuery";
import { GetContractLeadCapacityConfig } from "../../common/models/types/GetContractLeadCapacityConfig";
import { GetContracts } from "../../common/models/types/GetContracts";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { queryLoad, StatusIcon, useModal } from "../../components";
import { ChipList } from "../../components/chipList";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { RootState } from "../../state";
import { updateContractDetailForms } from "../../state/contractSectionReducer";
import {
  dockForm,
  FormStateProps,
  openForm,
  resetForm,
} from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { ContractLeadCapacityConfigForm } from "./contractLeadCapacityConfigModal";

interface ContractLeadCapacityConfigProps {
  contractId?: String;
  dispatch?: Function;
  //contractRequestBodyRecordSaved: boolean;
  contractForms?: FormStateProps;
}

const ContractLeadCapacityConfig = ({
  contractId,
  contractForms,
  dispatch,
}: ContractLeadCapacityConfigProps) => {
  const [
    getContractLeadCapacityConfig,
    { data, loading, error, refetch },
  ] = useLazyQuery<GetContractLeadCapacityConfig>(
    GET_CONTRACT_LEAD_CAPACITY_CONFIG,
    { fetchPolicy: "cache-and-network" }
  );
  const LDP_TABLE_ID = `contract-capacity-list-${contractId}`;

  useEffect(() => {
    getContractLeadCapacityConfig({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, []);

  const [disabledCreate, setDisabledCreate] = useState<boolean>(true);
  useEffect(() => {
    if (
      data?.LDPConfigQueryGroup?.GetContractLeadCapacityConfig &&
      data?.LDPConfigQueryGroup?.GetContractLeadCapacityConfig?.length < 1
    ) {
      setDisabledCreate(false);
    } else {
      setDisabledCreate(true);
    }
  }, [data]);

  const [
    getContract,
    { data: contractData, loading: contractLoading },
  ] = useLazyQuery<GetContracts>(GET_CONTRACTS);

  const [
    getBuyerCapacities,
    { data: buyerCapacityData, loading: buyerCapacityLoading },
  ] = useLazyQuery<BuyerCapacityQuery, BuyerCapacityQueryVariables>(
    GET_BUYER_CAPACITY,
    { fetchPolicy: "network-only" }
  );

  const [chipList, setChipList] = useState<any>([]);

  useEffect(() => {
    getContract({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, []);

  useEffect(() => {
    if (contractData) {
      getBuyerCapacities({
        variables: {
          where: `BuyerId = ${contractData.LDPConfigQueryGroup?.Contract[0]?.BuyerId} AND IsActive = true`,
        },
      });
    }
  }, [contractData]);

  const ifNotNull = (value: any) => {
    return value !== null ? value : "";
  };

  useEffect(() => {
    if (buyerCapacityData) {
      const buyerCapacityDataList =
        buyerCapacityData.LDPConfigQueryGroup?.BuyerCapacity[0];
      if (buyerCapacityDataList) {
        setChipList([
          `Daily Max: ${ifNotNull(buyerCapacityDataList?.DailyMaxCapacity)}`,
          `Weekly Max: ${ifNotNull(buyerCapacityDataList?.WeeklyMaxCapacity)}`,
          `Monthly Max: ${ifNotNull(
            buyerCapacityDataList?.MonthlyMaxCapacity
          )}`,
        ]);
      } else {
        setChipList([`Daily Max`, `Weekly Max`, `Monthly Max`]);
      }
    }
  }, [buyerCapacityData]);

  const columnsMUI = [
    {
      name: "ContractLeadCapacityConfigId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">Edit</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    if (
                      data &&
                      data?.LDPConfigQueryGroup &&
                      data?.LDPConfigQueryGroup.GetContractLeadCapacityConfig
                    ) {
                      openModal({
                        title: "Edit Contract Capacity",
                        icon: <EditIcon />,
                        iconColor: "orange",
                        content: (
                          <ContractLeadCapacityConfigForm
                            action="edit"
                            data={data?.LDPConfigQueryGroup.GetContractLeadCapacityConfig.filter(
                              (item: any) =>
                                item.ContractLeadCapacityConfigId === value
                            ).pop()}
                            close={closeModal}
                            refetch={refetch}
                          />
                        ),
                      });
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    var HISTORY_FORM_ID = `contract-${contractId}-contract-capacity-history-${value}`;
                    const formProps = {
                      formId: HISTORY_FORM_ID,
                      formTitle: `History Contract Lead Capacity Config #${value}`,
                      formIcon: <HistoryIcon />,
                      formComponent: (
                        <HistoryModal
                          ConfigName="ContractLeadCapacityConfig"
                          ConfigId={value}
                          TabContainerId={`history-contract-capacity-${value}-list-tabs`}
                        />
                      ),
                      formData: {
                        ConfigName: "ContractLeadCapacityConfig",
                        ConfigId: value,
                        ...tableMeta.tableData[tableMeta.rowIndex],
                      },
                      formProps: {
                        closeTab: () =>
                          dispatch(resetForm({ formId: HISTORY_FORM_ID })),
                      },
                    };

                    dispatch(
                      updateContractDetailForms({
                        contractId: `contract-${contractId}`,
                        formIds: [HISTORY_FORM_ID],
                      })
                    );
                    dispatch(
                      contractForms?.formContainers[HISTORY_FORM_ID] &&
                        contractForms?.formContainers[HISTORY_FORM_ID]
                          .isLastOpenedDocked
                        ? dockForm(formProps)
                        : openForm(formProps)
                    );
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    // {
    //   name: "ContractLeadCapacityConfigId",
    //   label: "Id",
    //   options: {
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    {
      name: "ContractId",
      label: "ContractId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "MaxMonthlyCapacity",
      label: "MaxMonthlyCapacity",
      options: {
        filter: false,
        customBodyRender: (value: any) => (value === null ? "" : value),
      },
    },
    {
      name: "MaxWeeklyCapacity",
      label: "MaxWeeklyCapacity",
      options: {
        filter: false,
        customBodyRender: (value: any) => (value === null ? "" : value),
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No"),
        },
        customFilterListOptions: {
          render: (v: any) => `IsActive: ${v ? "Yes" : "No"}`,
        },
      },
    },
    {
      name: "UserId",
      label: "UserId",
      options: {
        filter: false,
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          /* return value
              ? mument(value)
                .local()
                .format("YYYY-MM-DD")
              : ""; */
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "StartTime",
      direction: "desc",
    },
  };
  const { Modal, closeModal, openModal, setContent } = useModal();

  return (
    <React.Fragment>
      {queryLoad([!!loading], [error]) || (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ChipList title={"Buyer Capacity"} list={chipList} />
          </Grid>
          <Grid item xs={6}>
            <Box p={1}>
              <Grid container spacing={1} justify="flex-end">
                <Grid item sm={12} md={6} lg={6}>
                  <Button
                    disabled={disabledCreate}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<AddCircle />}
                    onClick={() => {
                      if (!disabledCreate)
                        openModal({
                          title: "Add Weekly/Monthly Capacity Configuration",
                          icon: <AddCircle />,
                          iconColor: "orange",
                          content: (
                            <ContractLeadCapacityConfigForm
                              action="create"
                              data={{
                                ContractId: contractId,
                                ContractLeadCapacityConfigId: -1,
                              }}
                              close={closeModal}
                              refetch={refetch}
                            />
                          ),
                        });
                    }}
                  >
                    Add Weekly/Monthly Capacity
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <LDPUIDataTable
              ldpTableId={LDP_TABLE_ID}
              restoreFilters={true}
              title={"Weekly / Monthly Configuration"}
              data={
                data?.LDPConfigQueryGroup?.GetContractLeadCapacityConfig ?? []
              }
              columns={columnsMUI}
              options={options}
            />
            <Modal />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default connect(
  (state: RootState) => ({
    // contractRequestBodyRecordSaved: state.contractRequestSection.contractRequestBodyRecordSaved,
    contractForms: state.formsSection,
  }),
  null
)(ContractLeadCapacityConfig);
