import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
} from "@material-ui/core";


import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { GetBuyerContact_LDPConfigQueryGroup_BuyerContact } from "../../common/models/types/GetBuyerContact";

import SaveIcon from "@material-ui/icons/Save";
import { SAVE_BUYER_CONTACT } from "../../common/models/buyers";
import { BuyerContactInput } from "../../types/graphql-global-types";

import { eventTracker as tracker } from '../../components/tracker';
import { GoogleUserMetaProp } from "../../common/utils/googleUserMeta";

interface BuyerContactFormModalProps {
  data: GetBuyerContact_LDPConfigQueryGroup_BuyerContact | null;
  action: string;
  close: Function;
  refetch: Function;
  googleUserMeta?: GoogleUserMetaProp;
}

export const BuyerContactFormModal = ({ action, data, close, refetch, googleUserMeta }: BuyerContactFormModalProps) => {
  const classes = useStyles();
  const pageTitle = action == "create" ? "Add New Buyer Contact" : "Edit Buyer Contact";

  const [saveBuyerContact] = useMutation(SAVE_BUYER_CONTACT);
  const { register, handleSubmit, watch, errors } = useForm<BuyerContactInput>();

  const [disableButtons, setDisableButtons] = useState<Boolean>(false);
  const onSubmit = (buyerContactInput: BuyerContactInput) => {
    setDisableButtons(true);
    saveBuyerContact({ variables: { buyerContactInput } }).then((response: any) => {
      toast.success(`Buyer Contact ${(buyerContactInput?.BuyerContactId > 0 ? "updated" : "created")} successfully.`);
      if (buyerContactInput?.BuyerContactId == 0) {
        eventTracker({ buyerContactInput })
      }

      setDisableButtons(false);
      refetch();
      close();
    }).catch(({ errors }) => {
      setDisableButtons(false);
      if (errors) {
        toast.error("Unexpected error!");
        console.log(errors[0]);
      }
    });
  };

  const eventTracker = ({ buyerContactInput }: any) => {

    const blockers: any = { '@': '[at]', '.': '[dot]' };
    tracker({
      name: "CreateNewContractInfo",
      caption: "Track Create New Contract Info",
      values: {
        ...buyerContactInput,
        Email: buyerContactInput.Email.replace(/\@|\./g, it => blockers[it]),
        email: googleUserMeta?.email?.replace(/\@|\./g, it => blockers[it]) ?? null
      }
    });
  }

  // const BuyerContactConstraints = {
  //   Title: {
  //       required: {
  //       value: true,
  //       message: "Title is required.",
  //       },
  //     },
  //   FirstName: {
  //     required: {
  //     value: true,
  //     message: "Firstname is required.",
  //     },
  //   },
  //   LastName: {
  //     required: {
  //     value: true,
  //     message: "Lastname is required.",
  //     },
  //   },
  //   WorkPhone: {
  //     required: {
  //     value: true,
  //     message: "Workphone is required.",
  //     },
  //   },
  //   CellPhone: {
  //     required: {
  //       value: true,
  //       message: "Cellphone is required.",
  //     },
  //   },
  // };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              hidden
              inputRef={register}
              name="BuyerContactId"
              defaultValue={data?.BuyerContactId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
            <TextField
              inputRef={register}
              name="Title"
              label="Title"
              defaultValue={data?.Title ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="FirstName"
              label="First Name"
              defaultValue={data?.FirstName ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="LastName"
              label="Last Name"
              defaultValue={data?.LastName ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="ContactTypeId"
              label="Contact Type ID"
              defaultValue={data?.ContactTypeId ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Email"
              label="Email"
              defaultValue={data?.Email ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="WorkPhone"
              label="Work Phone"
              defaultValue={data?.WorkPhone ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="CellPhone"
              label="Cellphone"
              defaultValue={data?.WorkPhone ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
