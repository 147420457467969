import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,  
  Divider,
  FormControlLabel,
  TextField,
  Switch,  
} from "@material-ui/core";
import { queryLoad } from "../../components";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import SaveIcon from "@material-ui/icons/Save";

import { eventTracker as tracker } from '../../components/tracker';
import { GoogleUserMeta, GoogleUserMetaProp } from "../../common/utils/googleUserMeta";
import { BuyerCapacityQuery, BuyerCapacityQueryVariables, BuyerCapacityQuery_LDPConfigQueryGroup_BuyerCapacity } from "../../common/models/types/BuyerCapacityQuery";
import { BuyerCapacityInputType } from "../../types/graphql-global-types";
import { GET_BUYER_CAPACITY, SAVE_BUYER_CAPACITY } from "../../common/models/buyerCapacity";

interface BuyerCapacityTotalCountFormModalProps {
  buyerId: any;
  data: BuyerCapacityQuery_LDPConfigQueryGroup_BuyerCapacity | null;
  action: string;
  close: Function;
  refetch?: Function;  
}

// const formError = {};
export const BuyerCapacityFormModal = ({
  action,
  buyerId,
  data,
  close,
  refetch,  
}: BuyerCapacityTotalCountFormModalProps) => {     
  const googleUserMeta = GoogleUserMeta();
  const { register, handleSubmit, setValue, errors } = useForm<BuyerCapacityInputType>();  
  const classes = useStyles();  
  
  const eventTracker = ({ data }: any) => {
    const blockers: any = { '@': '[at]', '.': '[dot]' };
    tracker({
      name: "CreateNewBuyerCapacity",
      caption: "Track Create New Buyer Capacity",
      values: {
        ...data,
        email: googleUserMeta?.email?.replace(/\@|\./g, it => blockers[it]) ?? null
      }
    });
  }

  const [saveBuyerCapacity] = useMutation(SAVE_BUYER_CAPACITY);
  const onSubmit = (dataInput: BuyerCapacityInputType) => {
    dataInput.UserId = data?.UserId ?? googleUserMeta?.email    
    dataInput.CreatedDate = data?.CreatedDate ?? new Date();  
    if (data?.BuyerCapacityId > 0)
      dataInput.ModifiedDate = new Date();  

    dataInput.MonthlyMaxCapacity = parseInt(dataInput?.MonthlyMaxCapacity || "");
    dataInput.WeeklyMaxCapacity = parseInt(dataInput?.WeeklyMaxCapacity || "");
    dataInput.DailyMaxCapacity = parseInt(dataInput?.DailyMaxCapacity || "");

    saveBuyerCapacity({
      variables: { dataInput }
    }).then((response: any) => {
      if (dataInput.BuyerCapacityId < 1) {
        eventTracker({ dataInput });
        toast.success("Buyer Capacity created successfully.");
      }
      else toast.success("Buyer Capacity updated successfully.");

      refetch && refetch();
      close();
    });
  }

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <TextField
            required
            hidden
            inputRef={register}
            name="BuyerCapacityId"
            defaultValue={data?.BuyerCapacityId ?? 0}
          />
          <TextField
            required
            hidden
            inputRef={register}
            name="BuyerId"
            defaultValue={data?.BuyerId ?? buyerId}
          />
          <Grid item xs={12}>            
            <TextField
              inputRef={register}
              type="number"
              name="MonthlyMaxCapacity"
              label="Monthly Lead Max Capacity"
              defaultValue={data?.MonthlyMaxCapacity ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) &&
                e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>            
            <TextField
              inputRef={register}
              type="number"                            
              name="WeeklyMaxCapacity"
              label="Weekly Lead Max Capacity"
              defaultValue={data?.WeeklyMaxCapacity ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) &&
                e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>            
            <TextField
              inputRef={register}
              type="number"                            
              name="DailyMaxCapacity"
              label="Daily Lead Max Capacity"
              defaultValue={data?.DailyMaxCapacity ?? ""}
              variant="outlined"
              onKeyDown={(e: any) =>
                ['e', 'E', '+', '-', '.'].includes(e.key) &&
                e.preventDefault()
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Notes"
              label="Notes"
              defaultValue={data?.Notes ?? ""}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? true}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Is Active"
            />
          </Grid>

          <Grid item xs={12}>
              <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
          
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);