import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Box, Button, Grid, IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import LaunchIcon from "@material-ui/icons/Launch";
import { MUIDataTableColumnDef } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DELETE_CONTRACT_REQUEST_HEADER, GET_CONTRACT_REQUEST_HEADER } from "../../common/models/contractRequestHeader";
import { GET_CONTRACT_REQUEST_SCHEMA } from "../../common/models/contractRequestSchema";
import { GET_CONTRACTS } from "../../common/models/contracts";
import { GetContractRequestHeader } from "../../common/models/types/GetContractRequestHeader";
import { GetContractRequestSchema } from "../../common/models/types/GetContractRequestSchema";
import { GetContracts } from "../../common/models/types/GetContracts";
import { dateToPreferredTimezone, LDPUISimpleDateFormat } from "../../common/utils/date";
import { queryLoad, StatusIcon, useConfirmation, useModal } from "../../components";
import { booleanRadioFilter } from "../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { RootState } from "../../state";
import { signalContractRequestBodyRefreshCarriedOut } from "../../state/contractRequestReducer";
import { updateContractDetailForms } from "../../state/contractSectionReducer";
import { dockForm, FormStateProps, openForm, resetForm } from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { ContractRequestHeaderFormModal } from "./contractRequestHeaderModal";
import ContractRequestSchemaFormModal from "./contractRequestSchemaModal";

interface ContractRequestProps {
  contractId: String;
  contractTabs: any;
  dispatch: Function;
  contractRequestBodyRecordSaved: boolean;
  contractForms: FormStateProps;
}

interface ContractTableDefinitionProps {
  schema: string;
  name: string;
}

const ContractRequest = ({ contractId, contractRequestBodyRecordSaved, dispatch, contractForms }: ContractRequestProps) => {

  const EDIT_FORM_ID = `contract-${contractId}-request-body-edit`;
  const ADD_FORM_ID = `contract-${contractId}-request-body-add`;

  const LDP_REQUEST_HEADERS_TABLE_ID = `contract-request-headers-${contractId}`;
  const LDP_REQUEST_BODY_TABLE_ID = `contract-request-schema-list-${contractId}`;
  
  const [BuyerId, setBuyerId] = useState(null);
  const [contractTableDefinition, setContractTableDefinition] = useState<ContractTableDefinitionProps | null>(null);
  const { data: contractData } = useQuery<GetContracts>(GET_CONTRACTS, {
    variables: {
      where: `ContractId = ${contractId}`
    }
  });

  useEffect(() => {
    if (contractData) {
      const item = contractData?.LDPConfigQueryGroup?.Contract[0] ?? null;

      if (item) {
        setBuyerId(item?.BuyerId ?? null);

        setContractTableDefinition({
          schema: "lead",
          name: item?.Vertical?.VerticalName+""+item?.SubVertical?.SubVerticalName,
        });            
      }      
    }
  }, [contractData])

  const [
    getContractRequestHeader,
    {
      data: requestHeaderData,
      loading: requestHeaderDataLoading,
      error: requestHeaderError,
      refetch: requestHeaderRefetch,
    },
  ] = useLazyQuery<GetContractRequestHeader>(GET_CONTRACT_REQUEST_HEADER, { fetchPolicy: "cache-and-network" });

  const [
    getContractRequestSchema,
    {
      data: requestSchemaData,
      loading: requestSchemaDataLoading,
      error: requestSchemaError,
      refetch: requestSchemaRefetch,
    },
  ] = useLazyQuery<GetContractRequestSchema>(GET_CONTRACT_REQUEST_SCHEMA, { fetchPolicy: "cache-and-network" });

  const [ deleteHeader ] = useMutation(DELETE_CONTRACT_REQUEST_HEADER);
  const deleteHeaderEntry = (headerId:number) => {
    deleteHeader({
      variables: { contractRequestHeaderId: headerId },
    }).then((response: any) => {
      if (response.data.LDPConfigMutationGroup.DeleteContractRequestHeader) {
        toast.success("Request Header deleted.");
      }
      requestHeaderRefetch();
    });
  };

  useEffect(() => {
    getContractRequestHeader({
      variables: { where: `ContractId = ${contractId}` },
    });
    getContractRequestSchema({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, []);

  useEffect(() => {
    if (requestSchemaData && contractRequestBodyRecordSaved) {
      requestSchemaRefetch().finally(() => {
        dispatch(signalContractRequestBodyRefreshCarriedOut());
      });
    }
  }, [requestSchemaData, contractRequestBodyRecordSaved])

  const { Modal, closeModal, openModal, setContent } = useModal({ maxWidth: "75vw" });
  const { Modal: Confirmation, useModal: showConfirmation } = useConfirmation();

  const HeaderColumnsMUI:MUIDataTableColumnDef[] = [
    {
      name: "DataActionCol",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  openModal({
                    title: "Edit Request Header",
                    icon: <EditIcon />,
                    iconColor: "orange",
                    content: (
                      <ContractRequestHeaderFormModal
                        action={"edit"}
                        contractId={contractId}
                        data={mapRowDataToColumns(
                          HeaderColumnsMUI,
                          tableMeta.rowData
                        )}
                        close={closeModal}
                        refetch={requestHeaderRefetch}
                      />
                    ),
                  });
                }}
              >
                <LaunchIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  showConfirmation(
                    () => {
                      deleteHeaderEntry(tableMeta.rowData[2]);
                    },
                    {
                      title: "Delete Request Header?"
                    });
                }}
              >
                <DeleteIcon />
              </IconButton>

              <Tooltip placement="right" title={
                <React.Fragment>
                  <Typography color="textPrimary">History</Typography>
                </React.Fragment>
              }>
                <IconButton
                  onClick={() => {

                    const HISTORY_TAB_ID = `contract-${contractId}-request-header-history-${tableMeta.rowData[2]}`;

                    const formProps = {
                      formId: HISTORY_TAB_ID,
                      formTitle: `Request Header ${tableMeta.rowData[2]} History`,
                      formIcon: <HistoryIcon />,
                      formComponent: <HistoryModal ConfigName="ContractRequestHeader" ConfigId={tableMeta.rowData[2]} TabContainerId={HISTORY_TAB_ID} />,
                      formData: {  },
                      formProps: {
                        closeTab: () => dispatch(resetForm({ formId: HISTORY_TAB_ID })),
                      }
                    };

                    dispatch(updateContractDetailForms({ contractId: `contract-${contractId}`, formIds: [HISTORY_TAB_ID] }));
                    
                    dispatch(
                      contractForms.formContainers[HISTORY_TAB_ID]
                        && contractForms.formContainers[HISTORY_TAB_ID].isLastOpenedDocked ?
                        dockForm(formProps) : openForm(formProps)
                    );
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },

    {
      name: "ContractId",
      label: "ContractId",
      options: {
        filter: false,
        sort: false,
        display: false
      },
    },
    {
      name: "ContractRequestHeaderId",
      label: "ContractRequestHeaderId",
      options: {
        filter: false,
        display: false,
      },
    },

    {
      name: "Key",
      label: "Header Type",
      options: {
        filter: false,
      },
    },
    {
      name: "Value",
      label: "Value",
      options: {
        filter: false,
      },
    },

    {
      name: "IsActive",
      label: "Active",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        ...booleanRadioFilter("Is Active"),
        filterList: []
      },
    },
    {
      name: "IsPing",
      label: "Ping",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: false,
      },
    },
    
    {
      name: "UserId",
      label: "User",
      options: {},
    },
    
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          return value
            ? dateToPreferredTimezone(value, LDPUISimpleDateFormat)
            : "";
        },
        filter: false,
        sort: true,
      },
    },

    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        customBodyRender: (value: any) => {
          return value
            ? dateToPreferredTimezone(value, LDPUISimpleDateFormat)
            : "";
        },
        filter: false,
        sort: true,
      },
    },
  ];

  const SchemaColumnsMUI = [
    {
      name: "DataActionCol",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip placement="right" title={
                <React.Fragment>
                  <Typography color="textPrimary">Edit</Typography>
                </React.Fragment>
              }>
                <IconButton
                  onClick={() => {
                    const _data = mapRowDataToColumns(SchemaColumnsMUI, tableMeta.rowData);

                    const formProps =
                    {
                      formId: EDIT_FORM_ID,
                      formTitle: `Edit Request Body`,
                      formComponent: <ContractRequestSchemaFormModal tableDefinitionSchema={contractTableDefinition?.schema ?? ""} tableDefinitionName={contractTableDefinition?.name ?? ""}  />,
                      formData: { ..._data, BuyerId, contractId },
                      formProps: {
                        closeTab: () => dispatch(resetForm({ formId: EDIT_FORM_ID })),

                      }
                    };

                    //dispatch( contractRequestBodyEditFormData.isLastOpenedDocked ? dockContractRequestBodyEditForm(_data) : openContractRequestBodyEditForm(_data) );
                    dispatch(
                      contractForms.formContainers[EDIT_FORM_ID]
                        && contractForms.formContainers[EDIT_FORM_ID].isLastOpenedDocked ?
                        dockForm(formProps) : openForm(formProps)
                    );

                  }}
                >
                  <LaunchIcon />
                </IconButton>
              </Tooltip>

              <Tooltip placement="right" title={
                <React.Fragment>
                  <Typography color="textPrimary">History</Typography>
                </React.Fragment>
              }>
                <IconButton
                  onClick={() => {
                    const HISTORY_TAB_ID = `contract-${contractId}-request-schema-history-${tableMeta.rowData[1]}`;

                    const formProps = {
                      formId: HISTORY_TAB_ID,
                      formTitle: `Request Body ${tableMeta.rowData[1]} History`,
                      formIcon: <HistoryIcon />,
                      formComponent: <HistoryModal ConfigName="ContractRequestSchema" ConfigId={tableMeta.rowData[1]} TabContainerId={HISTORY_TAB_ID} />,
                      formData: {  },
                      formProps: {
                        closeTab: () => dispatch(resetForm({ formId: HISTORY_TAB_ID })),
                      }
                    };

                    dispatch(updateContractDetailForms({ contractId: `contract-${contractId}`, formIds: [HISTORY_TAB_ID] }));
                    dispatch(
                      contractForms.formContainers[HISTORY_TAB_ID]
                        && contractForms.formContainers[HISTORY_TAB_ID].isLastOpenedDocked ?
                        dockForm(formProps) : openForm(formProps)
                    );
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "ContractRequestSchemaId",
      label: "ContractRequestSchemaId",
      options: {
        filter: false,
        sort: false,
        display: false
      },
    },
    {
      name: "ContractId",
      label: "ContractId",
      options: {
        filter: false,
        sort: false,
        display: false
      },
    },
    {
      name: "ContractRequestSchemaName",
      label: "Name",
      options: {
        filter: false,
        setCellProps: () => ({ style: { minWidth: "264px", maxWidth: "264px" }})
      },
    },
    // {
    //   name: "AuthHeaderSchema",
    //   label: "AuthHeaderSchema",
    //   options: {
    //     filter: false,
    //   },
    // },
    // {
    //   name: "AuthHeaderValue",
    //   label: "AuthHeaderValue",
    //   options: {
    //     filter: false,
    //   },
    // },
    {
      name: "Encoding",
      label: "Encoding",
      options: {
        filter: false,
      },
    },
    {
      name: "HttpVerb",
      label: "Action",
      options: {
        filter: false,
      },
    },
    {
      name: "IsActive",
      label: "Active",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        ...booleanRadioFilter("Is Active"),
        filterList: []
      },
    },
    {
      name: "IsPing",
      label: "Ping",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: false,
      },
    },
    {
      name: "IsUrlEncodedRequest",
      label: "UrlEncoded",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: false,
      },
    },
    {
      name: "PostUrl",
      label: "Url",
      options: {
        filter: false,
      },
    },
    {
      name: "RequestSchema",
      label: "Request Body",
      options: {
        filter: false,
        customBodyRender: (value: any, meta: any) => (
          <Paper
            onClick={() => {               
              
              openModal({
                title: "Request Body",
                icon: <CodeIcon />,
                iconColor: "green",
                content: (
                  <Paper style={Style.CodeModal}>
                    <pre style={{ whiteSpace: 'pre-wrap', fontSize: '18px'}}>
                      {/* {XmlFormatter(value)} */}
                      {"\{\n   " + value.replace(/[{}]/g, "").replaceAll(/\s+/g, "").replaceAll(",", ",\n   ").replaceAll(':', ': ') + "\n\}"}                      
                    </pre>
                  </Paper>
                ),
              });
            }}
            style={Style.CodeWrapper}>{value}</Paper>
        ),
        setCellProps: () => {
          return {
            style: {
              padding: "5px",
            },
          };
        },
      },
    },
    {
      name: "TimeoutSeconds",
      label: "Timeout (sec)",
      options: {
        filter: false,
      },
    },
    {
      name: "UserId",
      label: "User",
      options: {},
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          /* return value
            ? mument(value)
              .local()
              .format("YYYY-MM-DD")
            : ""; */
            return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        filter: false,
        sort: true,
      },
    },

  ];

  const mapRowDataToColumns = (muiColumns: any, rowData: any) => {
    if (rowData) {
      let newJson: any = {};
      muiColumns.map((column, key) => {
        newJson[column.name] = rowData[key];
      });
      return newJson;
    } else return null;
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          startIcon={<AddCircle />}
          onClick={() => {
            openModal({
              title: "Add New Request Header",
              icon: <AddCircle />,
              iconColor: "orange",
              content: (
                <ContractRequestHeaderFormModal
                  action={"create"}
                  contractId={contractId}
                  data={null}
                  close={closeModal}
                  refetch={requestHeaderRefetch}
                />
              ),
            });
          }}
        >
          Create New Header
        </Button>
      </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {queryLoad([!!requestHeaderDataLoading], [requestHeaderError]) || (
          <LDPUIDataTable
            ldpTableId={LDP_REQUEST_HEADERS_TABLE_ID}
            restoreFilters={true}
            title={"Request Header"}
            data={requestHeaderData?.LDPConfigQueryGroup?.ContractRequestHeader}
            columns={HeaderColumnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          startIcon={<AddCircle />}
          onClick={() => {
            const formProps =
            {
              formId: ADD_FORM_ID,
              formTitle: `Add Request Body`,
              formComponent: <ContractRequestSchemaFormModal tableDefinitionSchema={contractTableDefinition?.schema ?? ""} tableDefinitionName={contractTableDefinition?.name ?? ""} />,
              formData: { ContractId: contractId, BuyerId },
              formProps: {
                closeTab: () => dispatch(resetForm({ formId: ADD_FORM_ID })),
              }
            };

            dispatch(
              contractForms.formContainers[ADD_FORM_ID]
                && contractForms.formContainers[ADD_FORM_ID].isLastOpenedDocked ?
                dockForm(formProps) : openForm(formProps)
            );
          }}
        >
          Create New Request Body
        </Button>
      </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {queryLoad([!!requestSchemaDataLoading], [requestSchemaError]) || (
          <LDPUIDataTable
            ldpTableId={LDP_REQUEST_BODY_TABLE_ID}
            restoreFilters={true}
            title={"Request Body"}
            data={requestSchemaData?.LDPConfigQueryGroup?.ContractRequestSchema}
            columns={SchemaColumnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Modal />
      <Confirmation />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  contractRequestBodyRecordSaved: state.contractRequestSection.contractRequestBodyRecordSaved,
  contractForms: state.formsSection,
}), null)(ContractRequest);

const Style: any = {
  CodeWrapper: {
    height: "120px",
    width: "100%",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },

  CodeModal: {
    height: "60vh",
    width: "100%",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },
};
