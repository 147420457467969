import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
} from "@material-ui/core";
import { MergeContractLeadCapacityConfigInputType } from "../../types/graphql-global-types";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import SaveIcon from "@material-ui/icons/Save";
import { SAVE_CONTRACT_LEAD_CAPACITY_CONFIG } from "../../common/models/contractLeadCapacityConfig";

interface ContractLeadCapacityConfigFormProps {
  action: string;
  data: MergeContractLeadCapacityConfigInputType | any;
  close: Function;
  refetch: Function;
}

export const ContractLeadCapacityConfigForm = ({
  action,
  data,
  close,
  refetch,
}: ContractLeadCapacityConfigFormProps) => {

  const [saveContractLeadCapacityConfig] = useMutation(SAVE_CONTRACT_LEAD_CAPACITY_CONFIG);
  const { register, handleSubmit, watch, errors, control, setError } = useForm();
  const classes = useStyles();  

  const [disableButtons, setDisableButtons] = useState<Boolean>(false);
  const onSubmit = (data: any) => {
    setDisableButtons(true);
    if (data.MaxMonthlyBudget === "")
      data.MaxMonthlyBudget = null;

    if (data.MaxWeeklyBudget === "")
      data.MaxWeeklyBudget = null;
    
    saveContractLeadCapacityConfig({
      variables: {
        contractLeadCapacityConfigInput: data
      }
    }).then((response) => {            
      if(response.data?.LDPConfigMutationGroup?.MergeContractLeadCapacityConfig) {
        toast.success(`Contract Lead Capacity Config created successfully.`);
        refetch();
        close();
      } else {
        toast.error(`Error: ${response.data?.LDPConfigMutationGroup?.MergeContractLeadCapacityConfig}`);
      }

      setDisableButtons(false);
      
    }).catch(({errors})=>{      
      setDisableButtons(false);
      toast.error("Unexpected error!");
    });
  };

  const ContractLeadCapacityConfigConstraints = {
    MaxMonthlyCapacity: {
      required: {
        value: true,
        message: "Max Monthly Capacity is required.",
      },
      min: {
        value: 0,
        message: "Minimum value is 0"
      },
    },
    MaxWeeklyCapacity: {
        required: {
            value: true,
            message: "Max Capacity is required.",
        },
        min: {
            value: 0,
            message: "Minimum value is 0"
        },
    },    
  };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractLeadCapacityConfigId"
              defaultValue={data?.ContractLeadCapacityConfigId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractId"
              defaultValue={data?.ContractId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
            <TextField
              // inputRef={register(ContractLeadCapacityConfigConstraints.MaxMonthlyCapacity)}
              inputRef={register}
              error={errors.MaxMonthlyCapacity && true}
              helperText={errors.MaxMonthlyCapacity && errors.MaxMonthlyCapacity?.message}
              name="MaxMonthlyBudget"
              label="Max Monthly Capacity"
              defaultValue={data?.MaxMonthlyCapacity === 0 ? "" : data?.MaxMonthlyCapacity}
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12}>            
            <TextField
              // inputRef={register(ContractLeadCapacityConfigConstraints.MaxWeeklyCapacity)}
              inputRef={register}
              error={errors.MaxWeeklyCapacity && true}
              helperText={errors.MaxWeeklyCapacity && errors.MaxWeeklyCapacity?.message}
              name="MaxWeeklyBudget"
              label="Max Weekly Capacity"
              defaultValue={data?.MaxWeeklyCapacity  === 0 ? "" : data?.MaxWeeklyCapacity}
              variant="outlined"
              type="number"
            />
          </Grid>          
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? false}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
