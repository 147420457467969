import gql from "graphql-tag";

export const GET_CONTRACT_ZIP_FILTER = gql`
  query GetContractZipCodeFilter($contractId: ID) {
    LDPConfigQueryGroup {
      GetZipCodeFilter(ContractId: $contractId) {
        ContractId
        ZipCodeFilterId
        ZipCodeList
        IsSuppressed
        UserId
        ModifiedDate
        CreatedDate
      }
    }
  }
`;

export const SAVE_CONTRACT_ZIP_FILTER = gql`
  mutation MergeZipCodeFilter($zipCodeFilter: ZipCodeFilterTypeInput!) {
    LDPConfigMutationGroup {
      MergeZipCodeFilter(ZipCodeFilter: $zipCodeFilter)
    }
  }
`;