import React, { useEffect, useState } from "react";
import {
    StatusIcon,
    useModal
} from "../../components";

import { Grid, IconButton, Link, Typography, makeStyles } from "@material-ui/core";
import { Edit as EditIcon, ErrorOutlineRounded } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/List';
import LeadViewModal from "./leadViewModal";

import TimelineIcon from '@material-ui/icons/Timeline';
import { connect } from "react-redux";
import { GetAccountManagers_LDPUserConfigQueryGroup_UserConfig_UserConfigRole } from "../../common/models/types/GetAccountManagers";
import { dateToPreferredTimezone } from "../../common/utils/date";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { EllipsisTooltip, useEllipsisStyles } from "../../components/tooltip";
import { RootState } from "../../state";
import { LoggedInUserProps } from "../../state/loggedInUserReducer";
import { TabProps, updateTabContainers } from "../../state/tabsReducer";
import { LdpJourneyDetails } from "../opsScreen/ldpJourneyDetails";
import ModifyPriceResultList from "./modifyPriceResultList";
import { findMuiRowValue } from "../../common/utils/findMuiRowValue";
import { isRolePriceAdjuster } from "../../common/utils/roles";
import { numberColumnFilter, stringColumnFilter } from "../../components/customMuiDatatableFilter";

interface LeadResultListProps {
    dispatch: Function;
    leadsTabs: any;
    data?: string;
    fetchEDW?: boolean | null;
    loggedInUser: LoggedInUserProps;
    verticalId?: any;
    subVerticalId?: any;
}

const TAB_CONTAINER = "leads-list-tabs";

const LeadResultList = ({ dispatch, leadsTabs, data, loggedInUser, verticalId, subVerticalId, fetchEDW }: LeadResultListProps) => {
    const classes = useEllipsisStyles();
    const { Modal, closeModal, openModal } = useModal(undefined, 0.9);

    const [leadData, setLeadData] = useState<any[]>([]);

    useEffect(() => {
        if(data){
            const newLeadData: any[] = [];
            (JSON.parse(data) as any[]).forEach((d, i) => {
                let dd: any = {...d, Payload: undefined};
                if(d.Payload){
                    try {
                        dd = { ...JSON.parse(d.Payload), ...dd };
                    } catch (error) {
                        console.error("Malfomed JSON at index record", i);
                    }
                }
                
                newLeadData.push(dd);
            });

            setLeadData(newLeadData);
        }
    }, [data]);

    const handleCloseTab = (tabs: TabProps[]) => {
        dispatch(
            updateTabContainers({
                [TAB_CONTAINER]: {
                    tabFocus: (tabs.length - 1),
                    tabs: tabs,
                }
            })
        );
    };

    const handleEditorTab = (signupId:number, leadJourneyDetails: any) => {
        const currentTabs = leadsTabs.tabContainers[TAB_CONTAINER].tabs;
        dispatch(updateTabContainers(
            {
                [TAB_CONTAINER]: {
                    tabFocus: currentTabs.length,
                    tabs: [
                        ...currentTabs,
                        {
                            title: `Modify Price #${signupId}`,
                            tabId: `leads-result-${currentTabs.length}`,
                            // content: <LeadEditTabView LeadDataId={value} />,
                            content: <ModifyPriceResultList signupId={signupId} leadJourneyDetails={leadJourneyDetails}/>,
                            closeTab: handleCloseTab,                            
                        },
                    ],
                }
            }
        ));
    }    

    const columnsMUI = [
        {
            "name": "VerticalId",
            "label": "VerticalId",
            "options": {
                "filter": false,
                "display": false
            }
        }, {
            "name": "SubVerticalId",
            "label": "SubVerticalId",
            "options": {
                "filter": false,
                "display": false
            }
        }, {
            name: "LeadDataId",
            label: "Lead Journey",
            options: {
                viewColumns: false,
                filter: false,
                sort: false,
                customBodyRender: (value: any, tableMeta: any, ) => {
                    const data = findMuiRowValue(columnsMUI, ["SignupId", "VerticalId", "SubVerticalId"], tableMeta.rowData);

                    return (
                        <div style={{width: "110px"}}>
                            {
                                loggedInUser && isRolePriceAdjuster(loggedInUser) &&
                                <IconButton
                                    title="Edit"
                                    onClick={() => {
                                        if(!!fetchEDW){
                                            openModal({
                                                content: <Grid container className={classes.root} spacing={2}>
                                                    <Grid item xs={12}>
                                                    <Grid container justifyContent="center" spacing={2}>
                                                        <h2>Please contact PMO team to assist with ticket creation.</h2>
                                                    </Grid>
                                                    </Grid>
                                                </Grid>,
                                                title: `Error: Modify Price #${data[0]}`,
                                                icon: <ErrorOutlineRounded />,
                                                iconColor: "rose",
                                            });
                                        } else {
                                            handleEditorTab(data[0], {VerticalId: data[1] || verticalId, SubVerticalId: data[2] || subVerticalId, LeadDataId: value, FetchEDW: !!fetchEDW}); // pass the SignupId
                                        }
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            }
                            <IconButton
                                title="Show LDP Journey"
                                onClick={() => {
                                    const VerticalId = data[1] || verticalId; //Insurance
                                    const SubVerticalId = data[2] || subVerticalId; //Auto
                                    const LeadDataId = value; //Lead
                                    // const fetchEDW = true; //SAA-2235: default to true since Leadsearch form has no StartDate to check
                                    
                                    openModal({
                                        content: <LdpJourneyDetails LeadDataId={LeadDataId} VerticalId={VerticalId} SubVerticalId={SubVerticalId} FetchEDW={!!fetchEDW} />,
                                        title: `LDP Journey Lead Data Id #${LeadDataId} on Vertical ${VerticalId}, Sub-Vertical ${SubVerticalId}`,
                                        icon: <TimelineIcon />,
                                        iconColor: "rose",
                                    });
                                }}
                            >
                                <TimelineIcon />
                            </IconButton>
                        </div>
                    );
                },
            },
        }, {
            "name": "LeadDataId",
            "label": "LeadDataId",
            "options": {
                "filter": true,
                ...numberColumnFilter,
                "display": true,
                customBodyRender: (value: any, tableMeta: any) => {
                    const data = findMuiRowValue(columnsMUI, ["VerticalId", "SubVerticalId"], tableMeta.rowData);

                    const VerticalId = data[0] || verticalId; //Insurance
                    const SubVerticalId = data[1] || subVerticalId; //Auto
                    const LeadDataId = value; //Lead
                    return <Link
                        style={{ 'cursor': 'pointer' }}
                        onClick={() => {
                            openModal({
                                title: "Lead Data Id #" + LeadDataId,
                                icon: <ListIcon />,
                                iconColor: "rose",
                                content: <LeadViewModal LeadDataId={LeadDataId} VerticalId={VerticalId} SubVerticalId={SubVerticalId} close={closeModal} FetchEDW={!!fetchEDW} />
                            });
                        }}>{LeadDataId}</Link>
                }
            }
        }, {
            "name": "UniversalLeadId",
            "label": "Univeral Lead Id (Jornaya Id)",
            "options": {
                "filter": true,
                ...stringColumnFilter,
                "display": true,
                "sort": false,
                customBodyRender: (value: any) => (
                    <EllipsisTooltip
                      title={<Typography color="inherit">{value}</Typography>}
                      placement="top"
                    >
                      <span className={`${classes.ellipsisColumn} ${classes.ellipsisColumnReportList}`}>
                        {value}
                      </span>
                    </EllipsisTooltip>
                  ),    
            }
        }, {
            "name": "SignupId",
            "label": "SignupId",
            "options": {
                "filter": true,
                ...numberColumnFilter,
                "display": true
            }
        }, {
            "name": "CkmAffiliateId",
            "label": "CkmAffiliateId",
            "options": {
                "filter": true,
                ...numberColumnFilter,
                "display": true
            }
        }, {
            "name": "FirstName",
            "label": "First Name",
            "options": {
                "filter": true,
                ...stringColumnFilter,
                "display": true,
                "sort": false
            }
        }, {
            "name": "LastName",
            "label": "Last Name",
            "options": {
                "filter": true,
                ...stringColumnFilter,
                "display": true,
                "sort": false
            }
        }, {
            "name": "SiteVertical",
            "label": "Site Vertical",
            "options": {
                "filter": true,
                ...stringColumnFilter,
                "display": true
            }
        }, {
            "name": "SiteName",
            "label": "Site Name",
            "options": {
                "filter": true,
                ...stringColumnFilter,
                "display": true
            }
        }, {
            "name": "Accident",
            "label": "Accident",
            "options": {
                "filter": false,
                "display": false,
                "sort": false,
                customBodyRender: (value: any) => <StatusIcon value={value} />,
            }
        }, {
            "name": "Address",
            "label": "Address",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "Age",
            "label": "Age",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "AgeFirstLicensed",
            "label": "AgeFirstLicensed",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "AnnualMileage",
            "label": "AnnualMileage",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "BirthDate",
            "label": "BirthDate",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "City",
            "label": "City",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "CreatedDate",
            "label": "CreatedDate",
            "options": {
                "filter": false,
                "display": true,
                "sort": false,
                customBodyRender: (value: any, tableMeta: any) => (dateToPreferredTimezone(value))
            }
        }, {
            "name": "CreditRating",
            "label": "CreditRating",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "CurrentlyInsured",
            "label": "CurrentlyInsured",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "DesiredCoverage",
            "label": "DesiredCoverage",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "DesireHomeownersInsurance",
            "label": "DesireHomeownersInsurance",
            "options": {
                "filter": false,
                "display": false,
                "sort": false,
                customBodyRender: (value: any) => <StatusIcon value={value} />,
            }
        }, {
            "name": "DoesRequireSr22",
            "label": "DoesRequireSr22",
            "options": {
                "filter": false,
                "display": false,
                "sort": false,
                customBodyRender: (value: any) => <StatusIcon value={value} />,
            }
        }, {
            "name": "Education",
            "label": "Education",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "Email",
            "label": "Email",
            "options": {
                "filter": true,
                ...stringColumnFilter,
                "display": true,
                "sort": false
            }
        }, {
            "name": "ExpirationDate",
            "label": "ExpirationDate",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "Gender",
            "label": "Gender",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "InsuranceCarrier",
            "label": "Insurance Carrier",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "IpAddress",
            "label": "IpAddress",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "IsTestLead",
            "label": "IsTestLead",
            "options": {
                "filter": false,
                "display": false,
                "sort": false,
                customBodyRender: (value: any) => <StatusIcon value={value} />,
            }
        }, {
            "name": "LicenseStatus",
            "label": "LicenseStatus",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "MaritalStatus",
            "label": "MaritalStatus",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "ModifiedDate",
            "label": "ModifiedDate",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "NumberOfVehicles",
            "label": "NumberOfVehicles",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "Occupation",
            "label": "Occupation",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "OptInDate",
            "label": "OptInDate",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "OwnedLeasedOrFinanced",
            "label": "OwnedLeasedOrFinanced",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "OwnOrRent",
            "label": "OwnOrRent",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "PhoneNumber",
            "label": "PhoneNumber",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "PolicyStartDate",
            "label": "PolicyStartDate",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "SignupUrl",
            "label": "SignupUrl",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "State",
            "label": "State",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "TcpaContent",
            "label": "TcpaContent",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "TcpaType",
            "label": "TcpaType",
            "options": {
                "filter": true,
                ...stringColumnFilter,
                "display": true,
                "sort": true
            }
        }, {
            "name": "TrustedFormCertUrl",
            "label": "TrustedFormCertUrl",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "TyPageShown",
            "label": "TyPageShown",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "UserAgent",
            "label": "UserAgent",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "UUID",
            "label": "UUID",
            "options": {
                "filter": false,
                "display": true,
                "sort": false,
                customBodyRender: (value: any) => (
                    <EllipsisTooltip
                      title={<Typography color="inherit">{value}</Typography>}
                      placement="top"
                    >
                      <span className={`${classes.ellipsisColumn} ${classes.ellipsisColumnReportList}`}>
                        {value}
                      </span>
                    </EllipsisTooltip>
                  ),    
            }
        }, {
            "name": "VehicleMake",
            "label": "VehicleMake",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "VehicleMake2",
            "label": "VehicleMake2",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "VehicleModel",
            "label": "VehicleModel",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "VehicleModel2",
            "label": "VehicleModel2",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "VehicleParkedAt",
            "label": "VehicleParkedAt",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "VehiclePrimaryUse",
            "label": "VehiclePrimaryUse",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "VehicleYear",
            "label": "VehicleYear",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "VehicleYear2",
            "label": "VehicleYear2",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        }, {
            "name": "Violation",
            "label": "Violation",
            "options": {
                "filter": false,
                "display": false,
                "sort": false,
                customBodyRender: (value: any) => <StatusIcon value={value} />,
            }
        }, {
            "name": "Payload",
            "label": "Payload",
            "options": {
                "filter": false,
                "display": false,
                "sort": false
            }
        },
    ];

    const options: any = {
        filterType: "checkbox",
        selectableRows: "none",
        responsive: "vertical",
        sortOrder: {
            name: 'ContractName',
            direction: 'asc'
        },
        tableBodyMaxHeight:'calc(100vh - 240px)',
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {
                    !data || (
                    <LDPUIDataTable
                        ldpTableId="lead-search-result-list"
                        restoreFilters={false}
                        title={<Typography variant='h6'> Search Result </Typography>}
                        data={leadData}
                        columns={columnsMUI}
                        options={options}
                    />
                )}
            </Grid>
            <Modal />
        </Grid>
    );
};

export default connect((state: RootState) => ({
    leadsTabs: state.tabsSection,
    loggedInUser: state.loggedInUser,
}))(LeadResultList);
