import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
} from "@material-ui/core";
import { SAVE_CONTRACT_MONETARY_CAPACITY } from "../../common/models/contractMonetaryCapacity";
import { MergeContractMonetaryCapacityConfigInputType } from "../../types/graphql-global-types";
import { GetContractMonetaryCapacityConfig_LDPConfigQueryGroup_GetContractMonetaryCapacityConfig as ContractMonetaryCapacity } from "../../common/models/types/GetContractMonetaryCapacityConfig";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import SaveIcon from "@material-ui/icons/Save";

interface ContractMonetaryCapacityFormModalProps {
  data: ContractMonetaryCapacity | any;
  action: string;
  close: Function;
  refetch: Function;
}

export const ContractMonetaryCapacityFormModal = ({
  action,
  data,
  close,
  refetch,
}: ContractMonetaryCapacityFormModalProps) => {

  const [saveContractMonetaryCapacity] = useMutation(SAVE_CONTRACT_MONETARY_CAPACITY);
  const { register, handleSubmit, control, getValues, watch, errors, setValue } = useForm<MergeContractMonetaryCapacityConfigInputType>({});
  const classes = useStyles();

  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  
  const onSubmit = async (ContractMonetaryCapacityInput: MergeContractMonetaryCapacityConfigInputType) => {
    setDisableButtons(true);

      const mergeContractMonetaryCapacityConfigParams = {
        ...ContractMonetaryCapacityInput,
        MaxMonthlyBudget: ContractMonetaryCapacityInput.MaxMonthlyBudget || null,
        MaxWeeklyBudget: ContractMonetaryCapacityInput.MaxWeeklyBudget || null,
        MaxDailyBudget: ContractMonetaryCapacityInput.MaxDailyBudget || null,
      }
          
      const result = await saveContractMonetaryCapacity({
        variables: {
          mergeContractMonetaryCapacityConfigParams
        }
      }).then((response) => {
        // evaluate responses here
        let allSuccess = response.data?.LDPConfigMutationGroup?.MergeContractMonetaryCapacityConfig;

        if(allSuccess){
          toast.success(`Contract Monetary Capacity ${(ContractMonetaryCapacityInput.ContractMonetaryCapacityConfigId > 0 ? "updated" : "created")} successfully.`);
          return true;
        } else {
          toast.error("Error saving contract monetary capacity configuration");
          return false;
        }
      }).catch(({errors})=>{                
        //toast.error("Unexpected error!");
        return false;
      });

    setDisableButtons(false);

    if(result){
      refetch();
      close();  
    }
  };

  const ContractMonetaryCapacityConstraints = {    
    MaxMonthlyBudget: {
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Max Monthly Budget value."
      },
    },
    MaxWeeklyBudget: {
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Max Weekly Budget value."
      },
    },
    MaxDailyBudget: {
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Max Daily Budget value."
      },
      min: {
        value: 0,
        message: "Minimum value is 0"
      },
    },
  };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs>
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractMonetaryCapacityConfigId"
              defaultValue={data?.ContractMonetaryCapacityConfigId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractId"
              defaultValue={data?.ContractId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractMonetaryCapacityConstraints.MaxMonthlyBudget)}
              error={errors.MaxMonthlyBudget && true}
              helperText={errors.MaxMonthlyBudget && errors.MaxMonthlyBudget?.message}
              name="MaxMonthlyBudget"
              label="Monthly budget ($)"
              defaultValue={data?.MaxMonthlyBudget ?? ""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractMonetaryCapacityConstraints.MaxWeeklyBudget)}
              error={errors.MaxWeeklyBudget && true}
              helperText={errors.MaxWeeklyBudget && errors.MaxWeeklyBudget?.message}
              name="MaxWeeklyBudget"
              label="Weekly budget ($)"
              defaultValue={data?.MaxWeeklyBudget ?? ""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractMonetaryCapacityConstraints.MaxDailyBudget)}
              error={errors.MaxDailyBudget && true}
              helperText={errors.MaxDailyBudget && errors.MaxDailyBudget?.message}
              name="MaxDailyBudget"
              label="Daily budget ($)"
              defaultValue={data?.MaxDailyBudget ?? ""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01"
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? false}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Notes"
              label="Notes"
              defaultValue={data?.Notes ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper >
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
